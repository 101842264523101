<template>
  <base-page title="Funktionen">
    <listable :query="require('../../graphql/Query/AssociationFunctions.gql')" has-text-search
              name="AssociationFunctions">
      <b-table-column v-slot="props" style="width:100%;" field="name" label="Name" sortable>
        <router-link :to="{ name: 'Function', params: { id: props.row.id }}">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="level.name"
                      v-if="$config.SHOW_ASSOCIATION_LEVELS"
                      label="Ebene"
                      sortable
                      numeric>
        {{ props.row.level.name }}
      </b-table-column>
    </listable>
  </base-page>
</template>

<script>
import BasePage from "../Layout/BasePage";
import Listable from "../Elements/Listable";

export default {
  name: "Functions",
  components: {Listable, BasePage}
}
</script>

<style scoped>

</style>
