<template>
    <div class="columns is-mobile is-vcentered">
        <div class="column">
            <div class="card">
                <div class="steps" style="padding:1rem 0rem;" v-if="seminar.status !== 'CANCELED'">
                    <div class="step-item"
                         v-for="(obj, key) in statuslist"
                         v-if="obj.key !== 'CANCELED'"
                         :class="{
                    'is-completed' : key < statuslist.findIndex((s) => s.key === seminar.status) && seminar.status !== 'CANCELED',
                    'is-active' : key == statuslist.findIndex((s) => s.key === seminar.status)
                 }">
                        <div class="step-marker">{{key}}</div>
                        <div class="step-details">
                            <p class="step-title"
                               v-if="key !== statuslist.findIndex((s) => s.key === seminar.status)">

                                   {{obj.name}}
                            </p>
                            <b-tag :class="obj.class" style="font-size:.9rem;" v-else-if="!seminar.canManageAll"><span>{{obj.name}}</span></b-tag>
                            <b-dropdown v-else>
                                <a class="step-title" slot="trigger"><b-tag :class="obj.class" style="font-size:.9rem;"><span>{{obj.name}}</span><b-icon icon="menu-down" size="is-small"></b-icon></b-tag>

                                </a>
                                <b-dropdown-item @click="setStatus(prevStep)" v-if="prevStep"
                                                 class="has-text-grey-light">
                                    <div class="media">
                                        <b-icon icon="arrow-left" class="media-left" size=""></b-icon>
                                        <div class="media-content">
                                            <b>{{prevStep.name}}</b>
                                            <p>Status auf {{prevStep.name}} stellen</p>
                                        </div>
                                    </div>
                                </b-dropdown-item>
                                <b-dropdown-item @click="setStatus(nextStep)" v-if="nextStep">
                                    <div class="media">
                                        <b-icon icon="arrow-right" class="media-left" size=""></b-icon>
                                        <div class="media-content">
                                            <b>{{nextStep.name}}</b>
                                            <p>Status auf {{nextStep.name}} stellen</p>
                                        </div>
                                    </div>
                                </b-dropdown-item>
                            </b-dropdown>
                            <p>{{obj.description}}</p>
                        </div>
                    </div>
                </div>
                <div v-else style="padding: 1.75rem; font-size:1.5rem;"
                     class="has-text-centered has-background-danger-light">
                  <div class="media">
                    <div class="media-left">
                      <b-icon icon="close" size="is-medium"/>
                    </div>
                    <div class="media-content">
                      <span class="m-lmd">Dieses Seminar wurde abgesagt</span>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {SEMINAR_STATUS} from "../../../constants";
import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'

export default {
  name: "SeminarStatusSteps",
  props: ['seminar'],
  data() {
    return {
      statuslist: SEMINAR_STATUS
    }
  },
  computed: {
    nextStep() {
      let index = this.statuslist.findIndex((s) => s.key === this.seminar.status);
      return this.statuslist[index + 1] || false
    },
    prevStep() {
      let index = this.statuslist.findIndex((s) => s.key === this.seminar.status);
      if (index === 1) {
        return false
      }
      return this.statuslist[index - 1] || false
    }
  },
  methods: {
    setStatus(status) {
      this.$buefy.dialog.confirm({
        title:'Status umstellen',
        message: 'Status auf <i><b>' + status.name + '</b></i> stellen bedeutet: <br/>' + status.message + '',
        type: 'is-warning',
        confirmText: 'Ja, fortfahren',
        hasIcon: true,
        onConfirm: () => {
          this.$apollo.mutate({
            mutation: UPDATE_SEMINAR_MUTATION,
            variables: {
              id: this.seminar.id,
              seminar: {
                status: status.key
              }
            }
          })
        }
      })

    }
  }
}
</script>

<style scoped>
.seminarsteps {

}
</style>
