<template>
    <section style="position: relative;">
        <b-loading :active="isUploading" :is-full-page="false" style="z-index: 1;"></b-loading>
        <upload-item :item="file"
                     :canDelete="$store.getters.hasPermission('manage templates')"
                     v-for="file in downloads"
                     :key="file.id"
                     @refresh="refresh"/>
        <div v-if="downloads.length === 0" class="has-text-grey">Noch nichts hinterlegt</div>
        <div class="m-t-md" v-if="$store.getters.hasPermission('manage templates')">
            <b-upload v-model="dropFiles"
                      multiple
                      @input="uploadFiles"
                      drag-drop style="width:100%;">
                <div class="content has-text-centered" style="padding:1rem; width:100%;">
                    <p>
                        <span>Dateien hier hinziehen oder klicken..</span>
                    </p>
                </div>
            </b-upload>
        </div>
    </section>
</template>

<script>
import GET_TEMPLATE_UPLOADS_QUERY from '../../../graphql/Query/TemplateUploads.gql'
import {API_DOWNLOAD_URL, API_TEMPLATE_UPLOAD_URL} from "@/config";
import UploadItem from "../UploadItem";

export default {
  name: "TemplateUploads",
  components: {UploadItem},
  props: ['template'],
  data() {
    return {
      dropFiles: [],
      Template: [],
      isUploading: false
    }
  },
  apollo: {
    Template: {
      query: GET_TEMPLATE_UPLOADS_QUERY,
      variables() {
        return {
          id: this.template.id
        }
      }
    }
  },
  methods: {
    checkFiles() {
      let files = this.dropFiles;
      for (const file of files) {
        if (file.size > 52428800) {
          alert('Fehler: Die maximale Dateigröße beträgt 50MiB')
          this.dropFiles = []
          return false
        }
      }
      if (files.length > 20) {
        alert('Fehler: Es können maximal 20 Dateien auf einmal hochgeladen werden')
        this.dropFiles = []
        return false
      }
      return true
    },
    uploadFiles() {
      if (!this.checkFiles()) return false;
      let files = this.dropFiles;
      this.dropFiles = [];
      let data = new FormData();
      for (const file of files) {
        data.append('uploads[]', file, file.name);
      }
      this.isUploading = true;
      let cookieVal = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"));

      fetch(API_TEMPLATE_UPLOAD_URL + '/' + this.template.id, {
        credentials: 'include',
        method: 'POST',
        body: data,
        headers: {
          'X-XSRF-TOKEN': cookieVal
        }
      }).then(res => {
        this.isUploading = false;
        this.refresh()
      });
    },
    refresh() {
      this.$apollo.queries.Template.refetch()
    }
  },
  computed: {
    downloads() {
      if (!this.Template.uploads) return []
      return this.Template.uploads.map(upload => ({
        ...upload,
        link: API_DOWNLOAD_URL + '/' + upload.id
      }))
    }
  }
}
</script>

<style scoped>

</style>
