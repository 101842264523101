<template>
  <div>
    <button @click="modalActive = true" class="button">
      <span>Todo anlegen</span>
    </button>
    <b-modal :active.sync="modalActive" :has-modal-card="true">
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Todo anlegen</div>
        </div>
        <div class="modal-card-body">
          <b-field label="Titel">
            <b-input placeholder="Aufgabe eingeben" type="text" v-model="newTodoName"></b-input>
          </b-field>

        </div>
        <div class="modal-card-foot">
          <button :class="{'is-loading' : isAdding}"
                  @click="addTodo"
                  class="button is-success">
            Hinzufügen
          </button>
          <button @click="modalActive=false" class="button is-danger">Abbrechen</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CREATE_DEFAULT_TODO_MUTATION from '../../graphql/Mutations/createDefaultTodo.gql'
import GET_DEFAULT_TODOS_QUERY from '../../graphql/Query/DefaultTodos.gql'

export default {
  name: "AddDefaultTodoButton",
  data() {
    return {
      modalActive: false,
      isAdding: false,
      newTodoName: ''
    }
  },
  methods: {
    addTodo() {
      this.isAdding = true;
      this.$apollo.mutate({
        mutation: CREATE_DEFAULT_TODO_MUTATION,
        refetchQueries: [{query: GET_DEFAULT_TODOS_QUERY}],
        variables: {
          defaultTodo: {
            name: this.newTodoName
          }
        }
      }).then(() => {
        this.newTodoName = ''
        this.isAdding = false;
        this.modalActive = false
      })
    }
  }
}
</script>

<style scoped>

</style>
