import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import queryString from 'qs'
import i18n from '../i18n'

import Login from '@/components/Auth/Login'
// import Register from '@/components/Auth/Register'
import PasswordReset from '@/components/Auth/PasswordReset'
import SendPasswordReset from '@/components/Auth/SendPasswordReset'

import BasicLayout from '@/components/Layout/BasicLayout'
// import NotFoundView from '@/components/404.vue'

import DashboardView from '@/components/Pages/Dashboard'
import UsersView from '@/components/Pages/Users'
import UserView from '@/components/Pages/User'

import Functions from '@/components/Pages/Functions'
import Function from '@/components/Pages/Function'

import MembersView from '@/components/Pages/Members'
import MemberView from '@/components/Pages/Member'

import DepartmentsView from '@/components/Pages/Departments'
import Department from '@/components/Pages/Department'

import LecturersView from '@/components/Pages/Lecturers'

import LecturerView from '@/components/Pages/Lecturer'
import LecturerOverview from '@/components/Elements/Lecturer/LecturerOverview'
import LecturerLectureships from '@/components/Elements/Lecturer/LecturerLectureships'
import LecturerSeminars from '@/components/Elements/Lecturer/LecturerSeminars'

import Seminars from '@/components/Pages/Seminars'
import Seminar from '@/components/Pages/Seminar'
import ParticipationList from '@/components/Elements/Seminar/ParticipationList'
import AddParticipantToSeminar from '@/components/Elements/Seminar/AddParticipantToSeminar'
import MailToSeminar from '@/components/Elements/Seminar/MailToSeminar'
import LetterToSeminar from '@/components/Elements/Seminar/LetterToSeminar'

import PlaceList from '@/components/Pages/PlaceList'
import PlaceDetail from '@/components/Pages/PlaceDetail'

import Templates from '@/components/Pages/Templates'
import Template from '@/components/Pages/Template'

import Settings from '@/components/Pages/Settings'
import SettingsOverview from '@/components/Pages/Settings/Overview'
import SettingsMails from '@/components/Pages/Settings/Mails'
import SettingsEa from '@/components/Pages/Settings/EASettings'
import SettingsDefaultTodos from '@/components/Pages/Settings/DefaultTodos'

import UserSettings from '@/components/Pages/UserSettings'
import DataImport from "@/components/Pages/Settings/DataImport";

import BasicSettings from "@/components/Pages/Settings/Basic";


Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {redirectAuthenticated: true}
    },
    /*{
      path: '/register',
      name: 'Register',
      component: Register
    },*/
    {
      path: '/password/reset/:token',
      name: 'PasswordReset',
      props: true,
      component: PasswordReset,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/password/email',
      name: 'SendPasswordReset',
      component: SendPasswordReset,
      meta: {redirectAuthenticated: true}
    },
    {
      path: '/',
      name: 'Basic',
      component: BasicLayout,
      redirect: '/dashboard',
      meta: {requiresAuth: true},
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          meta: {depth: 1, title: 'Dashboard'},
          component: DashboardView
        },
        {
          path: 'usersettings',
          name: 'UserSettings',
          component: UserSettings
        },
        {
          path: 'settings',
          name: 'Settings',
          redirect: 'settings/overview',
          meta: {depth: 1, title: 'Einstellungen'},
          component: Settings,
          children: [
            {
              path: 'overview',
              name: 'SettingsOverview',
              component: SettingsOverview
            },
            {
              path: 'mails',
              name: 'SettingsMails',
              component: SettingsMails
            },
            {
              path: 'ea',
              name: 'SettingsEa',
              component: SettingsEa
            },
            {
              path: 'todos',
              name: 'SettingsDefaultTodos',
              component: SettingsDefaultTodos
            },
            {
              path: 'import',
              name: 'SettingsDataImport',
              component: DataImport
            },
            {
              path: 'basic',
              name: 'SettingsBasic',
              component: BasicSettings
            }
          ]
        },
        {
          path: 'users',
          name: 'Users',
          meta: {depth: 1, title: 'User'},
          component: UsersView
        },
        {
          path: 'users/:id',
          name: 'User',
          meta: {depth: 2, title: 'User'},
          component: UserView,
          props: true
        },
        {
          path: 'members',
          name: 'Members',
          meta: {depth: 1, title: i18n('Mitglied', true)},
          component: MembersView
        },
        {
          path: 'members/:id',
          name: 'Member',
          component: MemberView,
          meta: {depth: 2, title: i18n('Mitglieder')},
          props: true
        },
        {
          path: 'templates',
          name: 'Templates',
          meta: {depth: 1, title: 'Seminartypen'},
          component: Templates
        },
        {
          path: 'templates/:id',
          name: 'Template',
          component: Template,
          meta: {depth: 2, title: 'Seminartyp'},
          props: true
        },
        {
          path: 'places',
          name: 'Places',
          component: PlaceList,
          meta: {depth: 1, title: 'Tagungsorte'}
        },
        {
          path: 'places/:id',
          name: 'Place',
          component: PlaceDetail,
          meta: {depth: 2, title: 'Tagungsort'},
          props: true
        },
        {
          path: 'functions',
          name: 'Functions',
          component: Functions,
          meta: {depth: 1, title: 'Funktionen'}
        },
        {
          path: 'functions/:id',
          name: 'Function',
          component: Function,
          meta: {depth: 2, title: 'Funktion'},
          props: true
        },
        {
          path: 'departments',
          name: 'Departments',
          component: DepartmentsView,
          meta: {depth: 1, title: i18n('Verbandsstufe', true)}
        },
        {
          path: 'departments/:id',
          name: 'Department',
          component: Department,
          meta: {depth: 2, title: i18n('Verbandsstufe')},
          props: true
        },
        {
          path: 'lecturers',
          name: 'Lecturers',
          component: LecturersView,
          meta: {depth: 1, title: 'Seminarleiter'}
        },
        {
          path: 'lecturers/:id',
          name: 'Lecturer',
          component: LecturerView,
          redirect: 'lecturers/:id/overview',
          props: true,
          meta: {depth: 2, title: 'Seminarleiter'},
          children: [
            {
              name: 'LecturerOverview',
              path: 'overview',
              props: true,
              meta: {depth: 3, title: 'Übersicht'},
              component: LecturerOverview
            },
            {
              name: 'LecturerLectureships',
              path: 'lectureships',
              props: true,
              meta: {depth: 3, title: 'Templates'},
              component: LecturerLectureships
            },
            {
              name: 'LecturerSeminars',
              path: 'seminars',
              props: true,
              meta: {depth: 3, title: 'Templates'},
              component: LecturerSeminars
            }
          ]
        },
        {
          path: 'seminars',
          name: 'Seminars',
          component: Seminars,
          meta: {depth: 1, title: 'Seminare'}
        },
        {
          path: 'seminars/:id/',
          name: 'Seminar',
          redirect: 'seminars/:id/participations',
          component: Seminar,
          props: true,
          meta: {depth: 2, title: 'Seminare'},
          children: [
            {
              name: 'ParticipationList',
              path: 'participations',
              props: true,
              meta: {depth: 3, /*title: 'Teilnahmeliste'*/},
              component: ParticipationList
            },
            {
              name: 'AddParticipantToSeminar',
              path: 'add',
              props: true,
              meta: {depth: 3, /*title: 'TeilnehmerInnen hinzufügen'*/},
              component: AddParticipantToSeminar
            },
            {
              name: 'MailToSeminar',
              path: 'sendmails',
              props: true,
              meta: {depth: 3, /*title: 'Mail-Versand'*/},
              component: MailToSeminar
            },
            {
              name: 'LetterToSeminar',
              path: 'sendletters',
              props: true,
              meta: {depth: 3, /*title: 'Serienbrief'*/},
              component: LetterToSeminar
            }
          ]
        }
      ]
    },
    {
      // not found handler
      path: '*',
      redirect: {
        path: "/"
      }
    }
  ],

  mode: 'history',
  linkActiveClass: 'is-active',
  /*scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {x: 0, y: 0}
    }
  },*/
  parseQuery: queryString.parse,
  stringifyQuery: (query) => queryString.stringify(query, {addQueryPrefix: true, delimiter: '&'})
})

router.beforeEach((to, from, next) => {
  let loggedIn = store.getters.loggedIn;
  // if required but not loggedIn
  if (to.matched.some(record => record.meta.requiresAuth && !loggedIn)) {
    next({
      path: '/login',
      replace: true
    })
    // if !required but loggedIn
  } else if (to.matched.some(record => record.meta.redirectAuthenticated) && loggedIn) {
    next({
      path: '/',
      replace: true
    })
  }
  next()
})

function nextTitle(to) {
  if (to.meta.title !== undefined) {
    return to.meta.title
  }
  let nearestTitles = to.matched.filter(r => r.meta.title !== undefined)
  console.log(nearestTitles)
  if (nearestTitles.length === 0) {
    return 'EIS'
  } else {
    return nearestTitles[nearestTitles.length - 1].meta.title
  }
}

router.afterEach((to) => {
  if (to.meta.depth === undefined || to.meta.depth < 3) {
    document.title = nextTitle(to)
  }
})
export default router
