<template>
  <div>
    <member-mail-item v-for="mail in mails.slice().reverse()" :mail="mail" :key="mail.id" class="item"/>
  </div>
</template>

<script>
import MemberMailItem from "./MemberMailItem";

export default {
  name: "MemberMailList",
  props: ['mails'],
  components: {MemberMailItem}
}
</script>

<style scoped>
.item + .item {
  margin-top:1em;
}

</style>