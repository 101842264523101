import Vue from 'vue'
import {apolloProvider} from './main'
import GET_SYSTEM_SETTINGS_QUERY from './graphql/Query/SystemSettings.gql'

const apibase = process.env.VUE_APP_EIS_API_URL || '/'
export const API_GRAPHQL_ENDPOINT = apibase + 'graphql'
export const API_INIT_CSRF = apibase + 'csrf-cookie'
export const API_SEMINAR_UPLOAD_URL = apibase + 'eisapi/upload/seminar'
export const API_TEMPLATE_UPLOAD_URL = apibase + 'eisapi/upload/template'
export const API_DOWNLOAD_URL = apibase + 'eisapi/download'
export const API_EXPORT_URL = apibase + 'eisapi/export'
export const API_MEMBER_IMPORT_URL = apibase + 'eisapi/import/members'
export const API_MEMBER_FUNCTION_IMPORT_URL = apibase + 'eisapi/import/member_functions'
export const EA_URL = process.env.VUE_APP_EIS_EAAM_URL || 'http://m.eis.localhost:8000'
export const DEFAULT_LANG = process.env.VUE_APP_EIS_LANG || 'de_vdk'
export const FALLBACK_LANG = 'de_vdk'
export let SHOW_DISTRICTS = process.env.VUE_APP_EIS_SHOW_DISTRICTS === "true"
export let SHOW_ASSOCIATION_LEVELS = process.env.VUE_APP_EIS_SHOW_ASSOCIATION_LEVELS === "true"
export let SHOW_PRICE = process.env.VUE_APP_EIS_SHOW_PRICE === "true"
export let SHOW_SEMINAR_REGISTER = process.env.VUE_APP_EIS_SHOW_SEMINAR_REGISTER === "true"
export let SHOW_ANALYZE = process.env.VUE_APP_EIS_SHOW_ANALYZE === "true"
export let AFTER_LOGIN_REDIRECT_TO = "/seminars"
export let SHOW_REGISTER_NEW = false
export let SHOW_REGISTER = false
export let SHOW_DATA_IMPORT = false
export let SHOW_BILLING_ADDRESSES = false


export const loadSettings = async () => {
  const {data: {SystemSettings}} = await apolloProvider.defaultClient.query({query: GET_SYSTEM_SETTINGS_QUERY})

  SHOW_DISTRICTS = SystemSettings.districts
  //AFTER_LOGIN_REDIRECT_TO = SystemsSettings.eisStartPage
  SHOW_ASSOCIATION_LEVELS = SystemSettings.departmentHierarchy
  SHOW_ANALYZE = SystemSettings.analytics
  SHOW_SEMINAR_REGISTER = SystemSettings.seminarSignup
  SHOW_REGISTER = SystemSettings.registration
  SHOW_REGISTER_NEW = SystemSettings.registration && SystemSettings.registrationOfNewMembers
  SHOW_PRICE = SystemSettings.prices
  SHOW_BILLING_ADDRESSES = SystemSettings.memberBillingAddresses
  SHOW_DATA_IMPORT = SystemSettings.dataImport

  Vue.prototype.$config = {
    SHOW_DISTRICTS,
    SHOW_ASSOCIATION_LEVELS,
    SHOW_PRICE,
    SHOW_SEMINAR_REGISTER,
    SHOW_ANALYZE,
    SHOW_REGISTER_NEW,
    SHOW_DATA_IMPORT,
    SHOW_BILLING_ADDRESSES,
    API_EXPORT_URL,
    AFTER_LOGIN_REDIRECT_TO,
  }
}
