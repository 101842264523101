<template>

    <div class="has-text-grey has-text-centered empty">
            <slot></slot>
    </div>

</template>

<script>
export default {
  name: 'empty-table'
}
</script>

<style scoped>
.empty{
    padding:1rem;
}
</style>