<template>
  <base-page title="Seminartypen">
    <template slot="headerRight">
      <div class="is-block-mobile" v-if="$store.getters.hasPermission('manage templates')">
        <a @click="addTemplateModal = true" class="button is-flex-mobile">
          <b-icon icon="text-box-plus"/>
          <span>Seminartyp anlegen</span>
        </a>
      </div>
    </template>
    <b-modal :active.sync="addTemplateModal" :has-modal-card="true">
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Seminartyp hinzufügen</div>
        </div>
        <div class="modal-card-body">
          <b-field label="Name">
            <b-input placeholder="Der Name des neuen Templates..."
                     v-model="newTemplateName"></b-input>
          </b-field>
        </div>
        <div class="modal-card-foot">
          <button class="button is-success" @click="addTemplate">Hinzufügen</button>
          <button class="button is-danger" @click="newTemplateName = ''; addTemplateModal = false">Abbrechen
          </button>
        </div>
      </div>
    </b-modal>
    <div class="columns is-mobile is-1 is-variable is-multiline">
      <div class="column is-full-mobile">
        <clearable-input v-model="filters.searchQuery" placeholder="Seminartyp Eingeben"></clearable-input>
      </div>
      <div class="column is-full-mobile is-narrow">
        <b-checkbox v-model="filters.hideInactive" class="mt-2">Inaktive ausblenden</b-checkbox>
      </div>
    </div>
    <listable :filter-defaults="{hideInactive: true, searchQuery: ''}"
              :filter-function="filterFunction"
              :query="require('../../graphql/Query/Templates.gql')"
              name="Templates">

      <b-table-column v-slot="props" label="">
        <b-icon icon="file-document"></b-icon>
      </b-table-column>
      <b-table-column v-slot="props" label="ID" field="id" width="5%" sortable>
        {{ props.row.id }}
      </b-table-column>
      <b-table-column v-slot="props" label="Name" field="name" sortable width="95%">
        <router-link :to="{name: 'Template', params: {id: props.row.id}}" class="mr-2">{{
            props.row.name
          }}
        </router-link>
        <tags-simple :tags="props.row.tags" @click="tag => filters.searchQuery = tag"/>
      </b-table-column>
      <b-table-column v-slot="props" label="Status" field="state" numeric sortable :customSort="sortstate">
        <state-dropdown :state="props.row.state" varName="template" :id="props.row.id"
                        :disabled="!$store.getters.hasPermission('manage templates')"
                        :mutation="require('../../graphql/Mutations/updateTemplate.gql')"></state-dropdown>
      </b-table-column>
      <div slot="footer" class="has-text-right m-t-lg">
        <a :href="$config.API_EXPORT_URL + '/templates'" class="button">Seminartypen Exportieren</a>
      </div>
    </listable>
  </base-page>
</template>

<script>
import BasePage from "../Layout/BasePage";
import CREATE_TEMPLATE_MUTATION from '../../graphql/Mutations/createTemplate.gql'
import {addTemplate} from "@/cache/cacheUpdater";
import StateDropdown from "../Elements/StateDropdown";
import TagsSimple from "../Elements/TagsSimple";
import TagsInput from "../Elements/TagsInput";
import Listable from "../Elements/Listable";
import ClearableInput from "../Elements/ClearableInput";
import {sortstate} from "@/utils/utils";

export default {
  name: 'templates',
  components: {ClearableInput, Listable, TagsInput, TagsSimple, StateDropdown, BasePage},
  data() {
    return {
      addTemplateModal: false,
      newTemplateName: '',
      filters: {
        searchQuery: '',
        hideInactive: true
      }
    }
  },
  methods: {
    sortstate,
    filterFunction(templates) {
      return templates.filter((p) => (p.name.toLowerCase().indexOf(this.filters.searchQuery.toLowerCase()) !== -1 || p.tags.findIndex(t => t.name.toLowerCase().indexOf(this.filters.searchQuery.toLowerCase()) !== -1) !== -1) && !(p.state === false && this.filters.hideInactive === true))
    },
    addTemplate() {
      this.isLoadingAddTemplate = true
      this.$apollo.mutate({
        mutation: CREATE_TEMPLATE_MUTATION,
        variables: {
          template: {
            name: this.newTemplateName
          }
        },
        update: (store, {data: {createTemplate}}) => addTemplate(store, createTemplate)
      }).then(({data}) => {
        this.isLoadingAddTemplate = false
        this.$router.push({name: 'Template', params: {id: data.createTemplate.id}})
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
