<template>
  <b-input
      v-bind="$attrs"
      :icon-right="!!newValue ? 'close-circle' : ''"
      :value="newValue"
      icon-right-clickable
      @input="onInput"
      @icon-right-click="onClear"/>
</template>

<script>
export default {
  props: ['value'],
  name: "clearable-input",
  data() {
    return {
      newValue: this.value
    }
  },
  watch: {
    /**
     * When v-model is changed:
     *   1. Set internal value.
     */
    value(value) {
      this.newValue = value
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.newValue
      },
      set(value) {
        this.newValue = value
        this.$emit('input', value)
      }
    },
  },
  methods: {
    onInput(val) {
      this.$nextTick(() => {
        this.computedValue = val
      })
    },
    onClear() {
      this.$nextTick(() => {
        this.computedValue = ''
      })
    }
  }
}
</script>

<style lang="scss">
.b-table > div.table-wrapper.table-container > table > thead > tr:nth-child(2) > th > div > span {
  width: 100%;

  input {
    width: 100%;
  }
}
</style>