<template>
    <base-page :title="title">
        <div class="tabs is-boxed">
            <ul>
                <router-link tag="li" :to="{ id: id, name: 'LecturerOverview'}">
                    <a>
                        <b-icon icon="clipboard-text"></b-icon>
                        Übersicht
                    </a>
                </router-link>
                <router-link tag="li" :to="{ id: id, name: 'LecturerLectureships'}">
                    <a>
                        <b-icon icon="clipboard-account"></b-icon>
                        Templates
                    </a>
                </router-link>
                <router-link tag="li" :to="{ id: id, name: 'LecturerSeminars'}">
                    <a>
                        <b-icon icon="calendar-multiple"></b-icon>
                        Seminarleitertätigkeit
                    </a>
                </router-link>

            </ul>
        </div>

        <keep-alive>
            <router-view></router-view>
        </keep-alive>
    </base-page>
</template>

<script>
import GET_LECTURER_QUERY from '../../graphql/Query/Lecturer.gql'
import BasePage from '../Layout/BasePage'

export default {
  components: {
    BasePage
  },
  stackType: 'single',
  props: ['id'],
  apollo: {
    Lecturer: {
      query: GET_LECTURER_QUERY,
      variables () {
        return {
          id: this.id
        }
      },
      loadingKey: 'isLoading'
    }
  },
  data () {
    return {
      isLoading: 0,
      Lecturer: {}
    }
  },
  computed: {
    title () {
      if (this.isLoading) {
        return 'Seminarleitung'
      } else {
        return this.Lecturer.member.fullName
      }
    }
  },
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.tabs{
    margin-bottom:0 !important;
    margin-left:-1px;
}
</style>
