<template>
    <div class="m-t-md m-b-lg" v-if="!isLoading">
        <div class="very-small-container">
            <div class="headline">Benutzereinstellungen</div>
            <p><label class="label">Name</label> {{Profile.fullName}}</p>
            <div class="m-t-md">
                <label class="label is-marginless">E-Mail</label>
                <div class="is-inline-flex">{{Profile.email}}</div>
                <div class="is-pulled-right has-text-right"><a @click.prevent="editMail">ändern</a>
                </div>
                <template v-if="mailEditing">
                    <b-field label="Neue E-Mail Adresse" class="m-t-md m-b-md">
                        <b-input type="email" placeholder="Neue E-Mail Adresse eingeben" @keyup.enter="updateMail"
                                 v-model="newMail"></b-input>
                    </b-field>
                    <button class="button" @click="updateMail">Speichern</button>
                    <button class="button" @click="mailEditing = false">Abbrechen</button>
                </template>
            </div>
           <!-- <div class="m-t-md">
                <label class="label is-marginless">E-Mail Benachrichtigungen</label>
                <b-switch class="m-t-sm" type="is-success" :value="Profile.mailNotifications"
                          @input="updateMailNotifications" style="position: relative;">
                    <template v-if="Profile.mailNotifications">Ja, Benachrichtigungen auch per E-Mail
                        erhalten.
                    </template>
                    <template v-else>Nein, keine Benachrichtigungen per E-Mail erhalten</template>
                    <b-loading :active="isLoadingNotifications" :is-full-page="false"
                               style="font-size: .5rem;"></b-loading>
                </b-switch>
            </div>-->
            <div class="headline">Passwort ändern</div>
            <password-changer class="m-t-md"/>
            <div class="headline has-text-danger">Account löschen</div>
            <div class="has-text-danger">
                Wenn Sie Ihren Benutzeraccount löschen, gehen alle mit ihm in Verbindung stehenden Daten verloren.
                Dieser Schritt kann nicht rückgängig gemacht werden!<br/>
                <delete-profile-button class="m-t-md "/>
            </div>
        </div>
    </div>
</template>

<script>
import GET_PROFILE_QUERY from '../../graphql/Query/Profile.gql'
import UPDATE_PROFILE_MUTATION from '../../graphql/Mutations/updateProfile.gql'
import PasswordChanger from '../Elements/PasswordChanger'
import DeleteProfileButton from '../Elements/DeleteProfileButton'

export default {
  components: {
    DeleteProfileButton,
    PasswordChanger
  },
  name: 'user-settings',
  data () {
    return {
      Profile: {},
      isLoading: 0,
      isLoadingMail: false,
      isLoadingNotifications: false,
      mailEditing: false,
      newPassword: {
        old: '',
        new: '',
        repeat: ''
      },
      newMail: ''
    }
  },
  methods: {
    editMail () {
      this.mailEditing = true
    },
    updateMail () {
      this.isLoadingMail = true
      if (this.newMail.length > 5) {
        this.$apollo.mutate({
          mutation: UPDATE_PROFILE_MUTATION,
          variables: {
            profile: {
              email: this.newMail
            }
          }
        }).then(() => {
          this.isLoadingMail = false
          this.mailEditing = false
          this.newMail = ''
          this.$buefy.toast.open({
            message: 'E-Mail Adresse erfolgreich geändert!',
            type: 'is-success'
          })
        })
      }
    },
    /*updateMailNotifications (yesno) {
      this.isLoadingNotifications = true
      this.$apollo.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          profile: {
            mailNotifications: yesno
          }
        }
      }).then(() => {
        this.isLoadingNotifications = false
      })
    }*/
  },
  apollo: {
    Profile: {
      query: GET_PROFILE_QUERY,
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped>

</style>
