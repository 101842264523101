<template>
  <div class="container">
    <div class="has-text-centered content mt-5">
      <h1>Whoopsi</h1>
      <h4>Die Seite die sie Suchen konnte leider nicht gefunden werden.</h4>
      <router-link to="/">Zurück zur Übersicht.</router-link>
    </div>
  </div>

</template>
<script>
export default {
  name: 'NotFound'
}
</script>

<style>
</style>
