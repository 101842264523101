<template>
  <auth-base>
    <div class="box has-text-left">
      <div v-if="!isSent">
        <b-message has-icon type="is-danger" v-if="showError">
          <b>Oh, nein.</b><br/>
          Leider ist ein Fehler augetreten.
        </b-message>

        <b-field label="E-Mail">
          <b-input placeholder="E-Mail-Adresse" type="email" v-model="email"/>
        </b-field>
        <div class="has-text-centered">
          <button :class="{'is-loading': isLoading}" @click="send" class="button">Passwort vergessen</button>
        </div>

      </div>
      <b-message has-icon type="is-success" v-else>
        <b>Alles klar!</b><br/>
        An Deine E-Mail-Adresse wurde ein Link zum Zurücksetzen des Passwords versandt.
      </b-message>
    </div>
    <p class="has-text-grey">
      <router-link to="/login">Login</router-link> <!--&nbsp;·&nbsp;
            <router-link to="/register">Registrieren</router-link>--><!--&nbsp;·&nbsp;
                               <a href="../">Need Help?</a>-->
    </p>

  </auth-base>
</template>

<script>
  import AuthBase from './AuthBase'
  import SEND_PASSWORD_RESET_MUTATION from '../../graphql/Mutations/sendPasswordReset.gql'


  export default {
    components: {AuthBase},
    name: 'send-password-reset',
    data() {
      return {
        email: '',
        isSent: false,
        isLoading: false,
        showError: false
      }
    },
    methods: {
      send() {
        this.isLoading = true
        this.$apollo.mutate({
          mutation: SEND_PASSWORD_RESET_MUTATION,
          variables: {
            email: this.email
          }
        }).then(() => {
          this.isSent = true
          this.isLoading = false
        }).catch(() => {
          this.showError = true
          this.isLoading = false
        })
      }
    }
  }
</script>

<style scoped>
  p {
    font-weight: 700;
  }
</style>