<template>
  <div>
    <b-field>
      <b-upload v-model="selectedFile"
                accept=".csv"
                class="is-relative"
                drag-drop
                expanded
                @input="fileDropped">
        <section class="section">
          <div class="content has-text-centered">
            <p>
              <b-icon
                  icon="upload"
                  size="is-large">
              </b-icon>
            </p>
            <p>eVewa Mitglieder- oder Beziehungs-Export hier hineinziehen oder klicken</p>
          </div>
        </section>
        <b-loading :active="isLoading" :is-full-page="false"
                   style="height:100%; background-color:rgba(255,255,255,0.5)"/>
      </b-upload>
    </b-field>

    <b-message v-for="{id, name,log,status,created_at} in ImportJobs" :class="importStatus[status].class">
      <div class="media">

        <div class="media-content">
          <label class="label">{{ name }} ({{ id }})</label>
          <div class="logbox" v-text="log"/>
          <div class="m-t-md">gestartet vor {{ created_at | moment('from','now', true) }}</div>
        </div>
        <b-icon :class="importStatus[status].iconClass" :icon="importStatus[status].icon" class="media-right"
                size="is-large"/>
      </div>
    </b-message>
  </div>
</template>

<script>
import SettingEditor from "../../Elements/SettingEditor";
import api from "@/api";
import {API_MEMBER_FUNCTION_IMPORT_URL, API_MEMBER_IMPORT_URL} from "@/config";
import IMPORT_JOBS_QUERY from '@/graphql/Query/ImportJobs.gql'

export default {
  name: "DataImport",
  components: {SettingEditor},
  apollo: {
    ImportJobs: {
      query: IMPORT_JOBS_QUERY,
      pollInterval: 10000
    }
  },
  methods: {
    refetchQueries() {
      return this.$apollo.queries.ImportJobs.refetch()
    },
    fileDropped(file) {
      console.log(file)
      this.currentFile = file
      this.reader.readAsBinaryString(file.slice(0, 1024 * 10));             // or whatever encoding you're using
      // reader.readAsText(file[0], 'ISO-8859-1');
    },
    startMemberFunctionImport() {

    },
    startMemberImport() {
      let data = new FormData();
      data.append('file', this.member_export, this.member_export.name)
      api.post(API_MEMBER_IMPORT_URL, data)
    }
  },
  mounted() {
    this.reader.addEventListener('load', (event) => {
      console.log(event)
      let firstLine = this.reader.result.split('\n').shift(); // first line
      if (firstLine === "DS_NR;MGL_NR;ANREDE;TITEL;VORNAME;NAME;ZUSATZ;STRASSE;PLZ;ORT;GEB_DAT;DS_Erkl_rung;TELEFON;MAIL;AUS_DAT;GLIED_NR\r") {
        this.$buefy.dialog.confirm({
          confirmText: "Ja, importieren!",
          cancelText: "Abbrechen",
          title: "Mitglieder-Export erkannt",
          message: "Willst Du den Export in die Datenbank importieren?<br/>Dabei werden Daten überschrieben.",
          type: "is-warning",
          onConfirm: () => {
            console.log("mitglieder export")
            let data = new FormData();
            data.append('file', this.currentFile, this.currentFile.name)
            this.isLoading = true
            api.post(API_MEMBER_IMPORT_URL, data).then(() => {
              this.isLoading = false
              this.refetchQueries()
            })
          }
        })
      } else if (firstLine === "DS_NR;MITGLIEDNR;NACHNAME;VORNAME;FIRMA1;MGLNR_HAS;NAME_HAS;BEZ_EBENE;BEZ_NAME;BEZ_NR;BEZ_STELL;ANL_DATUM;AEND_DATUM;GUELT_VON;GUELT_BIS;ADR_MAN;GLIED_NR") {
        this.$buefy.dialog.confirm({
          confirmText: "Ja, importieren!",
          cancelText: "Abbrechen",
          title: "Beziehungs-Export erkannt",
          message: "Willst Du den Export in die Datenbank importieren?<br/>Dabei werden Daten überschrieben.",
          type: "is-warning",
          onConfirm: () => {
            console.log("mitglieder funktions export")
            let data = new FormData();
            data.append('file', this.currentFile, this.currentFile.name)
            this.isLoading = true
            api.post(API_MEMBER_FUNCTION_IMPORT_URL, data).then(() => {
              this.isLoading = false
              this.refetchQueries()
            })
          }
        })
      } else {
        this.$buefy.dialog.alert({
          title: "Unbekannte Datei",
          type: "is-danger",
          confirmText: "OK",
          message: "<b>Leider wurde die Datei nicht erkannt.</b><br/>Es werden lediglich eVewa Mitglieder und MitgliderunktionsBeziehungs-Exporte unterstützt"
        })
        console.log("export konnte nicht erkannt werden")
        this.isLoading = false
      }
      console.log({firstLine});
      this.selectedFile = null
    })
  },
  data() {
    return {
      ImportJobs: [],
      isLoading: false,
      selectedFile: null,
      member_export: null,
      member_function_export: null,
      reader: new FileReader(),
      currentFile: null,
      importStatus: {
        PENDING: {
          icon: 'loading',
          class: 'is-warning',
          iconClass: 'rotating'
        },
        SUCCESS: {
          icon: 'check',
          class: 'is-success',
          iconClass: ''
        },
        FAILURE: {
          icon: 'close',
          class: 'is-danger',
          iconClass: ''
        }
      }
    }
  }
}
</script>

<style scoped>

.rotating {
  animation: rotate 1s cubic-bezier(0.36, 0.76, 0.83, 0.37) 0s infinite;;
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(760deg);
  }
}

.logbox {
  max-height: 100px;
  white-space: pre-wrap;
  overflow-y: scroll;
  background: rgba(0, 0, 0, .7);
  color: white;
  font-family: "DejaVu Sans Mono", monospace;
  font-size: 0.8em;
}
</style>