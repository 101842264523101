<template>
    <div>
        <div class="headline is-borderless">E-Mail Vorlagen</div>
        <template-list-item class="m-b-md" @load="$emit('load', template)" @deleted="() => $emit('deleted', template)"
                            :class="{'editingTemplate': template.id === editingId}"
                            v-for="template in MailTemplates"
                            :key="template.id" :template="template"/>
    </div>
</template>

<script>
import GET_MAIL_TEMPLATES_QUERY from '../../graphql/Query/MailTemplates.gql'
import TemplateListItem from './MailTemplateListItem'

export default {
  props: ['editingId'],
  components: {
    TemplateListItem
  },
  name: 'mail-template-list',
  data () {
    return {
      MailTemplates: []
    }
  },
  apollo: {
    MailTemplates: {
      query: GET_MAIL_TEMPLATES_QUERY,
      variables: {
        includeIsUsed: true
      }
    }
  }
}
</script>

<style>
.editingTemplate {
    background-color: #e4e4e4;
}

.editingTemplate a {
    color: black !important;
}

</style>
