<template>
  <div>
    <div v-if="title" class="headline is-borderless" style="margin-bottom:.75rem;">{{ title }}</div>
    <article class="media">
      <figure class="media-left">
        <p class="image">
          <!--<b-icon icon="account"></b-icon>-->
          <base-avatar :size="30" :user="$store.state.auth.user"></base-avatar>
        </p>
      </figure>
      <div class="media-content">
        <comment-editor :id="id" :mutation="mutation"/>
      </div>
    </article>
    <template v-for="(entity,index) in grouped_entities">
      <comment v-if="entity[0] && entity[0].typename === 'Comment'" :key="index" :comment="entity[0]"/>
      <log v-if="entity[0] && entity[0].typename === 'SeminarAudit'" :key="index" :log="entity"/>
    </template>
  </div>
</template>

<script>
import BaseAvatar from './BaseAvatar'
import CommentEditor from "./CommentEditor";
import Comment from "./Comment";
import Log from "./Log";

export default {
  components: {
    Log,
    Comment,
    CommentEditor,
    BaseAvatar
  },
  name: 'comments',
  props: ['comments', 'id', 'mutation', 'title', 'logs'],
  computed: {
    /*grouped_entities() {
      return this.entities.reduce((accumulator, current, index) => {
        let last = accumulator[accumulator.length - 1];
        if (
            index > 0 &&
            current.typename === 'SeminarAudit' &&
            last.typename === 'SeminarAudit' &&
            //current.type === last.type &&
            current.user.id === last.user.id //(&&

              //last.type === 'UPDATED_PARTICIPATION_LIST' ||
              //last.type === 'SENT_MAIL'
            //)
        ) {
          if (!last['group']) {
            let group = [{...last}];
            last.group = group;
            last.created_at = current.created_at
          }
          last.group = [...last.group, current];

          return accumulator
        }
          return [...accumulator, current]
        }, []
      )
    },*/
    grouped_entities() {
      return this.entities.reduce((a, b, i, v) => {
        let l = v[i - 1]
        if (i === 0 || !(
            b.typename === 'SeminarAudit' &&
            l.typename === 'SeminarAudit' &&
            //current.type === last.type &&
            (b.user && l.user && (b.user.id === l.user.id)) //(&&)
        )
        ) {
          a.push([]);
        }
        a[a.length - 1].push(b);

        return a;
      }, []);
    },
    entities() {
      if (!!this.logs) {
        return [...this.comments, ...this.logs].sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      } else {
        return this.comments.slice().reverse()
      }
    }
  },

}
</script>

<style lang="scss" scoped>


.addComment {
  height: 2em;
  min-height: 2em;
}

.media-left {
  width: 1.8rem;
}

.media-content {
  max-width: 95%;
  max-width: calc(100% - 3em);
}

.media + .media {
  padding-top: 1rem;
  margin-top: 1rem;
  border: 0;
}

.media.comm + .media.log {
  margin-top: .5rem;
}

.media.log + .media.comm {
  margin-top: .5rem;
}
</style>

