<template>
  <base-page v-if="!initLoading">
    <template slot="title">
      <span class="mr-2">{{ 'Seminartyp - ' + Template.name }}</span>
      <tags :id="id" :mutation="require('../../graphql/Mutations/updateTemplate.gql')" :tags="Template.tags"
            @click="tag => {$router.push({name: 'Templates', query: {query: tag}})} "></tags>
    </template>
    <warning-comments :comments="Template.comments"></warning-comments>
    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="card" style="padding:.75rem;">
          <div class="content is-marginless">
            <div class="headline is-borderless" v-if="!editing">Beschreibung</div>
            <!--<h3>{{Seminar.template.name}}</h3>-->
            <div v-if="!editing" class="is-marginless">
              <div v-if="Template.description === null || Template.description === ''">
                <i>keine Beschreibung.. .</i>
              </div>
              <div v-else v-html="$options.filters.marked(Template.description)"></div>
            </div>
          </div>
          <template v-if="editing">
            <b-field label="Name">
              <b-input v-model="editingFields.name"></b-input>
            </b-field>
            <b-field label="Beschreibung">

              <markdown-editor v-model="editingFields.description"
                               preview-class="content"
                               :configs="{'spellChecker': false, 'status':false}">
              </markdown-editor>
            </b-field>
          </template>
          <template v-if="$store.getters.hasPermission('manage templates')">
            <br/>
            <button @click="edit" v-if="editing === false" class="button">Bearbeiten</button>
            <template v-else>
              <button @click="saveTemplate" :class="{'is-loading' : isLoading}"
                      class="is-success button mr-2">
                Speichern
              </button>
              <button @click="editing = false" class="is-danger button">Abbrechen</button>
            </template>
          </template>
        </div>
      </div>
      <div class="column is-half-desktop">
        <div class="headline is-borderless">Einstellungen</div>
        <div class="card" style="padding:.75rem;">
          <div class="field">
            <b-switch :value="Template['state']"
                      :disabled="!$store.getters.hasPermission('manage templates')"
                      @input="setCheckbox('state', $event)"
                      type="is-success">
              Seminartyp aktiviert
            </b-switch>
          </div>
          <div class="field">
            <b-switch :value="Template['visible_in_ea']"
                      :disabled="!$store.getters.hasPermission('manage templates')"
                      @input="setCheckbox('visible_in_ea', $event)"
                      type="is-success">
              Sichtbar in Ehrenamtsakademie
            </b-switch>
          </div>
          <div class="field">
            <b-switch :disabled="!$store.getters.hasPermission('manage templates')"
                      :value="Template['selfCertificationEnabled']"
                      type="is-success"
                      @input="setCheckbox('selfCertificationEnabled', $event)">
              Selbstvergabe aktiviert
              <div v-if="Template['selfCertificationEnabled']"><a :href="Template['selfCertificationUrl']"
                                                                  target="_blank">Selbstvergabelink
                <b-icon class="m-l-sm" icon="open-in-new" style="vertical-align: bottom;"/>
              </a></div>
            </b-switch>
          </div>

        </div>
        <template v-if="$config.SHOW_SEMINAR_REGISTER">
          <div class="headline is-borderless">Online Anmeldung Standardkonfiguration</div>
          <div class="card" style="padding:.75rem;">
            <div class="field">
              <b-switch :disabled="!$store.getters.hasPermission('manage templates')"
                        :value="Template['isRegistrable']"
                        type="is-success"
                        @input="setCheckbox('isRegistrable', $event)">
                Online Anmeldung aktivieren
              </b-switch>
            </div>
            <editable v-if="$config.SHOW_PRICE" v-slot="{editData, loading, mutate, editing, edit}"
                      :data="Template"
                      :mutation="require('../../graphql/Mutations/updateTemplate.gql')" input-arg="template">
              <b-field>
                <div class="control" style="width:3.5em;">
                  Preis:
                </div>
                <b-input v-if="editing"
                         v-model="editData.defaultPrice"
                         v-parse-float
                         :value="Template['visible_in_ea']"
                         placeholder="Standardpreis eingeben"
                         step="0.01"
                         type="number"
                         @keydown.enter.native="mutate">
                </b-input>
                <div v-else class="control">
                  <template v-if="Template.defaultPrice != null">{{ Template.defaultPrice }} €</template>
                  <template v-else>Kein Preis angegeben</template>
                </div>
                <a v-if="$store.getters.hasPermission('manage templates') && !editing" class="ml-2" @click="edit(true)">
                  <b-icon icon="pencil"/>
                </a>
                <div class="control">
                  <button v-if="editing" :class="{'is-loading' : loading}" class="button" @click="mutate">
                    <b-icon icon="check"/>
                  </button>
                </div>
                <div class="control">
                  <button v-if="editing" class="button" @click="edit(false)">
                    <b-icon icon="close"/>
                  </button>
                </div>
              </b-field>
            </editable>
          </div>
        </template>
        <div class="headline is-borderless">
          Seminarvoraussetzungen
          <div class="sub">(Alle müssen erfüllt sein)</div>
        </div>

        <template-requirements-list :template="Template"/>

        <div class="headline is-borderless">
          Funktionsvoraussetzungen
          <div class="sub">(Eine muss erfüllt sein)</div>
        </div>
        <template-requirements-list :template="Template" is-function/>
        <div class="headline is-borderless">
          Teilnahmeinfoseite Standardkonfiguration
        </div>
        <div>
          <b-field>
            <b-switch :value="Template['defaultPageEnabled']"
                      :disabled="!$store.getters.hasPermission('manage templates')"
                      @input="setCheckbox('defaultPageEnabled', $event)"
                      type="is-success">
              Teilnahmeinfoseite aktivieren
            </b-switch>
          </b-field>
          <b-field>
            <div>
              <template v-if="editingPageDefault">
                <markdown-editor v-model="editingFields.defaultPageContent"
                                 preview-class="content"
                                 class="mb-5"
                                 :configs="{'spellChecker': false, 'status':false}">
                </markdown-editor>
              </template>
              <div class="notification" v-else>
                <div v-if="Template.defaultPageContent === null || Template.defaultPageContent === ''">
                  <i>kein Text...</i>
                </div>
                <div v-else v-html="$options.filters.marked(Template.defaultPageContent)" class="content"></div>
              </div>
              <template v-if="$store.getters.hasPermission('manage templates')">
                <button @click="editPageDefault" v-if="editingPageDefault === false" class="button">Bearbeiten</button>
                <template v-else>
                  <button @click="saveTemplate" :class="{'is-loading' : isLoading}"
                          class="is-success button mr-2">
                    Speichern
                  </button>
                  <button @click="editingPageDefault = false" class="is-danger button">Abbrechen</button>
                </template>
              </template>
            </div>
          </b-field>
        </div>
      </div>
      <div class="column is-half-desktop">
        <div class="headline is-borderless">
          Seminarmaterialien
        </div>
        <template-uploads :template="Template"/>
        <template v-if="$config.SHOW_DISTRICTS">
          <div class="headline is-borderless">Berechtigungen</div>
          <template-permissions :template="Template"/>
        </template>
      </div>
    </div>
    <div class="headline">Kommentare</div>
    <comments :comments="Template.comments" :id="id" class="m-t-md"
              :logs="Template.audits"
              :mutation="require('../../graphql/Mutations/updateTemplate.gql')"></comments>

  </base-page>
</template>

<script>
import GET_TEMPLATE_QUERY from '../../graphql/Query/Template.gql'
import UPDATE_TEMPLATE_MUTATION from '../../graphql/Mutations/updateTemplate.gql'
import Comments from "../Elements/Comments";
import TemplateRequirementsList from "../Elements/Template/TemplateRequirementsList";
import BasePage from "../Layout/BasePage";
import WarningComments from "../Elements/WarningComments";
import TemplatePermissions from "../Elements/Template/TemplatePermissions";
import TemplateUploads from "../Elements/Template/TemplateUploads";
import MarkdownEditor from "../Elements/MarkdownEditor";
import Tags from "../Elements/Tags";
import Editable from "../Elements/Editable";

export default {
  name: "Template",
  components: {
    Editable,
    Tags,
    MarkdownEditor,
    TemplateUploads, TemplatePermissions, WarningComments, BasePage, TemplateRequirementsList, Comments
  },
  props: ['id'],
  data() {
    return {
      Template: {},
      initLoading: 0,
      isLoading: false,
      editing: false,
      editingPageDefault: false,
      editingFields: {
        description: '',
        defaultPageContent: ''
      }
    }
  },
  apollo: {
    Template: {
      query: GET_TEMPLATE_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      loadingKey: 'initLoading'
    }
  },
  methods: {
    edit() {
      this.editing = true
      this.editingFields = {
        description: this.Template.description,
        name: this.Template.name
      }
    },
    editPageDefault() {
      this.editingPageDefault = true
      this.editingFields = {
        defaultPageContent: this.Template.defaultPageContent
      }
    },
    saveTemplate() {
      this.isLoading = true

      this.$apollo.mutate({
        mutation: UPDATE_TEMPLATE_MUTATION,
        variables: {
          id: this.id,
          template: this.editingFields
        }
      }).then(() => {
        this.isLoading = false
        this.editing = false
        this.editingPageDefault = false
      })
    },
    setCheckbox(field, value) {

      let variables = {}
      variables[field] = value
      this.$apollo.mutate({
        mutation: UPDATE_TEMPLATE_MUTATION,
        variables: {
          id: this.id,
          template: variables
        }
      }).then(() => {

      })
    }
  }
}
</script>

<style scoped>

</style>
