<template>
    <a @click="removeCertificate">
        <b-loading :active="isLoading" :is-full-page="false" style="font-size:0.5rem;"/>
        <b-icon icon="delete" type="is-danger" ></b-icon>
    </a>
</template>

<script>
import UPDATE_MEMBER_MUTATION from '../../graphql/Mutations/updateMember.gql'

export default {
  name: "removeCertificate",
  data() {
    return {
      isLoading: false
    }
  },
  props: ['memberId', 'certificateId'],
  methods: {
    removeCertificate() {
      this.$buefy.dialog.confirm({
        title: 'Teilnahme entfernen',
        message: 'Sind Sie sicher, dass sie die manuelle Seminarteilnahme/Kompetenzbestätigung entfernen möchten?',
        confirmText: 'Ja, entfernen!',
        type: 'is-danger',
        onConfirm: () => {
          this.isLoading = true
          this.$apollo.mutate({
            mutation: UPDATE_MEMBER_MUTATION,
            variables: {
              id: this.memberId,
              removeCertificateId: this.certificateId
            }
          }).then(() => {
            this.isLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
