<template>
  <div class="partidetail">
    <div class="headline is-borderless" style="">
      <router-link :is="participation.member.state ? 'router-link' : 'span'"
                   :to="{ name: 'Member', params: { id: participation.member.id }}">
        {{
          (participation.member.title ? participation.member.title + ' ' : '') + participation.member.firstName + ' ' + participation.member.lastName
        }}
      </router-link>
      <tags-simple :tags="participation.member.tags"
                   :all-types="true"
                   @click="tag => { $store.commit('memberFilter/setFilters', {textSearch : tag}); $router.push({name:'AddParticipantToSeminar'});}"/>
    </div>
    <div class="memberinfo">


      <div class="columns is-mobile">
        <div class="columns column is-multiline is-mobile" style="margin-bottom: 0;">

          <div class="column is-one-quarter">
            <label>Mitgliedernr.</label>
            {{ participation.member.mitgliedernummer }}
          </div>
          <div class="column is-one-quarter">
            <label>Telefon</label>
            {{ participation.member.phone }}
          </div>
          <div class="column is-one-quarter">
            <label>E-Mail</label>
            <a :href="'mailto:'+participation.member.email">{{ participation.member.email }}</a>
          </div>
          <div class="column is-one-quarter">
            <label>Datenschutz</label>
            <template v-if="participation.member.privacy_at">am {{
                participation.member.privacy_at |
                    moment('DD.MM.YY')
              }} akzept.
            </template>
            <template v-else>nicht akzeptiert</template>
          </div>
          <div class="column is-one-quarter">
            <label>Eingetragen am</label>
            <template v-if="!participation.created_at">nicht vorhanden</template>
            <template v-else>{{ participation.created_at | moment('DD.MM.YY') }}</template>
          </div>
          <div class="column is-one-quarter">
            <label>Eingeladen am</label>
            <template v-if="!editing">
              <template v-if="!participation.inviteDate">nicht vorhanden</template>
              <template v-else>{{ participation.inviteDate | moment('DD.MM.YY') }}</template>
            </template>
            <template v-else>
              <date-picker v-model="editingData.inviteDate"/>
            </template>
          </div>
          <div class="column is-one-quarter">
            <label>Nachgerückt am</label>
            <template v-if="!participation.moveupDate">nicht vorhanden</template>
            <template v-else>{{ participation.moveupDate | moment('DD.MM.YY') }}</template>
          </div>
          <div class="column is-one-quarter">
            <label>Abgesagt am</label>
            <template v-if="!editing">
              <template v-if="!participation.cancelDate">nicht vorhanden</template>
              <template v-else>{{ participation.cancelDate | moment('DD.MM.YY') }}</template>
            </template>
            <template v-else>
              <date-picker v-model="editingData.cancelDate"/>
            </template>
          </div>
        </div>
        <div v-if="seminar.canManageParticipations" class="action column is-narrow has-text-right">

          <template v-if="editing">
            <button :class="{'is-loading': isLoading}" class="button is-success"
                    @click="saveParticipation()">Speichern
            </button>
            <button class="button  is-danger "
                    @click="editing = false">Abbrechen
            </button>
          </template>
          <template v-else>
            <button class="button" @click="edit()">
              <span>Bearbeiten</span>
              <b-icon icon="pencil" size="is-small"></b-icon>
            </button>
          </template>

          <router-link v-if="participation.member.email && !editing"

                       :to="{ name: 'MailToSeminar', params:{ toIds: [participation.id]}}"
                       class="button" tag="button">

            <span>Mail schreiben</span>
            <b-icon icon="email" size="is-small"></b-icon>
          </router-link>

          <button v-if="seminar.status === 'PLANNING' && !editing" :class="{'is-loading' : isLoadingDelete}"
                  class="button is-danger"
                  @click="deleteParticipation(participation)">
            <span>Teiln. Löschen</span>
            <b-icon icon="delete" size="is-small"></b-icon>
          </button>
        </div>
      </div>
    </div>
    <template v-if="participation.mails.length > 0">
      <div class="headline is-borderless is-marginless" style="margin-top:1em !important;">Gesendete E-Mails</div>
    <MemberMailList :mails="participation.mails" class="memberMails"  />
    </template>
  </div>
</template>

<script>
import Comments from '../../Elements/Comments'
import DatePicker from '../../Elements/DatePicker'
import UPDATE_PARTICIPATION_MUTATION from '../../../graphql/Mutations/updateParticipation.gql'
import DELETE_PARTICIPATION_MUTATION from '../../../graphql/Mutations/deleteParticipation.gql'
import {deleteParticipationFromSeminar} from '@/cache/cacheUpdater'
import Template from "../../Pages/Template";
import MemberMailList from "./MemberMailList";
import TagsSimple from "../TagsSimple";

export default {
  name: 'participation-detail',
  components: {
    TagsSimple,
    MemberMailList,
    Template,
    Comments,
    DatePicker
  },
  data() {
    return {
      editing: false,
      editingData: {},
      isLoading: false,
      isLoadingDelete: false
    }
  },
  props: ['participation', 'seminar'],
  watch: {},
  methods: {
    saveParticipation() {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_PARTICIPATION_MUTATION,
        variables: {
          id: this.participation.id,
          participation: this.editingData
        }
      }).then(() => {
        this.isLoading = false
        this.editing = false
      })
    },
    edit() {
      this.editing = true
      this.editingData = {
        inviteDate: this.participation.inviteDate,
        cancelDate: this.participation.cancelDate
      }
    },
    deleteParticipation() {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        message: 'Möchten Sie diese Teilnahme wirklich löschen?',
        onConfirm: () => {
          this.isLoadingDelete = true
          this.$apollo.mutate({
            mutation: DELETE_PARTICIPATION_MUTATION,
            variables: {
              id: this.participation.id
            },
            update: (store) => deleteParticipationFromSeminar(store, this.seminar.id, this.participation.id)
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">

.partidetail{
  padding: .75em .5em;
}
.memberMails {
  margin: .75em 0;
  // margin: 0 -1.5rem -1.25rem;
}

.memberinfo {
 // margin: -.5em;

  .column {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    text-overflow: ellipsis;
  }

  label {
    font-weight: bold;
    display: block;
  }

  div {
    white-space: nowrap;
  }

  .action {
    button {
      display: block;
      width: 10em;
      text-align: center;
      margin-bottom: .5em;
    }
  }
}
</style>