<template>
    <div class="notifications">
        <div v-for="notification in groupedNotificatons" v-if="Notifications">
            <div class="media">
                <div class="media-left has-text-centered">
                    <router-link :to="{name: 'Seminar', params: {id: notification.subjectId}}" class="has-text-dark">
                        <b-icon :icon="getIcon(notification)" class="is-32x32"></b-icon>
                        <div class="">
                            {{notification.subjectId}}
                        </div>
                    </router-link>
                </div>
                <div class="media-content">
                    <router-link :to="{name: 'Seminar', params: {id: notification.subjectId}}" class="has-text-dark">
                        <p>
                            <template v-if="notification.type === 'SeminarParticipationsUpdated'">
                                Änderung an Teilnahmeliste
                                <template v-if="notification.id.length > 0">({{notification.id.length}})</template>
                            </template>
                            <template v-if="notification.type === 'SeminarStatusUpdated'">Status wurde auf
                                <i>{{notification.subjectData}}</i> gestellt
                            </template>
                        </p>
                    </router-link>
                    <small>vor {{notification.created_at | moment('from', 'now', true)}}</small>
                </div>
                <div class="media-right">
                    <button class="button" @click="markAsRead(notification.id)">
                        <b-icon icon="check" type="is-dark"></b-icon>
                    </button>
                </div>
            </div>

        </div>
        <div class="has-text-centered m-t-md actions" v-if="Notifications.length > 0">
            <div class="button" @click="markAllAsRead()">
                <b-icon icon="check"></b-icon>
                <span>Alle als gelesen markieren</span>
            </div>
        </div>
        <div v-else class="has-text-centered has-text-grey m-t-md">
            Keine neuen Benachrichtigungen
        </div>
        <b-loading :active="isLoading || $apollo.loading" :is-full-page="false"></b-loading>
    </div>
</template>

<script>
import GET_NOTIFICATIONS_QUERY from "../../graphql/Query/Notifications.gql"
import GET_NOTIFICATION_COUNT_QUERY from "../../graphql/Query/NotificationCount.gql"
import MARK_NOTIFICATIONS_READ_MUTATION from "../../graphql/Mutations/markNotificationsRead.gql"
import BLoading from "buefy/src/components/loading/Loading";
import {removeNotifications} from "../../cache/cacheUpdater";

export default {
  name: "Notifications",
  components: {BLoading},
  data() {
    return {
      Notifications: [],
      isLoading: false
    }
  },
  apollo: {
    Notifications: {
      query: GET_NOTIFICATIONS_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        read: false
      }
    }
  },
  computed: {
    groupedNotificatons() {
      let groupedTypes = ['SeminarParticipationsUpdated', 'SeminarStatusUpdated'];
      return this.Notifications.reduce((accumulator, notification) => {
        if (groupedTypes.indexOf(notification.type) !== -1) {
          let existing = accumulator.find(existing => existing.subjectId === notification.subjectId && existing.type === notification.type)
          if (!!existing) {
            existing.id.push(notification.id)
            return accumulator
          }
        }
        return accumulator.concat({...notification, id: [notification.id]})
      }, [])
    }
  },
  methods: {
    markAllAsRead() {
      this.markAsRead(this.Notifications.map(n => n.id))
    },
    markAsRead(ids) {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: MARK_NOTIFICATIONS_READ_MUTATION,
        update: (store) => removeNotifications(store, ids),
        refetchQueries: [{query: GET_NOTIFICATION_COUNT_QUERY}],
        variables: {
          ids: ids
        }
      }).then(() => {
        this.isLoading = false;
      })
    },
    getIcon(notification) {
      if (notification.type === 'SeminarParticipationsUpdated') {
        return 'account-multiple'
      } else {
        return 'information-outline'
      }
    }
  }
}
</script>

<style scoped>

.
.actions {
    /*border-top: 1px solid rgba(219, 219, 219, 0.5);*/
    padding: 0.5rem;
}

.notifications {
    position: relative;
    overflow: auto;
    max-height: 100%;
    padding: 1rem .5rem;
}

.media {
    padding: .5em;
    border-bottom: 1px solid lightgrey;
}

.router-link-exact-active {
    font-weight: bold;
}

.circle {
    border: 1px solid black;
    padding: .25rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
</style>