<template>
    <b-select expanded :size="isSmall ? 'is-small' : ''" @input="$emit('input', $event)" :value="value" placeholder="Tagungsort auswählen">
        <option :value="null" class="has-text-grey-light">Nichts auswählen</option>
        <option v-for="place in filteredPlaces" :value="place.id">{{place.name}}</option>
    </b-select>
</template>

<script>
import GET_PLACES_QUERY from '../../graphql/Query/Places.gql'
import {filterObjects} from '../../utils/utils'
// import _ from 'lodash'

export default {
  name: 'places-select',
  data() {
    return {
      textInput: '',
      Places: [],
      isLoading: 0
    }
  },
  methods: {
  },
  apollo: {
    Places: {
      query: GET_PLACES_QUERY,
      variables: {
        filter: {
          state: true
        }
      },
      loadingKey: 'isLoading'
    }
  },
  props: ['value', 'isSmall'],
  computed: {
    filteredPlaces() {
      if (!this.isLoading) {
        return filterObjects(this.Places, 'name', this.textInput).sort(function (a, b) {
          return a.name.localeCompare(b.name);
        })
      }
    }
  }
}
</script>

<style scoped>

</style>