<template>
  <div>

    <b-field style="margin-bottom:.5rem;" v-if="!sendLetter">
      <p class="control">
        <span class="button is-static inputlabels">Antwort an</span>
      </p>
      <p class="control is-expanded">
            <span class="input is-disabled">
                {{ (seminar.replyToEmail || seminar.district.email || 'seminare@ehrenamtsakademie.de') }}
            </span>
      </p>
    </b-field>
    <b-field style="margin-bottom:.5rem;">
      <p class="control">
        <span class="button is-static inputlabels">Betreff</span>
      </p>
      <b-input v-if="!isPreview" v-model="mail.subject" placeholder="Betreff" expanded/>
      <b-input v-else :value="preview.subject" disabled expanded/>
    </b-field>
    <div class="columns is-variable is-1 is-mobile is-multiline">
      <b-field class="column" expanded>
        <p class="control">
          <span class="button is-static inputlabels">Empfänger</span>
        </p>
        <b-taginput class="responsiveTags"
                    expanded
                    ref="taginput"
                    :data="filteredParticipations"
                    :value="mailToParticipations"
                    @input="syncParticipationIds"
                    autocomplete
                    open-on-focus
                    field="member.fullName"
                    icon="label"
                    placeholder="Empfänger hinzufügen"
                    @typing="input => this.tagInputValue = input">


          <template slot="empty">
            There are no items
          </template>
          <template slot="selected" slot-scope="props">
            <b-tag
                v-for="(tag, index) in props.tags"
                :key="index"
                :type="getType(tag)"
                rounded
                :tabstop="false"
                ellipsis
                closable
                @close="$refs.taginput.removeTag(index, $event)">
              {{ tag.member.fullName }}
            </b-tag>
          </template>
        </b-taginput>
      </b-field>
      <!--<div class="column is-narrow is-full-mobile">
        <div class="button is-flex-mobile" @click="isModalActive = true">
          <b-icon icon="book-open-page-variant" size="is-small"></b-icon>
          <span>Empfängerliste</span>
        </div>
      </div>-->
    </div>
    <!--<b-modal :active.sync="isModalActive">
      <b-table
          :data="Participations"
          :mobile-cards="false"
          @check="syncParticipationIds"
          :checked-rows="mailToParticipations"
          narrowed
          default-sort-direction="ASC"
          default-sort="status"
          checkable>
        <b-table-column v-slot="props" field="fullName" label="Name" sortable>
          {{ props.row.member.fullName }}
        </b-table-column>
        <b-table-column v-slot="props" field="status" label="Status" sortable>
          <participation-status-label :status="props.row.status"/>
        </b-table-column>
        <template slot="empty">
          <empty-table>Dieses Seminar hat noch keine Teilnehmenden mit E-Mail-Adresse.</empty-table>
        </template>
      </b-table>
    </b-modal>-->
    <div class="mb-3">
        <textarea v-if="!isPreview"
                  class="textarea"
                  ref="mailarea"
                  :disabled="isPreview"
                  placeholder="Text eingeben..."
                  v-model="mail.body">
                </textarea>
      <textarea v-else
                class="textarea"
                :value="preview.body"
                disabled></textarea>
    </div>
    <div class="m-b-sm">
      <div class="columns">
        <div class="column">
          <button @click="submit"
                  :disabled="!(mail.body !== '' && mail.subject !== '' && mailToParticipations.length > 0)"
                  :class="{'is-loading': isSending}"
                  class="button is-success mr-2">
            <span>{{ sendLetter ? 'Brief exportieren' : 'E-Mails senden' }}</span>
          </button>
          <button :disabled="!(mail.body !== '' || mail.subject !== '' || mailToParticipations.length > 0)"
                  @click="clearAll" class="button is-danger">
            {{ sendLetter ? 'Brief' : 'E-Mail' }} verwerfen
          </button>
        </div>
        <div class="column is-narrow">
          <b-dropdown>
            <template v-slot:trigger>
              <button :disabled="isPreview"
                      class="button"
                      @click="keepfocus">
                <b-icon icon="console-line" size="is-small"></b-icon>
                <span>Platzhalter einsetzen</span>
              </button>
            </template>
            <b-dropdown-item v-for="item in placeholders"
                             :key="item.placeholder"
                             @click="insertPlaceholder(item)">
              {{ item.placeholder }}<br/>
              <span class="replacement"><small>{{ item.replacement }}</small></span>
            </b-dropdown-item>
          </b-dropdown>
          <b-autocomplete
              v-model="templateQuery"
              :data="MailTemplates.filter(t => t.name.toLowerCase().indexOf(templateQuery.toLowerCase()) !== -1)"
              class="templateSelect is-inline-block mr-2 ml-2"
              dropdown-position="bottom"
              field="name"
              icon="bookshelf"
              max-height="500px"

              open-on-focus
              placeholder="Vorlage auswählen"
              @select="loadTemplate"/>
          <!--<b-dropdown>
            <div class="mr-2" slot="trigger">
              <button class="button templateSelect"
                      v-if="!templateFocus"
                      @click="templateFocus = true; $nextTick(() => {$refs.templateInput.$children[0].focus() })">
                <b-icon icon="bookshelf" size="is-small"></b-icon>
                <span>Vorlage auswählen</span>
              </button>
              <clearable-input class="templateSelect" v-model="templateQuery" placeholder="Vorlage suchen" v-else
                               ref="templateInput"
                               @focusout.native="templateFocus = templateQuery !== ''"></clearable-input>
            </div>
            <b-dropdown-item
                v-for="template in MailTemplates.filter(t => t.name.toLowerCase().indexOf(templateQuery.toLowerCase()) !== -1)"
                :key="template.id"
                @click="loadTemplate(template)">
              {{ template.name }}
            </b-dropdown-item>
          </b-dropdown>-->
          <button class="button is-inline-block"
                  @click="isPreview = !isPreview">
            <b-icon icon="eye" size="is-small"></b-icon>
            <span>Vorschau</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {startDownload} from "@/utils/utils";
import moment from 'moment'
import autosize from 'autosize'
import ParticipationStatusLabel from "./ParticipationStatusLabel";
import UPDATE_SEMINAR_MUTATION from "../../../graphql/Mutations/updateSeminar.gql"
import DOWNLOAD_SEMINAR_LETTER_MUTATION from "../../../graphql/Mutations/downloadSeminarLetter.gql"
import GET_MAIL_TEMPLATES_QUERY from '../../../graphql/Query/MailTemplates.gql'
import EmptyTable from "../EmptyTable";
import {PARTICIPATION_STATUS} from "@/constants";
import ClearableInput from "../ClearableInput";


export default {
  name: "SeminarMail",
  components: {ClearableInput, EmptyTable, ParticipationStatusLabel},
  props: {
    seminar: {
      type: Object,
      required: true
    },
    toIds: {
      type: Array
    },
    sendLetter: {
      type: Boolean
    }
  },
  apollo: {
    MailTemplates: {
      query: GET_MAIL_TEMPLATES_QUERY
    }
  },
  data() {
    return {
      templateFocus: false,
      templateQuery: '',
      isSending: false,
      MailTemplates: [],
      mail: {
        subject: '',
        body: ''
      },
      mailToIds: _.isArray(this.toIds) ? this.toIds : [],
      isPreview: false,
      isModalActive: false,
      tagInputValue: ''
    }
  },
  computed: {
    preview() {
      return {
        subject: this.replaceText(this.mail.subject, this.placeholders, true),
        body: this.replaceText(this.mail.body, this.placeholders, true)
      }
    },
    placeholders() {
      if (!this.seminar || !this.seminar.appointments || !this.seminar.template) return []
      let appointmentBlock = ''
      let onePlace = this.seminar.appointments.map(a => a.place ? a.place.id : 0).filter((val, index, self) => self.indexOf(val) === index).length === 1

      if (onePlace) {
        appointmentBlock += '\nOrt: ' + this.placeInfo(this.seminar.appointments[0].place) + '\n';
      }
      for (let i = 0; i < this.seminar.appointments.length; i++) {
        if (this.seminar.appointments.length > 1) appointmentBlock += '\n' + (i + 1) + '. Termin: '
        appointmentBlock += this.appiontmentInfo(this.seminar.appointments[i], !onePlace)
        //if (i < this.seminar.appointments.length - 1) appointmentBlock += '\n'
      }
      return [
        {
          'placeholder': '##ANREDE##',
          'replacement': 'Frau',
          'serverSide': true
        },
        {
          'placeholder': '##BRIEFANREDE##',
          'replacement': 'Sehr geehrte Frau Muster',
          'serverSide': true
        },
        {
          'placeholder': '##TITEL##',
          'replacement': 'Dr.',
          'serverSide': true
        },
        {
          'placeholder': '##VORNAME##',
          'replacement': 'Erika',
          'serverSide': true
        },
        {
          'placeholder': '##NACHNAME##',
          'replacement': 'Musterfrau',
          'serverSide': true
        },
        {
          'placeholder': '##MITGLIEDERNUMMER##',
          'replacement': '123456789',
          'serverSide': true
        },
        {
          'placeholder': '##SEMINARNAME##',
          'replacement': this.seminar.template.name,
          'serverSide': false
        },
        {
          'placeholder': '##SEMINARNUMMER##',
          'replacement': this.seminar.id,
          'serverSide': false
        },
        {
          'placeholder': '##PREIS##',
          'replacement': this.seminar.price === 0 ? 'kostenfrei' : this.seminar.price !== null ? this.seminar.price + ' €' : '-- Kein Preis --',
          'serverSide': false
        },
        {
          'placeholder': '##DATUM_BEGINN##',
          'replacement': this.seminar.appointments.length > 0 ? moment(this.seminar.appointments[0].begin).format('dddd, DD. MMMM YYYY') : '-- Noch kein Termin --',
          'serverSide': false
        },
        {
          'placeholder': '##DATUM_ENDE##',
          'replacement': this.seminar.appointments.length > 0 ? moment(this.seminar.appointments[this.seminar.appointments.length - 1].end).format('dddd, DD. MMMM YYYY') : '-- Noch kein Termin --',
          'serverSide': false
        },
        {
          'placeholder': '##TERMIN_INFO_BLOCK##',
          'replacement': appointmentBlock,
          'serverSide': false
        },
        {
          'placeholder': '##TAGUNGSORT_URL##',
          'replacement': this.seminar.appointments[0]
          && this.seminar.appointments[0].place
          && this.seminar.appointments[0].place.address
          && this.seminar.appointments[0].place.address.url
              ? this.seminar.appointments[0].place.address.url
              : '-- Noch keine URL --',
          'serverSide': false
        },
        {
          'placeholder': '##OEFFENTLICHE_BESCHREIBUNG##',
          'replacement': this.seminar.description || '-- Noch keine beschreibung --',
          'serverSide': false
        },
        {
          'placeholder': '##BEZIRK##',
          'replacement': this.seminar.district.name,
          'serverSide': false
        },
        {
          'placeholder': '##BEZIRK_EMAIL##',
          'replacement': this.seminar.district.email,
          'serverSide': false
        },
        {
          'placeholder': '##TEILNAHME_INFOSEITE##',
          'replacement': this.seminar.pageUrl,
          'serverSide': false
        },
        {
          'placeholder': '##ICH##',
          'replacement': this.$store.state.auth.user.fullName,
          'serverSide': false
        }
      ]
    },
    Participations() {
      return this.sendLetter ? this.seminar.participations : this.seminar.participations.filter(p => !!p.member.email)
    },
    filteredParticipations() {
      return this.Participations
          .filter(p => p.member.fullName.toLowerCase().includes(this.tagInputValue.toLowerCase()))
          .filter(p => this.mailToIds.indexOf(p.id) === -1)
    },
    mailToParticipations() {
      return this.Participations.filter(p => this.mailToIds.indexOf(p.id) !== -1)
    }
  },
  methods: {
    getType(tag) {
      return PARTICIPATION_STATUS[tag.status].class
    },
    appiontmentInfo(appointment, includePlace = false) {
      let info = ''
      if (moment(appointment.begin).isSame(appointment.end, 'day')) {
        info += moment(appointment.begin).format('dddd, DD. MMMM YYYY') + ', ' + moment(appointment.begin).format('HH:mm') + ' - ' + moment(appointment.end).format('HH:mm')
      } else {
        info += moment(appointment.begin).format('dddd, DD. MMMM YYYY, HH:mm') + ' bis ' + moment(appointment.end).format('dddd, DD. MMMM YYYY, HH:mm')
      }
      if (appointment.place && includePlace) {
        info += '\nOrt: ' + this.placeInfo(appointment.place) + '\n'
      }
      return info
    },
    placeInfo(place) {
      if (!place) return 'Noch kein Tagungsort'
      let info = place.name
      if (place.contactAddress) {
        info = place.contactAddress.toString
      }
      return info;
    },
    genLetters() {
      this.$apollo.mutate({
        mutation: DOWNLOAD_SEMINAR_LETTER_MUTATION,
        variables: {
          id: this.seminar.id,
          letter: this.mail,
          participationIds: this.mailToIds
        }
      }).then(({data}) => {
        startDownload(data.downloadSeminarLetter.FileName, data.downloadSeminarLetter.FileContent)
      })

    },
    submit() {
      if (this.sendLetter) {
        this.genLetters()
      } else {
        this.sendMails()
      }
    },
    sendMails() {
      this.$buefy.dialog.confirm({
        title: 'Wirklich fortfahren?',
        type: 'is-success',
        message: 'Die Mails werden versandt.',
        confirmText: 'Ja, fortfahren!',
        onConfirm: () => {
          this.isSending = true
          this.$apollo.mutate({
            mutation: UPDATE_SEMINAR_MUTATION,
            variables: {
              id: this.seminar.id,
              includeParticipations: true,
              sendMails: {
                ParticipationIds: this.mailToIds,
                mail: this.mail
              }
            }
          }).then(() => {
            this.isSending = false;
            this.mailToIds = []
            this.mail = {
              subject: '',
              body: ''
            }
            this.updateSize()
            this.$buefy.toast.open({
              message: 'Die Mails wurden versandt.',
              type: 'is-success'
            })
          })
        }
      })
    },
    clearAll() {
      this.$buefy.dialog.confirm({
        title: 'Wirklich fortfahren?',
        type: 'is-danger',
        message: (this.sendLetter ? 'Der Brief' : 'Die Mail') + ' wird gelöscht.',
        confirmText: 'Ja, fortfahren!',
        onConfirm: () => {
          this.mailToIds = []
          this.mail = {
            subject: '',
            body: ''
          }
          this.updateSize()

        }
      })
    },
    syncParticipationIds(participations) {
      this.mailToIds = participations.map(p => p.id)
    },
    replaceText(text, placeholders, preview = false) {
      let output = text
      placeholders.filter(placeholder => preview ? true : !placeholder.serverSide).forEach(p => {
        let placeholder = new RegExp(p.placeholder, 'gi')
        output = output.replace(placeholder, p.replacement)
      })
      return output
    },
    loadTemplate(template) {
      if (template === null) return;
      this.mail.subject = this.replaceText(template.subject, this.placeholders)
      this.mail.body = this.replaceText(template.body, this.placeholders)
      if (this.$refs.mailarea) {
        this.$refs.mailarea.focus()
        this.updateSize()
      }
      this.$nextTick(() => {
        this.templateQuery = ''
      })
    },
    keepfocus() {
      this.$nextTick(() => {
        this.$refs.mailarea.focus()
      })
    },
    insertPlaceholder(placeholder) {
      this.insertText(placeholder.serverSide ? placeholder.placeholder : placeholder.replacement)
    },
    insertText(text) {
      let pos = this.$refs.mailarea.selectionStart
      this.mail.body = this.mail.body.substr(0, pos) + text + this.mail.body.substr(pos)
      let newpos = pos + text.length
      this.updateSize()
      this.$nextTick(() => {
        this.$refs.mailarea.focus()
        this.$refs.mailarea.setSelectionRange(newpos, newpos)
      })
    },
    updateSize() {
      this.$nextTick(() => {
        autosize.update(this.$refs.mailarea);
      })
    }
  },
  created() {
    this.$nextTick(() => {
      autosize(this.$refs.mailarea)
    })
  },
  watch: {
    toIds(ids) {
      this.mailToIds = _.isArray(ids) ? ids : this.mailToIds
    }
  }
}
</script>

<style scoped>

.templateSelect {
  width: 12em;
}

.textarea {
  min-height: 250px;
  max-height: unset;
}

.inputlabels {
  width: 7em;
  text-align: left;
  justify-content: flex-start;

}

.replacement {
  max-width: 200px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<style lang="scss">
.templateSelect {
  .dropdown-menu {
    max-width: 350px !important;
    width: auto !important;

    .dropdown-item {
      font-size: .85rem;
      //text-overflow: clip !important;
    }
  }

  .control.has-icons-left .icon {
    color: inherit;
  }

  input:not(:focus) {
    box-shadow: unset;

    &::placeholder {
      color: inherit;
    }
  }
}

</style>
