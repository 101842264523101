<template>
  <div class="is-inline-block">
    <a @click="edit">
      <b-icon icon="pencil"></b-icon>
    </a>
    <b-modal :active.sync="modalActive" :has-modal-card="true">
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Todo bearbeiten</div>
        </div>
        <div class="modal-card-body">

          <b-field label="Titel">
            <b-input placeholder="Aufgabe" type="test" v-model="editTodo.name"></b-input>
          </b-field>
          <b-field label="Deadline">
            <div class="block">
              <b-radio :native-value="null"
                       v-model="deadlineType">
                keine Deadline
              </b-radio>
              <b-radio native-value="beginOffsetDays"
                       v-model="deadlineType">
                vor/nach Beginn
              </b-radio>
              <b-radio native-value="endOffsetDays"
                       v-model="deadlineType">
                vor/nach Ende
              </b-radio>
              <b-radio native-value="date"
                       v-model="deadlineType">
                festes Datum
              </b-radio>
            </div>
          </b-field>
          <b-field label="Datum" v-if="deadlineType === 'date'">
            <date-picker v-model="editTodo.date"></date-picker>
          </b-field>

          <b-field label="Tage vor/nach Beginn" message="Negative Werte (-) für davor, positive für danach."
                   v-if="deadlineType === 'beginOffsetDays'">
            <b-input placeholder="Anzahl der Tage vor/nach Seminar" type="number"
                     v-model="editTodo.beginOffsetDays" v-parse-int></b-input>
          </b-field>
          <b-field label="Tage vor/nach Ende" message="Negative Werte (-) für davor, positive für danach."
                   v-if="deadlineType === 'endOffsetDays'">
            <b-input placeholder="Anzahl der Tage vor/nach Seminar" type="number"
                     v-model="editTodo.endOffsetDays" v-parse-int></b-input>
          </b-field>
        </div>
        <div class="modal-card-foot">
          <button :class="{'is-loading' : isLoading}"
                  @click="addTodo"
                  class="button is-success"
          >
            Speichern
          </button>
          <button @click="modalActive=false" class="button is-danger">Abbrechen</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatetimePicker from "../DateTimePicker";
import UPDATE_TODO_MUTATION from '../../../graphql/Mutations/updateTodo.gql'
import DatePicker from "../DatePicker";

export default {
  name: "EditTodo",
  components: {DatePicker, DatetimePicker},
  props: ['todo'],
  data() {
    return {
      modalActive: false,
      isLoading: false,
      deadlineType: null,
      editTodo: {}
    }
  },
  computed: {
    editTodoComputed() {
      let todo = {
        name: this.editTodo.name,
        beginOffsetDays: null,
        endOffsetDays: null,
        date: null
      };
      if (this.deadlineType !== null) {
        todo[this.deadlineType] = this.editTodo[this.deadlineType];
      }
      return todo;
    }
  },
  methods: {
    edit() {
      this.modalActive = true
      if (this.todo.endOffsetDays !== null) {
        this.deadlineType = "endOffsetDays"
      }
      if (this.todo.beginOffsetDays !== null) {
        this.deadlineType = "beginOffsetDays"
      }
      if (this.todo.date !== null) {
        this.deadlineType = "date"
      }
      this.editTodo = {...this.todo}
    },
    addTodo() {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_TODO_MUTATION,
        variables: {
          id: this.todo.id,
          todo: this.editTodoComputed,
        }
      }).then(() => {
        this.isLoading = false
        this.modalActive = false
        this.deadlineType = null
        this.editTodo = {}
      })
    }
  }
}
</script>

<style scoped>

</style>
