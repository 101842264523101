<template>
  <div class="compactComments is-badge-small has-text-right"
       :class="{'badge' : comments.length > 1}"
       :data-badge="comments.length">
    <b-dropdown position="is-bottom-left">
      <a style="color: inherit;" slot="trigger">
        <div v-if="hasComments">
          <div v-for="comment in firstComments">
            <div class="cuttedComment tag" :class="{'is-danger': hasHighComments}">{{ comment.body }}</div>
          </div>
        </div>
        <div style="color: green;" class="tag" v-else>
          <span>Kommentieren...</span>
        </div>
        <!--<b-icon class="" icon="comment"></b-icon>--></a>
      <b-dropdown-item custom paddingless class="has-text-left">

        <div class="compactDropdown">
          <div v-if="title" class="headline is-small is-borderless">{{title}}</div>

          <comments class="m-t-sm" :id="id" :mutation="mutation"
                    :comments="comments"/>
        </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import Comments from './Comments'

export default {
  name: 'compact-comments',
  components: {Comments},
  props: {
    comments: {
      type: Array,
      default: () => []
    },
    id: String,
    mutation: Object,
    title: String,
    cutted: Boolean
  },
  computed: {
    hasComments() {
      return this.comments.length > 0
    },
    hasHighComments() {
      return !!this.comments.find(c => c.highPriority)
    },
    firstComments() {
      if (this.comments.length === 0) {
        return false
      } else {
        let highcomments = this.comments.filter(c => c.highPriority).slice().reverse();
        return highcomments.length > 0 ? highcomments : this.comments.slice(-1);
      }
    }
  }
}
</script>

<style scoped>

.cont {
  text-align: right;
}

.cuttedComment {
  max-width: 250px;
  vertical-align: sub;
  overflow: hidden;
  white-space: pre-wrap;
  display: inline-block;
}

.nowrap {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag {
  height: auto;
  padding: .2rem .5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 2px 8px rgba(0, 0, 0, 0.15);
  background-color: white;
}

.compactDropdown {
  padding: .5rem 1rem 1.5rem 1rem;
}
</style>
<style lang="scss">
@import "../../assets/colors";

.compactComments .dropdown-content {
  background-color: $background-color;

}
</style>
