<template>
  <filters v-slot="{filters, isActive, isActiveWith, isActiveWithout, resetFilter}" name="seminar"
           @input="$emit('input', $event)">
    <div class="columns is-1 is-variable is-mobile is-multiline">
      <div class="column is-full-mobile">
        <clearable-input v-model="filters.textSearch"
                         placeholder="Schnellsuche (Name, Nummer oder Tags)"></clearable-input>
      </div>
      <div class="column is-narrow-desktop is-half-mobile">
        <b-dropdown class="is-block-mobile" position="is-bottom-left">
          <div :class="{'badge' : isActiveWith(['status'])}" class="button is-badge-small is-flex-mobile"
               data-badge=""
               slot="trigger">
            <span>Status</span>
            <b-icon icon="menu-down"></b-icon>
          </div>
          <b-dropdown-item custom>
            <b-checkbox v-model="filters.status" native-value="PLANNING">In Planung</b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item custom>
            <b-checkbox v-model="filters.status" native-value="PUBLIC">Veröffentlicht</b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item custom>
            <b-checkbox v-model="filters.status" native-value="INVITED">Einladung verschickt</b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item custom>
            <b-checkbox v-model="filters.status" native-value="COMPLETED">Durchgeführt</b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item custom>
            <b-checkbox v-model="filters.status" native-value="CANCELED">Abgesagt</b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item custom>
            <b-checkbox v-model="filters.status" native-value="CLEARED">Abgerechnet</b-checkbox>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="column is-narrow-desktop is-half-mobile" v-if="$config.SHOW_DISTRICTS">
        <district-select v-model="filters.districtIn" multiple/>
      </div>
      <div class="column is-half-mobile is-narrow">
        <div class="button is-flex-mobile" @click="resetFilter">
          <b-icon icon="filter-remove" size="is-small"/>
          <span>Zurücksetzen</span></div>
      </div>
      <div class="column  is-half-mobile is-narrow">
        <div :class="{'badge' : isActiveWithout(['textSearch','status', 'districtIn' ])}"
             class="button is-flex-mobile is-badge-small" data-badge=""
             @click="filterOpen = !filterOpen">
          <span>Weitere Filter</span>
          <b-icon :icon="filterOpen? 'menu-up' : 'menu-down'"></b-icon>
        </div>
      </div>
    </div>
    <div v-if="filterOpen" class="columns mb-2 is-multiline">
      <div class="column is-half">
        <b-field label="Seminartyp">
          <template-select v-model="filters.templateId" :hideInactive="true" class="is-inline-block"
                           expanded></template-select>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Seminarnummer">
          <b-input v-model="filters.id" placeholder="Seminarnummer.." type="input"/>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Von" expanded>
          <b-input v-model="filters.after" type="date"></b-input>
        </b-field>
      </div>
      <div class="column is-half">
        <b-field label="Bis" expanded>
          <b-input v-model="filters.before" type="date"></b-input>
        </b-field>
      </div>
      <div class="column is-half is-full-mobile">
        <b-field expanded label="Tags (oder verknüpft)">
          <tags-input v-model="filters.tags"/>
        </b-field>
      </div>
      <div class="column is-half is-full-mobile">
        <b-field label="Tagungsort" expanded>
          <places-select v-model="filters.placeId" expanded style="width: 100%;"/>
        </b-field>
      </div>
    </div>
  </filters>
</template>

<script>
import TemplateSelect from '../../Elements/TemplateSelect'
import PlacesSelect from "../PlacesSelect";
import TagsInput from "../TagsInput";
import ClearableInput from "../ClearableInput";
import DistrictSelect from "../DistrictSelect";
import Filters from "../Filters";

export default {
  name: 'SeminarFilter',
  components: {Filters, DistrictSelect, ClearableInput, TagsInput, PlacesSelect, TemplateSelect},
  data() {
    return {
      filterOpen: false
    }
  }
}
</script>

<style scoped>
</style>
