<template>
  <auth-base>
    <h3 class="title has-text-grey">Passwort zurücksetzen</h3>
    <div class="box has-text-left">
      <template v-if="!SuccessMessage">
        <b-message has-icon type="is-danger" v-if="!!ErrorMessage"><b>Oh, nein.</b><br/>
          {{ErrorMessage}}
          <p v-if="password.length < 8">Das Passwort muss mindestens 8 Zeichen lang sein.</p>
        </b-message>
        <b-field label="E-Mail">
          <b-input @keyup.enter="resetPassword" type="email" v-model="email"/>
        </b-field>

        <b-field label="Neues Passwort">
          <b-input @keyup.enter="resetPassword" type="password" v-model="password"/>
        </b-field>
        <b-field label="Neues Passwort wiederholen">
          <b-input @keyup.enter="resetPassword" type="password" v-model="passwordConfirmation"/>
        </b-field>
        <button :class="{'is-loading': isLoading}" @click="resetPassword" class="button">Passwort ändern</button>

      </template>
      <template v-else>
        <b-message has-icon type=" is-success
        "><b>Super!</b><br/>Dein Passwort wurde geändert. Du kannst dich
          jetzt damit einloggen.
        </b-message>
      </template>
    </div>
    <p class="has-text-grey">
      <router-link to="/login">Login</router-link>
    </p>
  </auth-base>
</template>

<script>
  import AuthBase from './AuthBase'
  import RESET_PASSWORD_MUTATION from '../../graphql/Mutations/resetPassword.gql'

  export default {
    components: {AuthBase},
    data() {
      return {
        password: '',
        passwordConfirmation: '',
        email: '',
        ErrorMessage: '',
        SuccessMessage: '',
        isLoading: false
      }
    },
    name: 'password-reset',
    props: ['token'],
    methods: {
      resetPassword() {
        if (this.password !== this.passwordConfirmation) {
          this.errorMessage = 'Die Passwörter stimmen nicht überein'
          return
        }
        this.isLoading = true
        this.$apollo.mutate({
          mutation: RESET_PASSWORD_MUTATION,
          variables: {
            data: {
              email: this.email,
              password: this.password,
              token: this.token
            }
          }
        }).then(() => {
          this.isLoading = false
          this.SuccessMessage = 'Password erfolgreich geändert'
          this.$router.push('/')
        }).catch(() => {
          this.isLoading = false
          this.ErrorMessage = 'Leider ist ein Fehler ist aufgetreten'
        })
      }
    }
  }
</script>

<style scoped>
  p {
    font-weight: 700;
  }
</style>