<template>
  <div :class="{'checked': todo.done, 'card' : isDash}" class="field m-b-sm m-t-sm todo">
    <div class="columns is-gapless is-mobile">
      <div class="column" v-if="!isDash">
        <b-checkbox :value="todo.done"
                    @click.native="toggleCheck"
                    type="is-dark">
          <b-tooltip
                  v-if="todo.description"
                  dashed
                  multilined
                  position="is-bottom">
            {{ todo.name }}
            <template v-slot:content>
              <div style="white-space: pre-wrap; text-align: left">{{ todo.description }}</div>
            </template>
          </b-tooltip>
          <template v-else>
            {{todo.name}}
          </template>
        </b-checkbox>
      </div>
      <div class="column" v-else>
        <span class="text">{{todo.name}}</span>
      </div>
      <div class="column is-narrow">
        <seminar-todo-deadline :hide-no-deadline="true" :todo="todo"/>
      </div>
      <div class="m-l-sm">
        <edit-todo :todo="todo"></edit-todo>
        <a @click="deleteTodo">
          <b-icon icon="delete" type="is-danger"></b-icon>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import UPDATE_TODO_MUTATION from '../../../graphql/Mutations/updateTodo.gql'
import DELETE_TODO_MUTATION from '../../../graphql/Mutations/deleteTodo.gql'
import GET_TODO_QUERY from '../../../graphql/Query/Todos.gql'
import {deleteTodoFromSeminar} from "../../../cache/cacheUpdater";
import EditTodo from "./EditTodoButton";
import SeminarTodoDeadline from "./SeminarToDoDeadline";

export default {
  name: "SeminarToDo",
  components: {SeminarTodoDeadline, EditTodo},
  props: ['todo', 'seminar', 'isDash'],
  data() {
    return {
      isLoading: false,
      modalActive: false
    }
  },
  methods: {
    deleteTodo() {
      this.$buefy.dialog.confirm({
        title: 'Todo löschen',
        type: 'is-danger',
        confirmText: 'Todo löschen',
        message: `Todo wirklich löschen? Das Todo kann auch abgehakt werden.`,
        onConfirm: () => {
          this.isLoading = true
          this.$apollo.mutate({
            mutation: DELETE_TODO_MUTATION,
            refetchQueries: [{query: GET_TODO_QUERY}],
            variables: {
              id: this.todo.id
            },
            update: (store) => deleteTodoFromSeminar(store, this.seminar.id, this.todo.id)
          }).then(() => {

          }).catch((e) => {
            this.isLoading = false
            this.$buefy.toast.open({
              message: 'Ein Fehler ist aufgetreten!',
              type: 'is-danger'
            })
          })
        }
      })
    },
    toggleCheck() {
      this.isLoading = true;
      this.$apollo.mutate({
        mutation: UPDATE_TODO_MUTATION,
        refetchQueries: [{query: GET_TODO_QUERY}],
        variables: {
          id: this.todo.id,
          todo: {
            done: !this.todo.done
          }
        }
      }).then(() => {
        this.isLoading = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
.checked {
  span .text {
    text-decoration: line-through;
    color: grey;
  }
}

.deleteIcon {
  color: #b9b9b9;
}

.deleteIcon:hover {
  color: #898989;
}

</style>
