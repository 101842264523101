<template>
  <div>
    <div @click="modalActive = true" class="field m-t-sm has-text-grey">
      <label class="b-checkbox checkbox">
        <b-icon icon="plus" style="width:1.25em; height: 1.25em;"/>
        <span class="control-label">Todo Hinzufügen</span>
      </label>
    </div>
    <b-modal :active.sync="modalActive" :has-modal-card="true">
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Todo hinzufügen</div>
        </div>
        <div class="modal-card-body">

          <b-field label="Titel">
            <b-input placeholder="Aufgabe" type="test" v-model="newTodo.name"></b-input>
          </b-field>
          <b-field label="Deadline">
            <div class="block">
              <b-radio :native-value="null"
                       v-model="deadlineType">
                keine Deadline
              </b-radio>
              <b-radio native-value="beginOffsetDays"
                       v-model="deadlineType">
                vor/nach Beginn
              </b-radio>
              <b-radio native-value="endOffsetDays"
                       v-model="deadlineType">
                vor/nach Ende
              </b-radio>
              <b-radio native-value="date"
                       v-model="deadlineType">
                festes Datum
              </b-radio>
            </div>
          </b-field>
          <b-field label="Datum" v-if="deadlineType === 'date'">
            <date-picker v-model="newTodo.date"></date-picker>
          </b-field>
          <b-field label="Tage vor/nach Beginn" message="Negative Werte (-) für davor, positive für danach."
                   v-if="deadlineType === 'beginOffsetDays'">
            <b-input placeholder="Anzahl der Tage vor/nach Seminar" type="number"
                     v-model="newTodo.beginOffsetDays" v-parse-int></b-input>
          </b-field>
          <b-field label="Tage vor/nach Ende" message="Negative Werte (-) für davor, positive für danach."
                   v-if="deadlineType === 'endOffsetDays'">
            <b-input placeholder="Anzahl der Tage vor/nach Seminar" type="number"
                     v-model="newTodo.endOffsetDays" v-parse-int></b-input>
          </b-field>
        </div>
        <div class="modal-card-foot">
          <button :class="{'is-loading' : isLoading}"
                  @click="addTodo"
                  class="button is-success"
          >
            Hinzufügen
          </button>
          <button @click="modalActive=false" class="button is-danger">Abbrechen</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DatetimePicker from "../DateTimePicker";
import GET_TODO_QUERY from '../../../graphql/Query/Todos.gql'
import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'
import DatePicker from "../DatePicker";

export default {
  name: "AddTodo",
  components: {DatePicker, DatetimePicker},
  props: ['seminar'],
  data() {
    return {
      modalActive: false,
      isLoading: false,
      deadlineType: null,
      newTodo: {}
    }
  },
  computed: {
    newTodoComputed() {
      let todo = {
        name: this.newTodo.name
      };
      if (this.deadlineType !== null) {
        todo[this.deadlineType] = this.newTodo[this.deadlineType];
      }
      return todo;
    }
  },
  methods: {
    addTodo() {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_SEMINAR_MUTATION,
        refetchQueries: [{query: GET_TODO_QUERY}],
        variables: {
          id: this.seminar.id,
          addTodo: this.newTodo,
          includeTodos: true
        }
      }).then(() => {
        this.isLoading = false
        this.modalActive = false
        this.deadlineType = null
        this.newTodo = {}
      })
    }
  }
}
</script>

<style scoped>

</style>
