import _ from 'lodash'
import Vue from 'vue'
import {include, omit} from "@/utils/utils";

export default {
  state: {
    defaults: {},
    values: {},
    page: 1,
    sort: {
      field: null,
      order: 'ASC'
    }
  },
  mutations: {
    setFilter(state, {filter, value}) {
      Vue.set(state.values, filter, value)
      state.page = 1
    },
    setFilters(state, filters) {
      state.values = _.cloneDeep({...state.defaults, ...filters})
      state.page = 1
    },
    resetFilter(state) {
      state.values = _.cloneDeep(state.defaults)
      state.page = 1
    },
    setPage(state, page) {
      state.page = page
    },
    setSort(state, {field, order}) {
      state.page = 1
      state.sort = {
        field,
        order: order.toUpperCase()
      }
    },
  },
  getters: {
    isActive: (state) => !_.isEqual(state.defaults, state.values),
    isActiveWithout: (state) => (withoutKeys = []) => {
      const c = omit(withoutKeys, state.values);
      const d = omit(withoutKeys, state.defaults);
      return !_.isEqual(c, d)
    },
    isActiveWith: (state) => (withKeys) => {
      const c = include(withKeys, state.values);
      const d = include(withKeys, state.defaults);
      return !_.isEqual(c, d)
    }
  }
}