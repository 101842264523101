<template>
    <div v-if="!isLoading">
        <SeminarMail
                :seminar="Seminar"
                :to-ids="toIds"
        ></SeminarMail>
    </div>
</template>

<script>
import GET_SEMINAR_QUERY from '../../../graphql/Query/Seminar.gql'
import SeminarMail from "./SeminarMail";

export default {
  name: 'mail-to-seminar',
  props: ['id', 'toIds'],
  components: {SeminarMail},
  data() {
    return {
      Seminar: {},
      isLoading: 0,
    }
  },
  apollo: {
    Seminar: {
      query: GET_SEMINAR_QUERY,
      variables() {
        return {
          id: this.id,
        }
      }
    }
  },
}
</script>

<style scoped>

</style>