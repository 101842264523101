export const PARTICIPATION_STATUS = {
  PARTICIPATING: {
    name: 'Teilnahme',
    value: 'participating',
    icon: 'account',
    class: 'is-success'
  },
  WAITING: {
    name: 'Warteliste',
    value: 'waitinglist',
    icon: 'timer-sand',
    class: 'is-warning'
  },
  /*COMPLETED: {
    name: 'Abgeschlossen',
    value: 'completed',
    icon: 'check',
    class: 'is-info'
  },*/
  CANCELED: {
    name: 'Abgesagt',
    value: 'canceled',
    icon: 'account-off',
    class: 'is-danger'
  }
}
// order is important!!!
export const SEMINAR_STATUS = [
  {
    key: 'CANCELED',
    name: 'Abgesagt',
    class: 'is-grey-light',
    message: ''
  },
  {
    key: 'PLANNING',
    name: 'In Planung',
    class: 'is-warning-light',
    message: 'Das Seminar wird noch nicht auf ehrenamtsakademie.de angezeigt.'
  },
  {
    key: 'PUBLIC',
    name: 'Veröffentlicht',
    class: 'is-success-light',
    message: 'Das Seminar ist auf ehrenamtsakademie.de sichtbar.'
  },
  {
    key: 'INVITED',
    name: 'Eingeladen',
    class: 'is-danger-light',
    message: 'Alle Teilnehmer wurden per E-Mail/Brief eingeladen'
  },
  {
    key: 'COMPLETED',
    name: 'Durchgeführt',
    class: 'is-info-light',
    message: 'Unterschriftenliste erhalten und nicht erschienene Teilnahmen bearbeitet.<br/>' +
      'E-Mail Fragebogen versendet.'
  },
  {
    key: 'CLEARED',
    name: 'Abgerechnet',
    class: '',
    message: 'Seminarleiter Honorar, Reisekosten, Raum- Verpflegung abgerechnet'
  }
]

export const LECTURESHIP_LEVELS = {
  LECTURER: {
    name: 'SeminarleiterIn'
  },
  TRAINEE: {
    name: 'HospitantIn'
  },
  MENTOR: {
    name: 'MentorIn'
  }
}


export const MemberDataStatus = {
  UNVERIFIED: {
    text: 'Unverifiziert',
    textLong: 'Daten verifizieren',
    icon: 'eye-off',
    type: 'is-danger is-light'
  },
  VERIFIED: {
    text: 'Verifiziert',
    textLong: 'Daten vertifizert',
    icon: 'eye-check',
    type: 'is-success'
  },
  /*INVALID: {
    text: 'Ungültig',
    textLong: 'Daten ungültig',
    type: 'is-danger'
  },
  OUTDATED: {
    text: 'Veraltet',
    textLong: 'Daten veraltet',
    type: 'is-warning'
  }*/
}