<template>
    <b-field>
        <b-input type="date"
                 :size="isSmall ? 'is-small' : ''"
                 v-model="date"
                 @input="sendIfNotEmpty()"/>
        <b-input type="time"
                 :size="isSmall ? 'is-small' : ''"
                 v-model="time"
                 @input="sendIfNotEmpty()"/>
    </b-field>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'datetime-picker',
  props: ['value', 'isSmall'],
  data() {
    return {
      date: moment(this.value).isValid() && this.value !== undefined ? moment(this.value).format('YYYY-MM-DD') : '',
      time: moment(this.value).isValid() && this.value !== undefined ? moment(this.value).format('HH:mm') : '',
    }
  },
  methods: {
    sendIfNotEmpty() {
      if (this.datetime !== '') {
        this.$emit('input', this.datetime)
      }
    }
  },
  computed: {
    datetime() {
      if (this.date !== '' && this.time !== '' && moment(this.date).isValid()) {
        return this.date + 'T' + this.time
      }
      return ''
    }
  },
  watch: {
    value(datetimeprop) {
      if (_.isString(datetimeprop) && datetimeprop !== this.datetime) {
        this.date = moment(datetimeprop).format('YYYY-MM-DD')
        this.time = moment(datetimeprop).format('HH:mm')
      }
      if (!_.isString(datetimeprop) || datetimeprop === '') {
        this.date = ''
        this.time = ''
      }
    }
  }
}
</script>

<style scoped>

</style>