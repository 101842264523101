<template>
  <div class="column">
    <div class="panel">
      <div class="panel-heading">
        {{ DefaultTodo.name }}
      </div>
      <div class="panel-block">
        <span v-if="DefaultTodo.description" style="white-space: pre-wrap;">{{ DefaultTodo.description }}</span>
        <template v-else>
          Keine Beschreibung
        </template>
      </div>
      <div class="panel-block" style="display: block;">
        <p v-if="!isEditing" class="">
          <template v-if="DefaultTodo.beginOffsetDays">
            {{ DefaultTodo.beginOffsetDays > 0 ? DefaultTodo.beginOffsetDays : -DefaultTodo.beginOffsetDays }} Tage
            {{ DefaultTodo.beginOffsetDays > 0 ? 'nach' : 'vor' }} Seminarbeginn
          </template>
          <template v-else-if="DefaultTodo.endOffsetDays">
            {{ DefaultTodo.endOffsetDays > 0 ? DefaultTodo.endOffsetDays : -DefaultTodo.endOffsetDays }} Tage
            {{ DefaultTodo.endOffsetDays > 0 ? 'nach' : 'vor' }} Seminarende
          </template>
          <template v-else>Keine automatische Deadline</template>
        </p>
        <div class="m-b-lg" v-else>
          <b-field label="Titel">
            <b-input placeholder="Aufgabe" type="text" v-model="editTodo.name"></b-input>
          </b-field>
          <b-field expanded label="Beschreibung">
            <b-input placeholder="Kurze Beschreibung der Aufgabe" type="textarea"
                     v-model="editTodo.description" expanded></b-input>
          </b-field>
          <b-field label="Deadline"></b-field>
          <b-field>
            <b-radio :native-value="null"
                     v-model="deadlineType">
              keine Deadline
            </b-radio>
          </b-field>
          <b-field>
            <b-radio native-value="beginOffsetDays"
                     v-model="deadlineType">
              vor/nach Beginn
            </b-radio>
          </b-field>
          <b-field>
            <b-radio native-value="endOffsetDays"
                     v-model="deadlineType">
              vor/nach Ende
            </b-radio>
          </b-field>
          <b-field label="Tage vor/nach Beginn" message="Negative Werte (-) für davor, positive für danach."
                   v-if="deadlineType === 'beginOffsetDays'">
            <b-input placeholder="Anzahl der Tage vor/nach Seminar" type="number"
                     v-model="editTodo.beginOffsetDays" v-parse-int></b-input>
          </b-field>
          <b-field label="Tage vor/nach Ende" message="Negative Werte (-) für davor, positive für danach."
                   v-if="deadlineType === 'endOffsetDays'">
            <b-input placeholder="Anzahl der Tage vor/nach Seminar" type="number"
                     v-model="editTodo.endOffsetDays" v-parse-int></b-input>
          </b-field>
        </div>
      </div>
      <div class="panel-block">
        <b-collapse :open.sync="templatesOpen"
                    class="is-fullwidth">
          <div slot="trigger" slot-scope="props">
            Templates ({{DefaultTodo.templates.length}})
            <a>
              <b-icon
                :icon="props.open ? 'menu-up' : 'menu-down'">
              </b-icon>
            </a>
          </div>
          <div class="m-t-md">
            <table class="table  is-narrow" style="margin: 0 -0.5em;">
              <tbody>
              <tr>
                <td>
                </td>
                <td>
                  <b-checkbox :value="this.editTemplateIds.length === this.filteredTemplates.length"
                              @change.native="selectAll"
                              v-if="isEditing"/>
                </td>
              </tr>
              <tr v-for="item in filteredTemplates">
                <td>{{item.name}}</td>
                <td>
                  <template v-if="!isEditing">
                    <b-icon icon="check" size="is-small" type="is-success"
                            v-if="DefaultTodo.templates.map(t => t.id).indexOf(item.id) !== -1"/>
                    <b-icon icon="close" size="is-small" type="is-danger" v-else/>
                  </template>
                  <b-checkbox :native-value="item.id"
                              v-else
                              v-model="editTemplateIds">
                  </b-checkbox>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </b-collapse>
      </div>
      <div class="panel-block">
        <template v-if="!isEditing">
          <button @click="edit" class="button m-r-sm">
            Bearbeiten
          </button>
          <button :class="{'is-loading': isDeleting}" @click="remove" class="button">
            <b-icon icon="delete" type="is-danger"/>
          </button>
        </template>
        <template v-else>
          <button :class="{'is-loading': isSaving}" @click="save" class="button is-success m-r-sm">
            Speichern
          </button>
          <button @click="isEditing = false; templatesOpen= false;" class="button is-danger">
            Abbrechen
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateSelect from "./TemplateSelect";
import GET_TEMPLATE_QUERY from "../../graphql/Query/Templates.gql";
import UPDATE_DEFAULT_TODO_MUTATION from "../../graphql/Mutations/updateDefaultTodo.gql"
import DELETE_DEFAULT_TODO_MUTATION from "../../graphql/Mutations/deleteDefaultTodo.gql";
import GET_DEFAULT_TODOS_QUERY from "../../graphql/Query/DefaultTodos.gql";

export default {
  name: 'DefaultTodo',
  props: ['DefaultTodo'],
  data() {
    return {
      Templates: [],
      templatesOpen: false,
      isEditing: false,
      isSaving: false,
      isDeleting: false,
      editTemplateIds: [],
      deadlineType: null,
      editTodo: {}
    }
  },
  methods: {
    selectAll() {
      if (this.editTemplateIds.length !== this.filteredTemplates.length) {
        this.editTemplateIds = this.filteredTemplates.map(t => t.id)
      } else {
        this.editTemplateIds = []
      }
    },
    remove() {
      this.$buefy.dialog.confirm({
        title: 'Todo entfernen',
        message: 'Sind Sie sicher, dass Sie das Todo entfernen möchten? <br/>' +
          '<b>Es wird ebenfalls bei bestehenden Seminaren entfernt, sofern es noch nicht abgeschlossen wurde.</b>.',
        confirmText: 'Ja, entfernen!',
        type: 'is-danger',
        onConfirm: () => {
          this.isDeleting = true
          this.$apollo.mutate({
            mutation: DELETE_DEFAULT_TODO_MUTATION,
            variables: {
              id: this.DefaultTodo.id,
              deleteExisting: true
            },
            refetchQueries: [{query: GET_DEFAULT_TODOS_QUERY}]
          }).then(() => {

          })
        }
      })
    },
    edit() {
      this.isEditing = true
      this.editTemplateIds = this.DefaultTodo.templates.map(t => t.id)
      this.templatesOpen = true

      if (this.DefaultTodo.endOffsetDays !== null) {
        this.deadlineType = "endOffsetDays"
      }
      if (this.DefaultTodo.beginOffsetDays !== null) {
        this.deadlineType = "beginOffsetDays"
      }
      this.editTodo = {
        ...this.DefaultTodo
      }
    },
    save() {
      this.$buefy.dialog.confirm({
        title: 'Todo speichern',
        message: '<div class="content">Sind Sie sicher, dass Sie das voreingestellte Todo speichern möchten? <br/>' +
          '<br/>Folgende Aktionen werden durchgeführt:<br/>' +
          '<ul><li>Unabgeschlossene Todos werden bei bestehenden Seminaren dessen Seminartyp nicht mehr enthalten ist entfernt.</li>' +
          '<li>Unabgeschlossene Todos werden bei bestehenden Seminaren aktualisert (Titel, Deadline)</li>' +
          '<li>Todo wird zu bestehenden Seminaren hinzugefügt deren Status <i>In Planung, Veröffentlicht</i> oder <i>Einladungen Verschickt</i> ist.</li></ul></div>',
        confirmText: 'Ja, speichern!',
        type: 'is-danger',
        onConfirm: () => {
          this.isSaving = true
          this.$apollo.mutate({
            mutation: UPDATE_DEFAULT_TODO_MUTATION,
            variables: {
              id: this.DefaultTodo.id,
              defaultTodo: this.editTodoComputed,
              templateIds: this.editTemplateIds,
              updateExisting: true
            }
          }).then(() => {
            this.isSaving = false
            this.templatesOpen = false
            this.isEditing = false
          })
        }
      })

    }
  },
  computed: {
    filteredTemplates() {
      return this.Templates.filter(t => t.state).sort(function (a, b) {
        return a.name.localeCompare(b.name);
      })
    },
    editTodoComputed() {
      if (!this.isEditing) return
      let todo = {
        name: this.editTodo.name,
        description: this.editTodo.description,
        beginOffsetDays: null,
        endOffsetDays: null
      };
      if (this.deadlineType !== null) {
        todo[this.deadlineType] = this.editTodo[this.deadlineType];
      }
      return todo;
    }
  },
  apollo: {
    Templates: {
      query: GET_TEMPLATE_QUERY,
      variables: {
        selectField: true
      }
    }
  },
  components: {TemplateSelect}
}
</script>

<style scoped>
table {
  background: 0 !important;
}
</style>
