<template>
  <b-select
      v-if="!isLoading && !multiple"
      :value="value"
      placeholder="Bezirk auswählen"
      @input="$emit('input', $event)">
    <option v-for="District in Districts" :value="District.id">{{ District.name }}</option>
  </b-select>
  <b-dropdown v-else-if="multiple" class="is-block-mobile">
    <div slot="trigger"
         class="button is-flex-mobile">
      <span>Bezirk</span>
      <b-icon icon="menu-down"></b-icon>
    </div>
    <b-dropdown-item custom>
      <button class="button" style="width:100%;" @click="toggleAllDistricts">{{
          selected.length
          !== Districts.length ? "Alle auswählen" : "Keinen auswählen"
        }}
      </button>
    </b-dropdown-item>
    <hr class="dropdown-divider"/>
    <b-dropdown-item v-for="District in Districts" :key="District.id" custom>
      <b-checkbox v-model="selected" :native-value="District.id">{{ District.name }}
      </b-checkbox>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import GET_DISTRICTS_QUERY from '../../graphql/Query/Districts.gql'
import {filterObjects} from '../../utils/utils'

export default {
  name: 'district-select',
  data() {
    return {
      Districts: [],
      isLoading: 0,
    }
  },
  methods: {
    toggleAllDistricts() {
      if (this.selected.length !== this.Districts.length) {
        this.selected = this.Districts.map(d => d.id)
      } else {
        this.selected = []
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  apollo: {
    Districts: {
      query: GET_DISTRICTS_QUERY,
      loadingKey: 'isLoading'
    }
  },
  props: {
    value: [String, Array],
    multiple: Boolean
  }
}
</script>

<style scoped>

</style>