<template>
  <b-select :value="sanitizedValue" placeholder="Keine" @input="$emit('input', $event)">
    <option :value="null">Keine</option>
    <option value="Herr">Herr</option>
    <option value="Frau">Frau</option>
  </b-select>
</template>

<script>

export default {
  name: "GenderSelect",
  props: ['value'],
  computed: {
    sanitizedValue() {
      if (this.value) {
        if (this.value.match(/herr/gi)) return "Herr"
        if (this.value.match(/frau/gi)) return "Frau"
      }
      return null
    }
  },
  watch: {
    value() {
      if (this.sanitizedValue !== this.value) this.$emit('input', this.sanitizedValue)
    }
  }
}
</script>

<style scoped>

</style>