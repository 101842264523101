<template>
  <div style="display:contents;">
    <div v-if="member.dataStatus === 'UNVERIFIED'" :class="[size, status.type]" class="tag m-r-sm">
      <a style="color:inherit" @click="$emit('click', member.dataStatus)">
        <span>{{ status.textLong }}</span>
      </a>
      <b-icon :icon="status.icon" size="is-small"/>
    </div>
    <div v-if="member.hasDuplicateEmail" :class="[size]" class="tag m-r-sm is-warning">
      <a style="color:inherit">
        <span>Doppelte E-Mail</span>
      </a>
    </div>
  </div>
</template>

<script>
import {MemberDataStatus} from "@/constants";

export default {
  name: "MemberDataStatus",
  computed: {
    status() {
      return MemberDataStatus[this.member.dataStatus] || {}
    }
  },
  props: {
    size: {
      type: String,
      default: () => 'is-medium'
    },
    member: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style scoped>

</style>