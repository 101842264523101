<template>
  <editable
      v-if="$store.getters.hasPermission('manage own seminars') || $store.getters.hasPermission('manage all seminars')"
      v-slot="{loading, editData, mutate, errors}"
      :data="{template_id: null, district_id: null, quota:null}"
      :mutation="require('../../../graphql/Mutations/createSeminar.gql')"
      class="is-block-mobile"
      input-arg="seminar"
      @mutated="data => $router.push({name: 'Seminar', params: {id: data.createSeminar.id}})">
    <a class="button is-flex-mobile" @click="modalActive = true; newSeminar = {}">
      <span>Seminar anlegen</span>
    </a>
    <b-modal :active.sync="modalActive" :has-modal-card="true">
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Seminar anlegen</div>
        </div>
        <div class="modal-card-body">
          <b-field :type="errors['seminar.template_id'] ? 'is-danger' : ''" label="Template">
            <template-select v-model="editData.template_id" :can-create="true"
                             :hideInactive="true"></template-select>
          </b-field>
          <b-field v-if="$store.getters.hasPermission('manage all seminars') && $config.SHOW_DISTRICTS"
                   :type="errors['seminar.district_id'] ? 'is-danger' : ''"
                   label="Bezirk">
            <district-select v-model="editData.district_id"></district-select>
          </b-field>
          <b-field :type="errors['seminar.quota'] ? 'is-danger' : ''" label="Teilnehmergrenze">
            <b-input v-model="editData.quota" v-parse-int icon="chart-pie" min="0" placeholder="z.B. 20"
                     type="number"></b-input>
          </b-field>
        </div>
        <div class="modal-card-foot">
          <button :class="{'is-loading' : loading}"
                  class="button is-success"
                  @click="mutate">
            Anlegen
          </button>
          <button class="button is-danger" @click="modalActive=false">Abbrechen</button>
        </div>
      </div>
    </b-modal>

  </editable>
</template>

<script>
import CREATE_SEMINAR_MUTATION from '../../../graphql/Mutations/createSeminar.gql'

import TemplateSelect from "../../Elements/TemplateSelect";
import DistrictSelect from "../../Elements/DistrictSelect";
import Editable from "../Editable";

export default {
  name: "AddSeminarButton",
  components: {Editable, DistrictSelect, TemplateSelect},
  data() {
    return {
      modalActive: false,
    }
  }
}
</script>

<style>
.deepOverflowVisible, .deepOverflowVisible div {
  /*overflow: visible;*/
}
</style>
