<template>
  <div>
    <headline :right-expanded="true"
              class="is-borderless is-medium">
      Todos
      <template slot="right">
        <div class="field is-grouped">
          <div class="field is-expanded is-marginless align-items-center is-flex">
            <clearable-input :value="$store.state.todoFilter.values.textSearch"
                             placeholder="Aufgabe, Seminarname oder -nummer, Bezirk oder Tags eingeben"
                             style="width: 100%;"
                             @input="value => $store.commit('todoFilter/setFilters', {textSearch:value})"/>
          </div>

          <div class="field is-marginless align-items-center is-flex">
            <div v-if="$config.SHOW_DISTRICTS" class="control">
              <b-dropdown :close-on-click="false" position="is-bottom-left">
                <template v-slot:trigger>
                  <a class="m-l-sm">
                    <b-icon class="" icon="cog">asd</b-icon>
                  </a>
                </template>
                <b-dropdown-item custom><label class="label">Todo Anzeige-einstellungen</label></b-dropdown-item>
                <apollo-query v-slot="{ result: { loading, error, data }, isLoading }"
                              :query="require('../../graphql/Query/Districts.gql')">
                  <template v-if="!isLoading && data">
                    <b-dropdown-item v-for="item in data.Districts" :key="item.id" custom>
                      <apollo-mutation :mutation="require('../../graphql/Mutations/updateProfile.gql')"
                                       :variables="{ profile: { settings: {
                                         todoFilter: {districtIn: districtIn.indexOf(item.id) !== -1
                                         ? districtIn.filter(a => a !== item.id)
                                         : [item.id, ...districtIn] }}}}">
                        <template v-slot="{ mutate, loading, error }">
                          <b-switch :value="districtIn.indexOf(item.id) !== -1"
                                    @input="mutate">{{ item.name }}
                          </b-switch>
                        </template>
                      </apollo-mutation>
                    </b-dropdown-item>
                  </template>
                </apollo-query>
              </b-dropdown>
            </div>
          </div>
        </div>
      </template>
    </headline>
    <b-table
        :currentPage="Todos.paginatorInfo.currentPage || 0"
        :data="Todos.data"
        :default-sort="[sort.field, sort.order]"
        :loading="!!isLoading"
        :mobile-cards="false"
        :per-page="perPage"
        :total="Todos.paginatorInfo.total || 0"
        backend-filtering
        :show-header="true"
        backend-pagination
        backend-sorting
        class="table-has-frame"
        narrowed
        paginated
        @sort="(field,order) => {$store.commit('todoFilter/setSort',{field,order})}"
        @page-change="$store.commit('todoFilter/setPage', $event)"
    >
      <b-table-column field="seminarId"
                      label="Nr."
                      sortable
                      width="6em">
        <template v-slot="props">
          <router-link :to="{ name: 'Seminar', params: { id: props.row.seminar.id }}" class="has-text-black">
            {{ props.row.seminar.id }}
          </router-link>
        </template>
      </b-table-column>
      <b-table-column field="name"
                      label="Zu erledignde Aufgabe"
                      sortable>
        <clearable-input slot="searchable"
                         :value="filters.name"
                         placeholder="Filtern"
                         @input="setFilter('name', $event)"/>
        <template v-slot="props">
          <router-link :to="{ name: 'Seminar', params: { id: props.row.seminar.id }}" class="">
            {{ props.row.name }}
          </router-link>
        </template>
      </b-table-column>
      <b-table-column cell-class="crazyRow"
                      field="templateName"
                      label="Seminar"
                      sortable
                      width="11em">
        <template v-slot="props">
          <small>
            <router-link :title="props.row.seminar.template.name"
                         :to="{ name: 'Seminar', params: { id: props.row.seminar.id }}"
                         class="has-text-black">
              {{ props.row.seminar.template.name }}
            </router-link>
          </small>
        </template>
      </b-table-column>
      <b-table-column v-if="$config.SHOW_DISTRICTS" field="districtName" label="Bezirk"
                      sortable
                      width="8em">
        <template v-slot="props">
          {{ props.row.seminar.district.name }}
        </template>
      </b-table-column>
      <b-table-column v-slot="props"
                      field="deadline"
                      label="Deadline"
                      numeric
                      sortable
                      width="6em">
        <seminar-todo-deadline :todo="props.row"/>
      </b-table-column>
      <template slot="empty">
        <div style="text-align: center; font-size: 1.5em; margin:1em; min-height:150px;">
          <template v-if="!isLoading ">
            <template v-if="!$store.getters.todoFilterActive">
              <b-icon icon="check-circle"
                      size="is-large"
                      type="is-success"/>
              <br/>
              Keine ausstehenden Todos
            </template>
            <template>
              <br/>
              Keine Todos gefunden
            </template>
          </template>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import GET_TODOS_QUERY from '../../graphql/Query/Todos.gql'
import SeminarToDo from "./Seminar/SeminarToDo";
import SeminarTodoDeadline from "./Seminar/SeminarToDoDeadline";
import Template from "../Pages/Template";
import EditTodo from "./Seminar/EditTodoButton";
import ClearableInput from "./ClearableInput";
import Headline from "../Layout/Headline";
import DistrictSelect from "@/components/Elements/DistrictSelect";

export default {
  name: "DaschboardTodos",
  components: {DistrictSelect, Headline, ClearableInput, EditTodo, Template, SeminarTodoDeadline, SeminarToDo},
  data() {
    return {
      Todos: {
        data: [],
        paginatorInfo: {}
      },
      perPage: 25,
      isLoading: 0
    }
  },
  computed: {
    districtIn() {
      return this.$store.getters['todoFilter/todoFilterDistricts']
    },
    filters() {
      return this.$store.getters['todoFilter/transformed']
    },
    sort() {
      return this.$store.state.todoFilter.sort
    },
    page() {
      return this.$store.state.todoFilter.page
    }
  },
  apollo: {
    Todos: {
      query: GET_TODOS_QUERY,
      loadingKey: 'isLoading',
      pollInterval: 60000,
      deep: true,
      variables() {
        return {
          sort: this.sort,
          filter: this.filters,
          page: this.page
        }
      }
    }
  }
}
</script>

<style lang="scss">
.crazyRow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12em;
}

.align-items-center {
  align-items: center;
}

</style>
