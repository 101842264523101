import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import ui from './modules/ui'
import filterBase from './modules/filter'
import {SHOW_DISTRICTS} from "@/config";
import _ from 'lodash'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: () => {
  },
  modules: {auth, ui},
  mutations: {
    initFilter(state, {name = "default", ...filter}) {
      if (store.hasModule(`${name}Filter`)) return
      store.registerModule(`${name}Filter`, {
        ..._.merge(filterBase, filter),
        state: () => ({...filterBase.state, ...filter.state}),
        namespaced: true // making our module reusable
      })
      store.commit(`${name}Filter/resetFilter`)
    },
  },
})

store.commit('initFilter', {
  name: 'member',
  state: {
    defaults: {
      districtIn: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      isMemberOfDepartments: [],
      isMemberOfAnyDepartment: null,
      hasAssociationFunctions: [],
      hasFunctionInDepartment: undefined,
      hasParticipations: [],
      hasParticipationAfter: null,
      hasParticipationBefore: null,
      doesntHaveParticipations: [],
      needsTemplates: [],
      needsTemplatesIsRequired: null,
      textSearch: '',
      firstName: '',
      lastName: '',
      mitgliedernummer: '',
      street: '',
      city: '',
      zip: '',
      zipBelow: '',
      zipAbove: '',
      registeredBefore: null,
      registeredAfter: null,
      email: '',
      phone: '',
      isVdk: null,
      agreedToPrivacy: null,
      hasAnyFunction: null,
      hasCurrentParticipations: null,
      tags: [],
      wantsNewsletter: null
    }
  },
  getters: {
    transformed: ({values: inputFilters}) => {
      let filters = {}
      if (inputFilters.textSearch.length > 0) {
        filters.textSearch = inputFilters.textSearch
      }
      if (inputFilters.agreedToPrivacy !== null) {
        filters.agreedToPrivacy = inputFilters.agreedToPrivacy
      }
      if (inputFilters.hasAnyFunction !== null) {
        filters.hasAnyFunction = inputFilters.hasAnyFunction
      }
      if (inputFilters.hasAssociationFunctions.length > 0) {
        filters.hasFunctions = inputFilters.hasAssociationFunctions.map(x => x.id)
      }
      if (inputFilters.isMemberOfDepartments.length !== 0) {
        filters.isMemberOfDepartments = inputFilters.isMemberOfDepartments
      }
      if (inputFilters.isMemberOfAnyDepartment !== null) {
        filters.isMemberOfAnyDepartment = inputFilters.isMemberOfAnyDepartment
      }
      if (inputFilters.hasFunctionInDepartment !== undefined) {
        filters.hasFunctionInDepartment = inputFilters.hasFunctionInDepartment;
      }
      if (inputFilters.hasParticipations.length > 0) {
        filters.hasCompleted = inputFilters.hasParticipations.map(x => x.id)
      }
      if (inputFilters.doesntHaveParticipations.length > 0) {
        filters.doesntHaveCompleted = inputFilters.doesntHaveParticipations.map(x => x.id)
      }
      if (inputFilters.needsTemplates.length > 0) {
        filters.needsTemplates = inputFilters.needsTemplates.map(x => x.id)
        if (inputFilters.needsTemplatesIsRequired !== null) {
          filters.needsTemplatesIsRequired = inputFilters.needsTemplatesIsRequired
        }
      }
      if (inputFilters.districtIn.length > 0 && SHOW_DISTRICTS) {
        filters.districtIn = inputFilters.districtIn
      }
      if (inputFilters.firstName !== '') {
        filters.firstName = inputFilters.firstName
      }
      if (inputFilters.lastName !== '') {
        filters.lastName = inputFilters.lastName
      }
      if (inputFilters.mitgliedernummer !== '') {
        filters.mitgliedernummer = inputFilters.mitgliedernummer
      }
      if (inputFilters.street !== '') {
        filters.street = inputFilters.street
      }
      if (inputFilters.city !== '') {
        filters.city = inputFilters.city
      }
      if (inputFilters.zip !== '') {
        filters.zip = inputFilters.zip
      }
      if (inputFilters.zipBelow !== '') {
        filters.zipBelow = inputFilters.zipBelow
      }
      if (inputFilters.zipAbove !== '') {
        filters.zipAbove = inputFilters.zipAbove
      }
      if (inputFilters.registeredBefore) {
        filters.registeredBefore = inputFilters.registeredBefore
      }
      if (inputFilters.registeredAfter) {
        filters.registeredAfter = inputFilters.registeredAfter
      }
      if (inputFilters.hasParticipationAfter) {
        filters.hasParticipationAfter = inputFilters.hasParticipationAfter
      }
      if (inputFilters.hasParticipationBefore) {
        filters.hasParticipationBefore = inputFilters.hasParticipationBefore
      }
      if (inputFilters.email !== '') {
        filters.email = inputFilters.email
      }
      if (inputFilters.phone !== '') {
        filters.phone = inputFilters.phone
      }
      if (inputFilters.isVdk !== null) {
        filters.isVdk = inputFilters.isVdk
      }
      if (inputFilters.hasCurrentParticipations !== null) {
        filters.hasCurrentParticipations = inputFilters.hasCurrentParticipations
      }
      if (inputFilters.tags.length > 0) {
        filters.tags = inputFilters.tags
      }
      if (inputFilters.wantsNewsletter !== null) {
        filters.wantsNewsletter = inputFilters.wantsNewsletter
      }
      return filters
    }
  }
})


store.commit('initFilter', {
  name: 'seminar',
  state: {
    defaults: {
      textSearch: "",
      status: ['PLANNING', 'PUBLIC', 'INVITED', 'COMPLETED'],
      districtIn: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      templateId: null,
      tags: [],
      id: ''
    }
  },
  getters: {
    transformed: ({values: inputFilter}) => {
      let filters = {}
      if (inputFilter.status.length < 6) {
        filters.statusIn = inputFilter.status
      }
      if (inputFilter.templateId > 0) {
        filters.templateIn = [inputFilter.templateId]
      }
      if (inputFilter.districtIn !== null) {
        filters.districtIn = inputFilter.districtIn
      }
      if (inputFilter.before) {
        filters.before = inputFilter.before
      }
      if (inputFilter.after) {
        filters.after = inputFilter.after
      }
      if (inputFilter.id) {
        filters.id = inputFilter.id
      }
      if (inputFilter.placeId) {
        filters.placeId = inputFilter.placeId
      }
      if (inputFilter.tags) {
        filters.tags = inputFilter.tags
      }

      if (inputFilter.textSearch) {
        filters.textSearch = inputFilter.textSearch
      }
      return filters
    }
  }
})

store.commit('initFilter', {
  name: 'todo',
  state: {
    defaults: {
      textSearch: "",
      //districtIn: SHOW_DISTRICTS ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : null,
    },
    sort: {
      field: 'deadline',
      order: 'ASC'
    }
  },
  getters: {
    todoFilterDistricts: (state, getters, rootStore, rootGetters) =>
      rootGetters.userSettings
      && rootGetters.userSettings.todoFilter
      && rootGetters.userSettings.todoFilter.districtIn
        ? rootGetters.userSettings.todoFilter.districtIn
        : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
    transformed: ({values: inputFilter}, getters) => {
      let filters = {}

      if (SHOW_DISTRICTS) {
        filters.districtIn = getters.todoFilterDistricts
      }
      if (inputFilter.textSearch) {
        filters.textSearch = inputFilter.textSearch
      }
      return filters
    }
  },
})


export default store;