<template>
    <b-select
            v-if="!isLoading"
            :value="value"
            placeholder="Rolle auswählen"
            @input="$emit('input', $event)">
        <option v-for="Role in Roles" :value="Role.id">{{Role.name}}</option>
    </b-select>
</template>

<script>
import GET_ROLES_QUERY from '../../graphql/Query/Roles.gql'

export default {
  name: 'role-select',
  data() {
    return {
      Roles: [],
      isLoading: 0
    }
  },
  apollo: {
    Roles: {
      query: GET_ROLES_QUERY,
      loadingKey: 'isLoading'
    }
  },
  props: ['value']
}
</script>

<style scoped>

</style>