<template>
  <base-page :title="$tc('Verbandsstufe', true)">
    <template v-if="$store.getters.hasPermission('manage departments')" slot="headerRight">
      <button class="button" @click="hasInvoiceAddress = false; modalActive = true">{{ $tc('Verbandsstufe') }}
        hinzufügen
      </button>

      <b-modal :active="modalActive" has-modal-card>
        <editable v-slot="{editData, mutate, loading, errors}" :data="{}"
                  :mutation="require('../../graphql/Mutations/createAssociationDepartment.gql')"
                  :refetch-queries="[{query: require('../../graphql/Query/AssociationDepartments.gql')}]"
                  input-arg="input"
                  @mutated="({createAssociationDepartment}) => $router.push({name: 'Department', params: {id: createAssociationDepartment.id}})">
          <div class="modal-card">
            <div class="modal-card-head">
              <div class="modal-card-title">{{ $tc('Verbandsstufe') }} anlegen</div>
            </div>
            <div class="modal-card-body">
              <b-field label="Name">
                <b-input v-model="editData.name" :placeholder="'Name der '+$tc('Verbandsstufe')+' eingeben'"
                         type="text"></b-input>
              </b-field>
            </div>
            <div class="modal-card-foot">
              <button :class="{'is-loading' : loading}"
                      class="button is-success"
                      @click="mutate">
                Hinzufügen
              </button>
              <button class="button is-danger" @click="modalActive=false ;">Abbrechen</button>
            </div>
          </div>
        </editable>
      </b-modal>
    </template>
    <b-field>
      <b-input v-model="searchQuery" placeholder="Suchbegriff eingeben"></b-input>
    </b-field>
    <listable :filter-function="filterFunction" :query="require('../../graphql/Query/AssociationDepartments.gql')"
              icon="home-modern"
              name="AssociationDepartments">
      <!--<b-table-column field="name"
                      label="ID"
                      v-slot="{row}"
                      sortable>
        {{ row.id }}
      </b-table-column>-->
      <b-table-column v-slot="{row}"
                      field="name"
                      label="Name"
                      sortable
                      width="80%">
        <router-link :to="{name:'Department', params: {id: row.id}}"> {{ row.name }}</router-link>
      </b-table-column>
      <b-table-column v-slot="{row}"
                      field="address.extra"
                      label="Zusatz"
                      sortable>
        {{ row.address ? row.address.extra : '' }}
      </b-table-column>
      <b-table-column v-slot="{row}"
                      field="address.city"
                      label="Ort"
                      sortable>
        {{ row.address ? row.address.city : '' }}
      </b-table-column>
      <b-table-column v-slot="{row}"
                      field="address.zip"
                      label="PLZ"
                      sortable>
        {{ row.address ? row.address.zip : '' }}
      </b-table-column>
      <b-table-column v-if="$config.SHOW_ASSOCIATION_LEVELS"
                      v-slot="{row}"
                      field="level.name"
                      label="Name"
                      sortable>
        {{ row.level ? row.level.name : '' }}
      </b-table-column>
      <div v-if="$store.getters.hasPermission('export departments')" slot="footer" class="has-text-right m-t-lg">
        <a :href="$config.API_EXPORT_URL + '/departments'" class="button">{{ $tc('Verbandsstufe', true) }}
          Exportieren</a>
      </div>
    </listable>
  </base-page>
</template>

<script>
import Listable from "../Elements/Listable";
import BasePage from "../Layout/BasePage";
import Editable from "../Elements/Editable";
import Headline from "../Layout/Headline";

export default {
  name: "Departments",
  components: {Headline, Editable, BasePage, Listable},
  data() {
    return {
      searchQuery: '',
      modalActive: false,
      hasInvoiceAddress: false
    }
  },
  methods: {
    filterFunction(data) {
      return data.filter(t => t.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
    }
  }
}
</script>

<style scoped>

</style>