<template>
  <div>
    <template v-if="Seminar !== 0">
      <div class="">
        <div v-for="lectureship in Seminar.lectureships"
             class="card m-b-md"
             style="padding: 1rem;"
             :key="lectureship.id">
          <div class="columns">
            <div class="column">
              <router-link :to="{ name: 'Lecturer', params: { id: lectureship.lecturer.id }}">
                {{lectureship.lecturer.member.fullName}}
                <span v-if="lectureship.level === 'TRAINEE'">(HospitantIn)</span>
                <span v-if="lectureship.level === 'LECTURER'">(SeminarleiterIn)</span>
                <span v-if="lectureship.level === 'MENTOR'">(MentorIn)</span>
              </router-link>
            </div>
            <div class="column is-narrow" v-if="Seminar.canManageAll">
              <a @click="removeLectureship(lectureship.id)">
                <b-icon icon="delete" type="is-danger"></b-icon>
              </a>
            </div>
          </div>
        </div>


        <div class="message is-danger" v-if="Seminar.lectureships.length === 0">
          <div class="message-body">
            Noch keine Seminarleitung
          </div>
        </div>

        <div v-if="Seminar.canManageAll">
          <b-field grouped>
            <b-field expanded>
              <b-autocomplete
                v-model="addLectureshipInput"
                :data="filteredLectureship"
                placeholder="SeminarleiterIn suchen.."
                field="displayName"
                open-on-focus
                @select="option => this.addLectureshipId = option !== null ? option.id : null">
              </b-autocomplete>
            </b-field>
            <b-field>
              <button @click="addLecturer"
                      :class="{'is-loading' : isLoadingAddLecturer}"
                      class="button"
                      :disabled="addLectureshipId === null"
              >
                <b-icon icon="account-plus"></b-icon>
                <span>Hinzufügen</span>
              </button>
            </b-field>
          </b-field>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import GET_SEMINAR_QUERY from '../../../graphql/Query/Seminar.gql'
  import GET_LECTURESHIPS_QUERY from '../../../graphql/Query/Lectureships.gql'
  import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'
  import {filterObjects} from '../../../utils/utils'
  import {LECTURESHIP_LEVELS} from "../../../constants";

  export default {
    name: 'seminar-lecturer-list',
    props: ['id'],
    data() {
      return {
        Seminar: 0,
        Lectureships: [],
        addLectureshipInput: '',
        addLectureshipId: 0,
        isLoadingAddLecturer: false,
        isLoadingRemoveLecturer: false,
        isLoading: 0
      }
    },
    methods: {
      addLecturer() {
        this.isLoadingAddLecturer = true

        this.$apollo.mutate({
          mutation: UPDATE_SEMINAR_MUTATION,
          variables: {
            id: this.id,
            seminar: {lectureships: {connect: [this.addLectureshipId]}},
            includeLectureships: true
          }
        }).then(() => {
          this.isLoadingAddLecturer = false
          this.addLectureshipInput = ''
          this.addLectureshipId = 0

        })
      },
      removeLectureship(id) {
        this.$buefy.dialog.confirm({
          type: 'is-danger',
          message: 'Möchten Sie diese SeminarleiterIn wirklich entfernen?',
          onConfirm: () => {
            this.isLoadingRemoveLecturer = true

            this.$apollo.mutate({
              mutation: UPDATE_SEMINAR_MUTATION,
              variables: {
                id: this.id,
                seminar: {lectureships: {disconnect: [id]}},
                includeLectureships: true
              }
            }).then(() => {
              this.isLoadingRemoveLecturer = false

            })
          }
        })
      }
    },
    computed: {
      filteredLectureship() {
        let mappedLecturers = this.Lectureships.filter(l => l.lecturer.state === true && l.state === true).map((l) => {
          return {
            id: l.id,
            name: l.lecturer.member.fullName,
            displayName: l.lecturer.member.fullName + ' (' + LECTURESHIP_LEVELS[l.level].name + ')'
          }
        })
        return filterObjects(mappedLecturers, 'name', this.addLectureshipInput).sort(function (a, b) {
          return a.name.localeCompare(b.name);
        })
      }
    },
    apollo: {
      Seminar: {
        query: GET_SEMINAR_QUERY,
        variables() {
          return {
            id: this.id,
            includeLectureships: true
          }
        },
        loadingKey: 'isLoading'
      },
      Lectureships: {
        query: GET_LECTURESHIPS_QUERY,
        variables() {
          return {
            selectField: true,
            filter: {
              state: true,
              templateId: this.Seminar.template.id
            }
          }
        },
        skip() {
          return !this.Seminar.template
        }
      }
    }
  }
</script>

<style scoped>
  .simple-table-padding td {
    padding-right: 0.5em
  }


</style>
