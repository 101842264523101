var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default",null,null,{
    filters: _vm.filters,
    isActive: _vm.isActive,
    isActiveWith: _vm.isActiveWith,
    isActiveWithout: _vm.isActiveWithout,
    resetFilter: _vm.resetFilter,
    transformed: _vm.transformed
  })],2)
}
var staticRenderFns = []

export { render, staticRenderFns }