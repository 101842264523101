<template>
    <b-field grouped>
        <template-select v-model="addTemplateId"/>
        <b-input type="date" v-model="created_at" placeholder="Erstellungsdatum"></b-input>
        <button class="button" @click="add" :class="{'is-loading' : isAdding}">Hinzufügen</button>
    </b-field>
</template>

<script>
import UPDATE_MEMBER_MUTATION from '../../graphql/Mutations/updateMember.gql'
import TemplateSelect from "./TemplateSelect";

export default {
  name: "AddCertificate",
  components: {TemplateSelect},
  props: ['member'],
  data() {
    return {
      addTemplateId: null,
      created_at: null,
      isAdding: false
    }
  },
  methods: {
    add() {
      if (this.addTemplateId !== null) {
        this.isAdding = true
        let addCertificate = {
          template_id: this.addTemplateId
        }
        if (!!this.created_at) {
          addCertificate['created_at'] = this.created_at
        }
        this.$apollo.mutate({
          mutation: UPDATE_MEMBER_MUTATION,
          variables: {
            id: this.member.id,
            addCertificate: addCertificate
          }
        }).then(() => {
          this.isAdding = false
          this.addTemplateId = ""
          this.created_at = null
        })
      }
    }
  }
}
</script>

<style scoped>

</style>