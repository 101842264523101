<template>
  <section>
    <div class="" style="margin-bottom: 1rem;">
      <div class="columns is-multiline is-mobile">
        <div class="column is-narrow is-full-mobile">
          <b-dropdown :disabled="checkedIds.length === 0" class="is-block-mobile">
            <div :class="{'is-loading': isLoadingAction}" class="button is-flex-mobile" slot="trigger"
                    v-if="Seminar.canManageParticipations">
              <span>Aktion</span>
              <b-icon icon="menu-down"></b-icon>
            </div>
            <b-dropdown-item @click="$router.push({name: 'MailToSeminar', params:{ toIds: checkedIds}})">
              <b-icon icon="message-text-outline" size="is-small"></b-icon>
              <span>Mail verfassen</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({name: 'LetterToSeminar', params:{ toIds: checkedIds}})">
              <b-icon icon="email-outline" size="is-small"></b-icon>
              <span>Serienbrief verfassen</span>
            </b-dropdown-item>
            <b-dropdown-item @click="setInvited(checkedIds, true)">
              <b-icon icon="email-outline" size="is-small"></b-icon>
              Auf eingeladen setzten
            </b-dropdown-item>
            <b-dropdown-item @click="setInvited(checkedIds, false)">
              <b-icon icon="email-alert" size="is-small"></b-icon>
              Auf nicht eingeladen setzten
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-full-mobile">
          <clearable-input v-model="searchQuery" placeholder="Teilnahme suchen..."/>

        </div>
        <div class="column is-narrow vcenter is-one-third-mobile">
          <b-checkbox :native-value="true"

                      :type="participationStatus.PARTICIPATING.class"
                      v-model="show.participating">
            <!--<b-icon :icon="participationStatus.PARTICIPATING.icon" size="is-small"></b-icon>-->
            <span>{{ participationStatus.PARTICIPATING.name }}</span>
          </b-checkbox>
        </div>
        <div class="column is-narrow vcenter is-one-third-mobile">
          <b-checkbox :native-value="true"
                      :type="participationStatus.WAITING.class"
                      v-model="show.waiting">
            <span>{{ participationStatus.WAITING.name }}</span>
          </b-checkbox>
        </div>
        <div class="column is-narrow vcenter is-one-third-mobile">
          <b-checkbox :native-value="true"
                      :type="participationStatus.CANCELED.class"
                      v-model="show.canceled">
            <span>{{ participationStatus.CANCELED.name }}</span>
          </b-checkbox>
        </div>
      </div>
    </div>
    <b-table :checkable="Seminar.canManageParticipations"
             :checked-rows.sync="checked"
             :data="filteredParticipations"
             :default-sort="['created_at', 'desc']"
             :loading="!!isLoading"
             :opened-detailed="openedDetail"
             class="specialtable participationList"
             detail-key="id"
             detailed
             :mobile-cards="false"
             narrowed
             striped
             :show-detail-icon="false"
             sortable
    >
      <template slot="header" slot-scope="props">
        <b-tooltip :active="!!props.column.meta"
                   :label="props.column.meta !== false ? props.column.meta : ''"
                   dashed>{{ props.column.label }}
        </b-tooltip>
      </template>

      <b-table-column v-slot="props" :custom-sort="statusSort" field="status" label="Status" sortable width="7.5em">
        <participation-status :participation="props.row" :seminar="Seminar"/>
      </b-table-column>

      <b-table-column v-slot="props" cell-class="nowrap" width="7.5em">
        <participation-status-icons :participation="props.row"/>
      </b-table-column>

      <b-table-column v-slot="props" field="member.lastName" label="Name" sortable width="auto"
                      style="white-space:normal;"
      >
        <a @click="addOrRemove(openedDetail, props.row.id)">
          <span :class="{'has-text-danger' : !props.row.member.state, 'strikethrough' : !props.row.member.state}">{{
              props.row.member.lastName + ', ' + props.row.member.firstName
            }}</span>&nbsp;
          <tags-simple :all-types="true" :tags="props.row.member.tags" @click="text => searchQuery = text"
                       @click.stop.native>
            <member-data-status :member="props.row.member" size="is-small"/>
          </tags-simple>
        </a>
      </b-table-column>

      <b-table-column v-slot="props" :label="$tc('MitgliedernummerShort')" :meta="$tc('Mitgliedernummer')"
                      field="member.mitgliedernummer" sortable width="7.5em">
        <a style="color: inherit;" @click="addOrRemove(openedDetail, props.row.id)">
          <mitgliedernummer :mitgliedernummer="props.row.member.mitgliedernummer"/>
        </a>
      </b-table-column>

      <b-table-column v-if="$config.SHOW_DISTRICTS" v-slot="props" field="member.district.name" label="Bezirk" sortable
                      width="7.5em">
        {{ props.row.member.district ? props.row.member.district.name : '' }}
      </b-table-column>
      <b-table-column v-slot="props" field="created_at" label="Eingetragen" sortable width="5em">
        {{ props.row['created_at'] | moment('DD.MM.YY') }}
      </b-table-column>

      <b-table-column v-slot="props" field="comments" label="Kommentare" numeric width="10em">
        <compact-comments :comments="props.row.comments" :expanded="true"
                          :id="props.row.id"
                          :mutation="require('../../../graphql/Mutations/updateParticipation.gql')"
                          title="Kommentare zur Seminarteilnahme"/>
      </b-table-column>

      <template slot="empty">
        <empty-table>Keine Teilnehmenden</empty-table>
      </template>
      <template slot="detail" slot-scope="props">
        <participation-detail :participation="props.row" :seminar="Seminar"></participation-detail>
      </template>
    </b-table>
  </section>
</template>

<script>
import GET_SEMINAR_QUERY from '../../../graphql/Query/Seminar.gql'
import UPDATE_PARTICIPATION_MUTATION from '../../../graphql/Mutations/updateParticipation.gql'
import ParticipationStatus from './ParticipationStatus.vue'
import ParticipationDetail from './ParticipationDetail.vue'
import ParticipationStatusIcons from './ParticipationStatusIcons'
import compactComments from '../compactComments'
import EmptyTable from '../EmptyTable'
import {PARTICIPATION_STATUS} from "@/constants";
import BInput from "buefy/src/components/input/Input";
import BField from "buefy/src/components/field/Field";
import Mitgliedernummer from "../Mitgliedernummer";
import TagsSimple from "../TagsSimple";
import ClearableInput from "../ClearableInput";
import MemberDataStatus from "@/components/Elements/Member/MemberDataStatus";

export default {
  components: {
    MemberDataStatus,
    ClearableInput,
    TagsSimple,
    Mitgliedernummer,
    BField,
    BInput,
    EmptyTable,
    ParticipationStatus,
    ParticipationDetail,
    compactComments,
    ParticipationStatusIcons
  },
  name: 'participation-list',
  data() {
    return {
      Seminar: 0,
      isLoading: 0,
      isLoadingAction: false,
      openedDetail: [],
      isMailModalActive: false,
      checkable: false,
      checked: [],
      show: {
        participating: true,
        waiting: true,
        completed: true,
        canceled: true
      },
      searchQuery: '',
      participationStatus: PARTICIPATION_STATUS
    }
  },
  props: ['id'],
  methods: {
    statusSort(a, b, isAsc) {
      a = a.status
      b = b.status
      if (a === b) return 0
      if (a === 'PARTICIPATING' || (a === 'WAITING' && b === 'CANCELED')) return isAsc ? 1 : -1
      if (a === 'CANCELED' || (a === 'WAITING' && b === 'PARTICIPATING')) return isAsc ? -1 : 1
    },
    addOrRemove(array, value) {
      let index = array.indexOf(value)

      if (index === -1) {
        array.push(value)
      } else {
        array.splice(index, 1)
      }
    },
    setInvited(ids, truefalse) {
      let action = truefalse ? 'eingeladen' : 'nicht eingeladen'
      this.$buefy.dialog.confirm({
        title: 'Auf ' + action + ' Setzen',
        message: 'Möchten sie wirklich diese <b>' + ids.length + '</b> TeilnehmerInnen auf <i>' + action + '</i> setzen?',
        type: truefalse ? 'is-success' : 'is-warning',
        confirmText: 'Ja',
        hasIcon: true,
        onConfirm: () => {
          this.isLoadingAction = true
          let promises = []
          ids.forEach((id) => {
            let invited = this.Seminar.participations.find((p) => p.id === id).inviteDate
            if (!invited && truefalse || invited && !truefalse) {
              promises.push(this.$apollo.mutate({
                mutation: UPDATE_PARTICIPATION_MUTATION,
                variables: {
                  id: id,
                  participation: {
                    inviteDate: truefalse ? new Date() : null
                  }
                }
              }))
            }
          })
          Promise.all(promises).then(() => {
            this.isLoadingAction = false
            this.checked = []
          })
        }
      })
    }
  },
  computed: {
    filteredParticipations() {
      if (!this.isLoading) {
        return this.Seminar.participations.filter((p) => {
          return (p.status === "PARTICIPATING" && this.show.participating) ||
              (p.status === "COMPLETED" && this.show.completed) ||
              (p.status === "WAITING" && this.show.waiting) ||
              (p.status === "CANCELED" && this.show.canceled)
        }).filter(p => p.member.tags.findIndex(t => t.name === this.searchQuery) !== -1 || p.member.fullName.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
      }
    },
    checkedIds() {
      return this.checked.filter((p) => {
        return this.filteredParticipations.indexOf(p) !== -1
      }).map(p => p.id)
    }
  },
  apollo: {
    Seminar: {
      query: GET_SEMINAR_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped>
.vcenter {
  display: flex !important;
  align-items: center;
}


.table td {
  white-space: nowrap;
}

@media (max-width: 770px) {
  .table td {
    width: auto !important;
  }
}

.button {
  margin-right: 0.5em;
}

.button:last-of-type {
  margin-right: 0;
}

</style>
<style>
.participationList {
  margin: 0 -1rem 1rem -1rem;
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.none {
  display: none;
}

.specialtable table {
  background-color: transparent !important;
  /* table-layout: fixed;*/
}

.specialtable > div > table > tbody > tr > td:first-child:not(:last-child), .specialtable > div > table > thead > tr > th:first-child:not(:last-child) {
  padding-left: 1rem !important;
}

.specialtable > div > table > tbody > tr > td:last-child:not(:first-child), .specialtable > div > table > thead > tr > th:last-child:not(:first-child) {
  padding-right: 1rem;
}
</style>
