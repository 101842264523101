<template>
    <div class="">
        <div v-if="!editing" class="content is-marginless notification">
          <!--<h3>{{seminar.template.name}}</h3>-->
          <div class="is-marginless">
            <div v-if="seminar.description === null || seminar.description === ''">
              <i>keine Beschreibung.. .</i>
            </div>
            <div v-else v-html="$options.filters.marked(seminar.description)"></div>
          </div>
        </div>
        <markdown-editor v-if="editing"
                         v-model="editingFields.description">
        </markdown-editor>
        <br/>
        <template v-if="seminar.canManageAll">
            <button @click="edit" v-if="editing === false" class="button">Bearbeiten</button>
            <template v-else>
                <button @click="saveseminar" :class="{'is-loading' : isLoading}"
                        class=" is-success button">
                    Speichern
                </button>
                <button @click="editing = false" class="is-danger button">Abbrechen</button>
            </template>
        </template>
    </div>
</template>

<script>
import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'
import MarkdownEditor from "../MarkdownEditor";

export default {
  name: "seminarDescriptionEditor",
  components: {MarkdownEditor},
  props: ['seminar'],
  data() {
    return {
      editing: false,
      editingFields: {
        description: ''
      },
      isLoading: false
    }
  },
  methods: {
    edit() {
      this.editing = true
      this.editingFields.description = this.seminar.description
    },
    saveseminar() {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_SEMINAR_MUTATION,
        variables: {
          id: this.seminar.id,
          seminar: {
            description: this.editingFields.description
          }
        }
      }).then(() => {
        this.isLoading = false
        this.editing = false

      })
    }
  }
}
</script>

<style scoped>

</style>