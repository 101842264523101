<template>
  <div>
    <template-requirements-list-item class="card m-b-sm"
                                     v-for="requirment in filteredRequirements"
                                     :requirement="requirment"
                                     :template="template"
                                     :key="requirment.id"/>
    <div class="card" v-if="template.requirements.length === 0">
      <div class="card-content has-text-centered">Keine Voraussetzungen</div>
    </div>
    <div class="columns mt-2 is-variable is-2" v-if="$store.getters.hasPermission('manage templates')">
      <div class="column">
        <function-select v-if="isFunction" v-model="addRequirementId" :hide-inactive="true"/>
        <template-select v-else v-model="addRequirementId" :hide-inactive="true"/>
      </div>
      <div class="column is-narrow">
        <button class="button" @click="addRequirement" :class="{'is-loading' : isAdding}">Hinzufügen</button>
      </div>
    </div>
  </div>
</template>

<script>
import Template from "../../Pages/Template";
import TemplateRequirementsListItem from "./TemplateRequirementsListItem";
import TemplateSelect from "../TemplateSelect";
import UPDATE_TEMPLATE_MUTATION from '../../../graphql/Mutations/updateTemplate.gql'
import FunctionSelect from "@/components/Elements/FunctionSelect.vue";

export default {
  name: "TemplateRequirementsList",
  components: {FunctionSelect, TemplateSelect, TemplateRequirementsListItem, Template},
  props: {
    'template': {
      type: Object
    },
    'isFunction': {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      addRequirementId: null,
      isAdding: false
    }
  },
  computed: {
    filteredRequirements() {
      return this.template.requirements.filter(
          requirement => requirement.requires_type === (this.isFunction ? "App\\AssociationFunction" : "App\\Template")
      )
    }
  },
  methods: {
    addRequirement() {
      if (!!this.addRequirementId) {
        this.isAdding = true
        this.$apollo.mutate({
          mutation: UPDATE_TEMPLATE_MUTATION,
          variables: {
            id: this.template.id,
            template: {
              requirements: {
                create: [{
                  requires: {
                    connect: {
                      type: (this.isFunction ? "App\\AssociationFunction" : "App\\Template"),
                      id: this.addRequirementId
                    }
                  }
                }]
              }
            }
          }
        }).then(() => {
          this.isAdding = false
          this.addRequirementId = null
        })
      }
    }
  }
}
</script>

<style scoped>
.card-content {
  padding: .75rem;
}
</style>
