<template>
  <auth-base>
    <div class="box">
      <b-message v-if="error" class="has-text-left" has-icon type="is-danger">
        <b>Oh, nein!</b>
        <br/>
        <template v-if="error.message === 'Login Error'">
          Es wurde kein Account mit dieser Kombination aus E-Mail-Adresse und Passwort gefunden.
        </template>
        <template v-else-if="error.message === 'Too many login attempts'">
          Sie haben zu oft versucht sich mit der angebenen E-Mail-Adresse einzuloggen.
          Bitte versuchen Sie es in einer <b>halben Stunde</b> erneut.
        </template>
        <template v-else-if="error.message === 'Network Error'">
          Ein <b>Netzwerkfehler</b> ist aufgetreten.<br/>
          Sind Sie korrekt mit dem Internet verbunden?
        </template>
        <template v-else>
          {{ error.message }}
          Ein <b>Serverfehler</b> ist aufgetreten.<br/>Probiere es vielleicht später noch einmal.
        </template>
      </b-message>
      <form>
        <div class="field">
          <div class="control">
            <input @keydown.enter="login"
                   autofocus=""
                   class="input is-large"
                   placeholder="Deine E-Mail"
                   type="email"
                   v-model="email">
          </div>
        </div>
        <div class="field">
          <div class="control">
            <input @keydown.enter="login"
                   class="input is-large"
                   placeholder="Dein Passwort"
                   type="password"
                   v-model="password">
          </div>
        </div>
        <a :class="{'is-loading' : isLoading}" class="button is-block is-primary is-large" @click="login">Login</a>
      </form>
    </div>
    <p class="has-text-grey">
      <!--<router-link to="/register">Registrieren</router-link> &nbsp;·&nbsp;-->
      <router-link to="/password/email">Passwort vergessen</router-link><!--&nbsp;·&nbsp;
                               <a href="../">Need Help?</a>-->
    </p>
  </auth-base>
</template>

<script>
import AuthBase from './AuthBase'

export default {
  components: {AuthBase},
  name: 'Login',
  data() {
    return {
      email: null,
      password: '',
      error: '',
      isLoading: false
    }
  },
  methods: {
    login() {
      this.error = ''
      this.isLoading = true
      this.$store.dispatch('login', {
        'email': this.email,
        'password': this.password,
        redirectTo: this.$route.query.from
      }).then(() => {
        this.isLoading = false
      }).catch(error => {
        this.error = error
        this.isLoading = false
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.box {
  margin-top: 0;
}

input {
  font-weight: 300;
}

p {
  font-weight: 700;
}
</style>
