<template>
  <article v-if="clog.type !== null || isGrouped" class="media log">
    <figure class="media-left" style="margin-top: .2rem;">
      <b-icon class="accountIcon" icon="account" v-if="isMemberUser"/>
      <base-avatar :size="30"
                   :user="clog.user ? clog.user : {id: null, fullName: 'Unbekannt'}"
                   v-else></base-avatar>
    </figure>
    <div class="media-content">
      <div v-if="!isGrouped" class="comment">
        <div>
          <span class="username">{{ clog.user ? clog.user.fullName : 'Unbekannt' }}&nbsp;</span>
          <span v-html="clog.message"/>
          <span class="time"
                v-if="clog.created_at">vor {{ clog.created_at | moment('from', 'now', true) }}</span>
        </div>
      </div>
      <div v-else class="comment">
        <div>
          <span class="username">
            {{ clog.user ? clog.user.fullName : 'Unbekannt' }}
          </span><a @click="isOpen = !isOpen">
          hat {{ log.length }} Änderungen vorgenommen
        </a>
          <span class="time"
                v-if="clog.created_at">vor {{ clog.created_at | moment('from', 'now', true) }} (zuletzt)</span>
          <div v-if="isOpen">
            <Log v-for="entry in log" :key="entry.id" :log="entry"/>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import BaseAvatar from "./BaseAvatar";

export default {
  name: "Log",
  components: {BaseAvatar},
  props: {log: [Object, Array]},
  computed: {
    isMemberUser() {
      return this.clog.user && this.clog.user.typename === 'Member'
    },
    clog() {
      return Array.isArray(this.log) ? this.log[0] : this.log;
    },
    isGrouped() {
      return Array.isArray(this.log) && this.log.length > 1;
    }
  },
  data() {
    return {
      isOpen: false
    }
  }
}
</script>

<style scoped>
.log .media-content {
  line-height: 30px;
}

/*.username {
  font-weight: 600;
}*/
.time {
  font-size: 0.65rem;
  padding: 0;
  margin-left: 0.2rem;
}

.media.log + .media.log {
  padding-top: .5rem;
  margin: 0;
  border-top: 0;
}

.media .media {
  border-top: 0;
}

.media-left {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  justify-items: flex-start;
}

.accountIcon {

}
</style>