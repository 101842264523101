<template>
  <base-page title="Seminarleitung">
    <template slot="headerRight" v-if="$store.getters.hasPermission('manage lecturers')">
      <button v-if="!addLectureship" class="button is-flex-mobile" @click="addLectureship = true">
        <span>SeminarleiterIn ernennen</span>
      </button>
      <button v-else class="button" @click="addLectureship = false">
        <b-icon icon="close" type="is-danger"/>
        <span>Ernennung abbrechen</span>
      </button>
    </template>
    <member-list v-if="addLectureship" :add-lecturer="true"/>
    <lecturer-list v-else/>
  </base-page>
</template>

<script>
import BasePage from '../Layout/BasePage'
import LecturerList from "../Elements/Lecturer/LecturerList";
import MemberList from "../Elements/MemberList";

export default {
  components: {MemberList, LecturerList, BasePage},
  name: 'Lecturers',
  data() {
    return {
      addLectureship: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
