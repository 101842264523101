<template>
  <b-select v-if="!multiple" v-model="computedValue" v-bind="$attrs" class="selectField"
            expanded
            placeholder="Funktion auswählen">
    <option :value="null" class="has-text-grey-light">Nichts auswählen</option>
    <option v-for="fun in filteredFunctions" :value="fun.id">{{ fun.name }}</option>
  </b-select>
  <b-taginput
      v-else
      v-model="computedValue"
      v-bind="$attrs"
      :allowNew="false"
      :data="filteredFunctions"
      :open-on-focus="true"
      autocomplete
      field="name"
      @typing="input => this.searchQuery = input"
  ></b-taginput>
</template>

<script>
import FUNCTIONS_SELECT_QUERY from '../../graphql/Query/AssociationFunctions.gql'

export default {
  name: 'function-select',
  data() {
    return {
      AssociationFunctions: [],
      searchQuery: ''
    }
  },
  methods: {
    select(option) {
      if (option !== null) {
        this.$emit('input', option.id)
      } else {
        this.$emit('input', null)
      }
    }
  },
  apollo: {
    AssociationFunctions: {
      query: FUNCTIONS_SELECT_QUERY,
    }
  },
  props: {value: [String, Array], multiple: Boolean},
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    filteredFunctions() {
      return this.AssociationFunctions.slice().sort(function (a, b) {
        return a.name.localeCompare(b.name);
      }).filter(t => t.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
    }
  }
}
</script>

<style scoped>
.selectField:not(.is-expanded) {
  max-width: 18rem;
}
</style>
