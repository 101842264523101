<template>
  <div>
    <vue-treeselect v-if="$config.SHOW_ASSOCIATION_LEVELS" :clearable="true" :defaultExpandLevel="1"
                    :multiple="multiple" :options="DepartmentTree"
                    :placeholder="$tc('Verbandsstufe',multiple) +' auswählen'"
                    :value="value"
                    @input="$emit('input', $event)">
      <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
             :class="labelClassName" :title="node.label">
        {{ node.label }}
        <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
      </label>
    </vue-treeselect>
    <b-select v-else :value="value" expanded @input="$emit('input', $event)">
      <option v-for="d in AssociationDepartments" :key="d.id" :value="d.id">{{ d.name }}
        <template v-if="d.address && d.address.extra">({{ d.address.extra }})</template>
      </option>
    </b-select>
  </div>
</template>

<script>
import GET_DEPARTMENTS_QUERY from '../../graphql/Query/AssociationDepartments.gql'
import VueTreeselect from "@riophae/vue-treeselect";

const nest = (items, id = null, link = 'parent_id') =>
    items
        .filter(item => item[link] === id)
        //.map(item => ({...item, children: nest(items, item.id)}));
        .map(item => {
          let children = nest(items, item.id);
          return children.length > 0 ? {...item, children: children} : item;
        });

export default {
  name: "DepartmentSelect",
  components: {VueTreeselect},
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    value: [String, Array]
  }
  ,
  data() {
    return {
      AssociationDepartments: [],
      query: '',
      bla: null
    }
  },
  methods: {},
  computed: {
    DepartmentTree() {
      let list = this.AssociationDepartments.map((node) => {
        const extra = node.address && node.address.extra ? ' (' + node.address.extra + ')' : '';
        return {
          ...node, label: node.name + extra
        }
      });
      return nest(list)
    }
  },
  apollo: {
    AssociationDepartments: {
      query: GET_DEPARTMENTS_QUERY
    }
  }
}
</script>

<style lang="less">
@import "~@riophae/vue-treeselect/src/style.less";

@treeselect-control-height: 40px;

</style>
