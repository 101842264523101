<template>
  <div class="headlineComponent">
    <div class="columns is-mobile">
      <div :class="{'is-narrow': rightExpanded}" class="column">
        <div class="headtitle">
          <slot/>
        </div>
      </div>
      <div :class="{'is-narrow': !rightExpanded}" class="column rightSide">
        <div class="">
          <slot name="right"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headline",
  props: {
    rightExpanded: {
      type: Boolean,
      default: () => false
    }
  },
}
</script>
<style scoped lang="scss">
.headlineComponent {

  .headtitle {
    &a {
      color: black;
    }

    font-size: 1.5rem;
    //color:black;
    font-weight: 400;

  }

  border-bottom: 1px #e1e4e8 solid;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;

  .rightSide {
    display: flex;
    align-items: center;
    justify-content: right;

    & > * {
      flex-grow: 1;
    }
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  &.is-borderless {
    border: 0;
    margin-bottom: 0.2rem;
  }

  &.is-small, &.is-small .headtitle {
    font-size: 1rem;
    font-weight: 600;
  }

  &.is-medium, &.is-medium .headtitle {
    font-size: 2rem;
    font-weight: normal;

  }
}

</style>