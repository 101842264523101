<template>
  <div>
    <base-page :title="$tc('Mitglied', 2)">
      <MembersList/>
      <template slot="headerRight">
        <add-member-button class="is-block-mobile"/>
      </template>
    </base-page>
  </div>
</template>

<script>
import MembersList from '../Elements/MemberList'
import BasePage from '../Layout/BasePage'
import AddMemberButton from "../Elements/AddMemberButton";

export default {
  name: 'Members',
  components: {
    AddMemberButton,
    BasePage,
    MembersList
  },
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
