<template>
  <div>
    <div v-for="appointment in Seminar.appointments" class="card m-b-md">
      <div style="padding:0.75rem;">
        <div class="columns is-mobile">
          <div class="column">
            <table>
              <tbody>
              <tr>
                <th style="width:6rem !important;">Ort</th>
                <td>
                                <span v-if="appointment.place && editAppointmentId !== appointment.id">
                                    {{appointment.place['name']}}
                                </span>
                  <span v-else-if="editAppointmentId !== appointment.id" class="has-text-danger">
                                     Kein Ort angegeben
                                </span>
                  <places-select v-else
                                 :isSmall="true"
                                 v-model="editData.place_id"></places-select>
                </td>
              </tr>
              <tr>
                <th>Von</th>
                <td>
                  <span v-if="editAppointmentId !== appointment.id">{{appointment.begin | moment('D. MMMM YYYY - H:mm')}}</span>
                  <DateTimePicker :isSmall="true" v-else
                                  v-model="editData.begin"/>
                </td>
              </tr>
              <tr>
                <th>Bis</th>
                <td>
                                <span v-if="editAppointmentId !== appointment.id">
                                    {{appointment.end | moment('D. MMMM YYYY - H:mm')}}
                                </span>
                  <DateTimePicker v-else :isSmall="true" v-if="editAppointmentId === appointment.id"
                                  v-model="editData.end"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="column is-narrow" v-if="Seminar.canManageAll">
            <template v-if="editAppointmentId !== appointment.id">
              <a @click="editAppointment(appointment)">
                <b-icon icon="pencil"></b-icon>
              </a>
              <a @click="deleteAppointment(appointment.id)">
                <b-icon icon="delete" type="is-danger"></b-icon>
              </a>
            </template>
          </div>
        </div>
        <div v-if="editAppointmentId === appointment.id" class="">
          <button @click="saveAppointment"
                  :disabled="!checkEditAppointmentForm"
                  class="button is-small is-success m-r-xs"
                  :class="{'is-loading': isLoadingEditAppointment}">
            Speichern
          </button>
          <button @click="editAppointmentId = 0"
                  class="button is-small is-danger">
            Abbrechen
          </button>
        </div>
      </div>
    </div>
    <div v-if="Seminar.appointments.length === 0"
         class="message is-danger">
      <div class="message-body">
        Noch keine Termine
      </div>
    </div>
    <add-appointment-button :seminar="Seminar" v-if="Seminar.canManageAll"></add-appointment-button>
  </div>
</template>
<script>
  import GET_SEMINAR_QUERY from '../../../graphql/Query/Seminar.gql'
  import UPDATE_APPOINTMENT_MUTATION from '../../../graphql/Mutations/updateAppointment.gql'
  import DELETE_APPOINTMENT_MUTATION from '../../../graphql/Mutations/deleteAppointment.gql'
  import {deleteAppointmentFromSeminar} from '../../../cache/cacheUpdater'

  import moment from 'moment'
  import DateTimePicker from '../DateTimePicker'
  import EmptyTable from "../EmptyTable";
  import PlacesSelect from "../PlacesSelect";
  import AddAppointmentButton from "./AddAppointmentButton";

  export default {
    name: 'appointment-list',
    components: {AddAppointmentButton, PlacesSelect, EmptyTable, DateTimePicker},
    props: ['id'],
    data() {
      return {
        Seminar: {},
        editAppointmentId: 0,
        editData: {},
        isLoadingEditAppointment: false,
      }
    },
    methods: {
      editAppointment(appointment) {
        this.editAppointmentId = appointment.id
        this.editData = {
          begin: appointment.begin,
          end: appointment.end,
          place_id: appointment.place ? appointment.place.id : null
        }
      },
      saveAppointment() {
        this.isLoadingEditAppointment = true
        this.$apollo.mutate({
          mutation: UPDATE_APPOINTMENT_MUTATION,
          variables: {
            id: this.editAppointmentId,
            appointment: this.editData
          }
        }).then(() => {
          this.isLoadingEditAppointment = false
          this.editAppointmentId = 0
        })
      },
      deleteAppointment(id) {
        this.$buefy.dialog.confirm({
          type: 'is-danger',
          message: 'Möchten Sie diesen Termin wirklich löschen?',
          onConfirm: () => {

            this.$apollo.mutate({
              mutation: DELETE_APPOINTMENT_MUTATION,
              variables: {
                id: id
              },
              update: (store) => deleteAppointmentFromSeminar(store, this.id, id)
            }).then(() => {

            })
          }
        })
      }
    },
    computed: {
      checkEditAppointmentForm() {
        return moment(this.editData.begin).isValid && moment(this.editData.end).isValid
      }
    },
    apollo: {
      Seminar: {
        query: GET_SEMINAR_QUERY,
        variables() {
          return {
            id: this.id,
          }
        },
        loadingKey: 'isLoading'
      }
    }
  }
</script>

<style scoped>
  .appointments {
    display: flex;
    flex-shrink: 1;
    margin-bottom: 1em;
    justify-content: center;
  }

  .appointments > div {

  }
</style>
