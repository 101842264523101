<template>
  <Comments v-if="$apollo.loading === false"
            :id="id"
            :comments="Seminar.comments"
            :logs="Seminar.audits"
            :mutation="UPDATE_SEMINAR_MUTATION"
            class="m-t-md"
  />
</template>
<script>
import Comments from "@/components/Elements/Comments.vue";
import GET_SEMINAR_QUERY from "@/graphql/Query/Seminar.gql";
import UPDATE_SEMINAR_MUTATION from "@/graphql/Mutations/updateSeminar.gql";

export default {
  name: "SeminarCommentsAndLogs",
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    Comments
  },
  data() {
    return {
      GET_SEMINAR_QUERY,
      UPDATE_SEMINAR_MUTATION,
      Seminar: {}
    }
  },
  apollo: {
    Seminar: {
      query: GET_SEMINAR_QUERY,
      variables() {
        return {
          id: this.id,
          includeComments: true,
          includeAudits: true
        }
      }
    }
  }
}
</script>


<style scoped>

</style>