<template>
  <base-page title="Tagungsorte">
    <template v-slot:headerRight>
      <add-place-button v-if="$store.getters.hasPermission('manage places')"/>
    </template>
    <div class="columns">
      <div class="column">
        <b-input v-model="filters.searchQuery" placeholder="Tagungsort suchen"></b-input>
      </div>
      <div class="column is-narrow">
        <b-checkbox v-model="filters.hideInactive" class="mt-2">Inaktive ausblenden</b-checkbox>
      </div>
    </div>
    <listable :filter-defaults="{searchQuery: '', hideInactive: true}" :filter-function="FilterFunction"
              :query="require('../../graphql/Query/Places.gql')"
              :variables="{includeComments: true}"
              name="Places">

      <b-table-column v-slot="props">
        <router-link class="is-black-link" :to="{ name: 'Place', params: { id: props.row.id }}">
          <b-icon icon="map-marker" size=""></b-icon>
        </router-link>
      </b-table-column>

      <b-table-column v-slot="props" field="name" label="Name" sortable width="100%">
        <router-link :to="{ name: 'Place', params: { id: props.row.id }}">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="name" label="Kurzname" sortable>
        <router-link class="is-black-link" :to="{ name: 'Place', params: { id: props.row.id }}">
          {{ props.row.nameShort }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="address.city" label="Ort" sortable>
        {{ props.row.address ? props.row.address.city : '' }}
      </b-table-column>
      <b-table-column v-slot="props" label="Kommentare">
        <compact-comments :comments="props.row.comments"
                          :id="props.row.id"
                          title="Kommentare zum Tagungsort"
                          :mutation="require('../../graphql/Mutations/updatePlace.gql')"></compact-comments>
      </b-table-column>
      <b-table-column v-slot="props" field="state"
                      label="Status"
                      sortable
                      numeric
                      :custom-sort="sortstate">
        <state-dropdown :state="props.row.state" varName="place" :id="props.row.id"
                        :disabled="!$store.getters.hasPermission('manage places')"
                        :mutation="require('../../graphql/Mutations/updatePlace.gql')"></state-dropdown>
      </b-table-column>
      <div v-if="$store.getters.hasPermission('export places')" slot="footer" class="has-text-right m-t-lg">
        <a :href="$config.API_EXPORT_URL + '/places'" class="button" target="_blank">Tagungsorte Exportieren</a>
      </div>
    </listable>
  </base-page>
</template>

<script>
import BasePage from "../Layout/BasePage";
import AddPlaceButton from "../Elements/AddPlaceButton";
import {sortstate} from "@/utils/utils";
import StateDropdown from "../Elements/StateDropdown";
import CompactComments from "../Elements/compactComments";
import Listable from "../Elements/Listable";

export default {
  name: 'place-list',
  components: {Listable, CompactComments, StateDropdown, AddPlaceButton, BasePage},
  data() {
    return {
      filters: {
        searchQuery: '',
        hideInactive: true
      }
    }
  },
  methods: {
    sortstate,
    FilterFunction(data) {
      return data.filter((p) => p.name.toLowerCase().search(this.filters.searchQuery.toLowerCase()) !== -1 && !(p.state === false && this.filters.hideInactive === true))
    }
  }
}
</script>

<style scoped>

</style>