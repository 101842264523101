<template>
  <div>

    <!--<b-field label="Text Startseite (öffentlich)">
        <markdown-editor></markdown-editor>
    </b-field>

    <b-field label="E-Mail für Supportanfragen">
        <b-input placeholder="E-Mail eintragen"></b-input>
    </b-field>-->

    <setting-editor id="eaStartpage" label="Text Startseite (öffentlich)" type="markdown"/>
    <setting-editor id="eaMemberstartpage" label="Text Startseite (intern/eingeloggt)" type="markdown"/>

    <setting-editor id="eaLecturerpage" label="Text Seminarleitung" type="markdown"/>
    <setting-editor id="eaAnalysispage" label="Text Auswertung" type="markdown"/>
    <setting-editor v-if="$config.SHOW_SEMINAR_REGISTER" id="eaBeforeSignup" label="Text vor Online Anmeldung"
                    type="markdown"/>
    <setting-editor v-if="$config.SHOW_SEMINAR_REGISTER" id="eaAfterSignup"
                    label="Text nach Online Anmeldung (Bestätigung)"
                    type="markdown"/>
    <setting-editor id="certificateFooter"
                    label="Fußzeile in Teilnahmebestätigungen"
                    type="textarea"/>
    <setting-editor id="supportMail" label="E-Mail Adresse Supportformular" type="text"/>
  </div>
</template>

<script>
import SettingEditor from "../../Elements/SettingEditor";

export default {
  name: "EASettings",
  components: {SettingEditor},
}
</script>

<style scoped>

</style>