<template>
  <base-page v-if="!isLoading" :title="'Funktion - '+AssociationFunction.name">

    <div class="columns is-multiline">
      <div class="column is-full">
        <div class="headline is-borderless">Ausbildungspaket</div>
        <function-requirement-list :association-function="AssociationFunction"/>
      </div>
      <div class="column">
        <div class="headline is-borderless">Einstellungen</div>
        <div class="card" style="padding:.75rem;">
          <b-loading :active="isSavingCheckbox" :is-full-page="false" style="font-size: 0.75rem;"></b-loading>
          <div v-if="$config.SHOW_ANALYZE" class="field">
            <b-switch :disabled="!$store.getters.hasPermission('manage functions')"
                      :loading="true"
                      :value="AssociationFunction.canAnalyze"
                      type="is-success"
                      @input="setCheckbox('canAnalyze', $event)">
              Darf die zugehörige Verbandsstufe auswerten
            </b-switch>
          </div>
          <div v-if="$config.SHOW_REGISTER_NEW" class="field">
            <b-switch :disabled="!$store.getters.hasPermission('manage functions')"
                      :loading="true"
                      :value="AssociationFunction.isRegistrable"
                      type="is-success"
                      @input="setCheckbox('isRegistrable', $event)">
              Kann bei der Registrierung ausgewählet werden
            </b-switch>
          </div>
        </div>
      </div>

    </div>
  </base-page>
</template>

<script>
import GET_ASSOCIATION_FUNCTION_QUERY from '../../graphql/Query/AssociationFunction.gql'
import UPDATE_ASSOCIATION_FUNCTION_MUTATION from '../../graphql/Mutations/updateAssociationFunction.gql'
import BasePage from "../Layout/BasePage";
import BSwitch from "buefy/src/components/switch/Switch";
import FunctionRequirementList from "../Elements/Function/FunctionRequirementList";
import BLoading from "buefy/src/components/loading/Loading";

export default {
  name: 'Function',
  props: ['id'],
  data() {
    return {
      AssociationFunction: {},
      isLoading: 0,
      isSavingCheckbox: false
    }
  },
  methods: {
    setCheckbox(field, value) {
      this.isSavingCheckbox = true
      let variables = {}
      variables[field] = value
      this.$apollo.mutate({
        mutation: UPDATE_ASSOCIATION_FUNCTION_MUTATION,
        variables: {
          id: this.id,
          associationFunction: variables
        }
      }).then(() => {
        this.isSavingCheckbox = false
      })
    }
  },
  apollo: {
    AssociationFunction: {
      query: GET_ASSOCIATION_FUNCTION_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      loadingKey: 'isLoading'
    }
  },
  components: {BLoading, FunctionRequirementList, BSwitch, BasePage}
}
</script>

<style scoped>

</style>