<template>
  <div v-if="registrationInfo">
    <div class="headline is-borderless">Registrierungsangeben</div>
    <div class="card">
      <div class="card-content">
        <b-field v-if="registrationInfo.kundennummer" label="Kundennummer">
          {{ registrationInfo.kundennummer }}
        </b-field>
        <b-field v-if="registrationInfo.mitgliedernummer" label="VdK Mitgliedsnummer">
          {{ registrationInfo.mitgliedernummer }}
        </b-field>
        <b-field v-if="registrationInfo.otherAccessibility" label="Sonstige Barrierefreiheit">
          {{ registrationInfo.otherAccessibility }}
        </b-field>
        <b-field v-if="registrationInfo.otherAccommodation" label="Sonstige Verpflegung">
          {{ registrationInfo.otherAccommodation }}
        </b-field>
        <address-info :address="registrationInfo.address" :labels="{address:'Firma', email: 'Rechnungs E-Mail'}"
                      class="m-b-md"/>
        <address-info :address="registrationInfo.invoiceAddress" :labels="{address:'Rechnungsanschrift'}"
                      class="m-b-md"/>
        <b-field label="Newsletter">
          {{ registrationInfo.wantsNewsletter ? 'Ja, abonniert' : 'Nicht abonniert' }}
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import AddressInfo from "../AddressInfo";

export default {
  name: "MemberRegistration",
  props: ['registrationInfo'],
  components: {AddressInfo}
}
</script>

<style scoped>

</style>