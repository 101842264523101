<template>
  <div class="is-flex is-align-items-center">
    <b-icon :icon="status.icon" :type="status.type"/>
    <b-dropdown position="is-bottom-left">
      <template #trigger>
        <a>
          <b-icon icon="chevron-down"></b-icon>
        </a>
      </template>
      <editable
          v-for="[index, {icon, type,text}] in Object.entries(MemberDataStatus)"
          :key="index"
          v-slot="{mutate,loading}"
          :data="{id: member.id, reviewed_at: index === 'VERIFIED' ? new Date() : null}"
          :include-keys="['reviewed_at']"
          :mutation="UPDATE_MEMBER_MUTATION"
          input-arg="member">
        <b-dropdown-item
            :disabled="index === member.dataStatus" @click="mutate">
          <div class="media">
            <b-icon :icon="icon" :type="type" class="media-left"/>
            <div class="media-content">
              <h3>ist {{ text }}</h3>
            </div>
          </div>
        </b-dropdown-item>
      </editable>
    </b-dropdown>
  </div>
</template>

<script>
import {MemberDataStatus} from "@/constants";
import UPDATE_MEMBER_MUTATION from "@/graphql/Mutations/updateMember.gql"
import Template from "@/components/Pages/Template";
import Editable from "@/components/Elements/Editable";

export default {
  name: "MemberDataStatusSwitch",
  components: {Editable, Template},
  data() {
    return {
      MemberDataStatus,
      UPDATE_MEMBER_MUTATION
    }
  },
  computed: {
    status() {
      return MemberDataStatus[this.member.dataStatus]
    }
  },
  props: ["member"]
}
</script>

<style scoped>

</style>