<template>
    <div>
        <b-tag class="status_label participationStatus" :class="[states[status].class]">
            <b-icon size="is-small" :icon="states[status].icon"></b-icon>
            <span>{{ states[status].name }}</span>
        </b-tag>
    </div>
</template>

<script>
export default {
  name: 'participation-status-label',
  props: ['status'],
  data () {
    return {
      states: {
        PARTICIPATING: {
          name: 'Teilnehmer',
          value: 'participating',
          icon: 'account',
          class: 'is-success'
        },
        WAITING: {
          name: 'Warteliste',
          value: 'waitinglist',
          icon: 'timer-sand',
          class: 'is-warning'
        },
        COMPLETED: {
          name: 'Abgeschlossen',
          value: 'completed',
          icon: 'check',
          class: 'is-info'
        },
        CANCELED: {
          name: 'Abgesagt',
          value: 'canceled',
          icon: 'account-off',
          class: 'is-danger'
        }
      }
    }
  }
}
</script>

<style scoped>
.participationStatus {
    font-size:.8rem;
    line-height:1.8em;
    height:1.8rem;
    width:7.5rem;
}
</style>
