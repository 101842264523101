<template>
  <base-page :title="'Benutzer  -  '+User.fullName" v-if="!isLoading">
    <div class="box">
      <div class="columns is-multiline" v-if="!editing">
        <div class="column ">
          <label class="label">Kontakt</label>
          {{User.fullName}}<br/>
          <a :href="'mailto:'+User.email">{{User.email}}</a>
        </div>
        <div class="column">
          <label class="label">Bezirk</label>
          {{User.district.name}}<br/><br/>
          <label class="label">Rolle</label>
          <span v-if="User.role">{{ User.role.name }}</span>
          <span v-else>Keine</span>
        </div>
      </div>
      <div class="columns" v-else>
        <div class="column">
          <b-field :type="errors.firstName ? 'is-danger': ''" label="Vorname">
            <b-input placeholder="Vorname" v-model="editData.firstName"></b-input>
          </b-field>
          <b-field :type="errors.lastName ? 'is-danger': ''" label="Nachname">
            <b-input placeholder="Nachname" v-model="editData.lastName"></b-input>
          </b-field>
          <b-field :type="errors.district_id ? 'is-danger': ''" label="Bezirk">
            <district-select v-model="editData.district_id"/>
          </b-field>
          <b-field :type="errors.role_id ? 'is-danger': ''" label="Rolle">
            <role-select v-model="editData.roles.sync[0]"/>
          </b-field>
          <b-field :type="errors.email ? 'is-danger': ''" label="E-Mail">
            <b-input placeholder="E-Mail-Adresse" type="email" v-model="editData.email"></b-input>
          </b-field>
          <b-field :type="errors.password ? 'is-danger': ''" label="Passwort"
                   message="Mindestens 8 Zeichen lang (leer = unverändert)">
            <b-input placeholder="Neues Passwort eingeben" type="password"
                     v-model="editData.password"></b-input>
          </b-field>
        </div>
      </div>
      <div class="m-t-lg" v-if="$store.getters.hasPermission('manage users')">
        <button @click="edit" class="button" v-if="editing === false">Bearbeiten</button>
        <template v-else>
          <button :class="{'is-loading': isLoadingEdit}" @click="save" class="button">Speichern</button>
          <button @click="editing = false" class="button">Abbrechen</button>
        </template>

      </div>
    </div>
    <comments
      :comments="User.comments"
      :id="id"
      :mutation="require('../../graphql/Mutations/updateUser.gql')"
      title="Kommentare zum Benutzer"/>
  </base-page>
</template>

<script>
  import GET_USER_QUERY from '../../graphql/Query/User.gql'
  import UPDATE_USER_MUTATION from '../../graphql/Mutations/updateUser.gql'
  import BasePage from "../Layout/BasePage";
  import Comments from "../Elements/Comments";
  import DistrictSelect from "../Elements/DistrictSelect";
  import RoleSelect from "../Elements/RoleSelect";

  export default {
    name: 'place-detail',
    props: ['id'],
    data() {
      return {
        User: {},
        editing: false,
        editData: {},
        isLoading: 0,
        isLoadingEdit: false,
        errors: {}
      }
    },
    methods: {
      validate() {
        this.errors = {}
        this.errors.firstName = !this.editData.firstName
        this.errors.lastName = !this.editData.lastName
        this.errors.email = !this.editData.email
        this.errors.district_id = !this.editData.district_id
        this.errors.role_id = !this.editData.roles.sync[0]
        this.errors.password = !!this.editData.password && this.editData.password.length < 8

        for (let i in this.errors) {
          if (this.errors[i] === true) {
            return false
          }
        }
        return true
      },
      edit() {
        this.editing = true
        this.editData = {
          firstName: this.User.firstName,
          lastName: this.User.lastName,
          email: this.User.email,
          district_id: this.User.district.id,
          roles: {sync: [...this.User.role.id]}
        }
      },
      save() {
        if (!this.validate()) return
        this.isLoadingEdit = true
        if(this.editData.password == "") delete this.editData.password;
        this.$apollo.mutate({
          mutation: UPDATE_USER_MUTATION,
          variables: {
            id: this.id,
            user: this.editData
          }
        }).then(() => {
          this.isLoadingEdit = false
          this.editing = false
        })
      }
    },
    apollo: {
      User: {
        query: GET_USER_QUERY,
        variables() {
          return {
            id: this.id
          }
        },
        loadingKey: 'isLoading'
      }
    },
    components: {RoleSelect, DistrictSelect, Comments, BasePage}
  }
</script>

<style scoped>

</style>
