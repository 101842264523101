<template>
    <div class="fileitem card">
        <div class="columns is-mobile">
            <div class="column">
                <a :href="item.link">{{item.fileName}}</a>
            </div>
            <div class="column is-narrow" style="position: relative;" v-if="canDelete">
                <b-loading :active="isDeleting" :is-full-page="false"/>
                <a @click="deleteFile">
                    <b-icon icon="delete" type="is-danger"></b-icon>
                </a>
            </div>
        </div>

    </div>
</template>

<script>
import DELETE_UPLOAD_MUTATION from '../../graphql/Mutations/deleteUpload.gql'

export default {
  name: "UploadItem",
  props: ['item', 'canDelete'],
  data() {
    return {
      isDeleting: false
    }
  },
  methods: {
    deleteFile() {
      this.$buefy.dialog.confirm({
        title: 'Datei löschen',
        message: 'Sind Sie sicher, dass sie die Datei <b>' + this.item.fileName + '</b> löschen möchten?',
        confirmText: 'Ja, entfernen!',
        type: 'is-danger',
        onConfirm: () => {
          this.isDeleting = true
          this.$apollo.mutate({
            mutation: DELETE_UPLOAD_MUTATION,
            variables: {
              id: this.item.id
            }
          }).then(() => {
            this.isDeleting = false;
            this.$emit('refresh')
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.fileitem {
    position:static;
    padding: 0.5rem;
    margin-bottom: .5rem;
}

</style>
