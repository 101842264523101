<template>
    <div v-if="!isLoading">
        <b-table
                :data="Lecturer.seminars"
                narrowed
                striped
                paginated
                :mobile-cards="false"
                :default-sort="['begin', 'desc']"
                :loading="!!isLoading"
                class="table-has-frame nowraptable">

                <b-table-column v-slot="props" label="ID" field="id" sortable>
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column v-slot="props" label="Name" field="template.name" width="100%" sortable>
                    <router-link :to="{ name: 'Seminar', params: { id: props.row.id }}">
                        {{ props.row.template.name }}
                    </router-link>
                </b-table-column>
                <b-table-column v-slot="props" label="Beginn" sortable field="begin">
                    {{ props.row.begin
                    | moment('D. MMMM YYYY') }}
                </b-table-column>
                <b-table-column v-slot="props" label="Status" field="status"  numeric>
                    <seminar-status :status="props.row.status"/>
                </b-table-column>

            <template slot="empty">
                <empty-table>{{ Lecturer.member.fullName }} hat noch keine Seminare gehalten ..
                </empty-table>
            </template>
        </b-table>
    </div>
</template>

<script>
import SeminarStatus from '../Seminar/SeminarStatus'
import EmptyTable from '../EmptyTable'
import GET_LECTURER_QUERY from '../../../graphql/Query/Lecturer.gql'

export default {
  name: 'lecturer-seminars',
  components: {EmptyTable, SeminarStatus},
  data () {
    return {
      Lecturer: {},
      isLoading: 0
    }
  },
  props: ['id'],
  apollo: {
    Lecturer: {
      query: GET_LECTURER_QUERY,
      variables () {
        return {
          id: this.id,
          includeSeminars: true
        }
      },
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped>

</style>