<template>
  <div style="margin: 0 auto;">
    <daschboard-todos></daschboard-todos>
    <h1 class="headline is-borderless m-t-lg is-medium">Aktuelles</h1>
    <div class="card" style="padding:1em;">
      <div class="content" v-html="$options.filters.marked(Setting.value)" v-if="!isLoading">

      </div>
    </div>
  </div>
</template>

<script>
import GET_SETTING_QUERY from '../../graphql/Query/Setting.gql'
import DaschboardTodos from "../Elements/DaschboardTodos";

export default {
  components: {DaschboardTodos},
  data() {
    return {
      Setting: {},
      isLoading: 0
    }
  },
  apollo: {
    Setting: {
      query: GET_SETTING_QUERY,
      loadingKey: 'isLoading',
      variables: {
        id: 'eisStartpage'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  overflow-x: auto;
}
</style>
