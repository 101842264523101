<template>
    <div>
        <div v-if="state" class="tag is-success onoffTag">an</div>
        <div v-else class="tag is-danger onoffTag">aus</div>
    </div>
</template>

<script>
export default {
  name: 'on-off-state',
  props: ['state']
}
</script>

<style scoped>
.onoffTag{
    width: 2.5rem;
}
</style>