<template>
    <b-dropdown position="is-bottom-left">
        <button slot="trigger" class="button" :class="{'is-loading' : isLoading}">
            <b-icon icon="pencil" size="is-small"></b-icon>
            <span>Aktion</span>
        </button>
        <b-dropdown-item @click="cancelSeminar">
            <b-icon icon="close" size="is-small"></b-icon>
            Seminar Absagen
        </b-dropdown-item>
        <!--<b-dropdown-item @click="deleteSeminar">
            <b-icon icon="delete" size="is-small"></b-icon>
            Seminar Löschen
        </b-dropdown-item>-->
    </b-dropdown>
</template>

<script>
import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'

export default {
  name: "ActionButton",
  props: ['seminar'],
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    deleteSeminar() {

    },
    cancelSeminar() {
      if (this.seminar.status === 'CANCELED') return
      this.$buefy.dialog.confirm({
        message: 'Möchten Sie das Seminar wirklich <b>absagen</b>?<br/><br/>Es muss sichergestellt werden, dass <b>Seminarleitung, Tagungsort</b> und <b>Teilnehmer</b> informiert sind.<br/><br/>In den Kommentaren bitte den Zeitpunkt der Absage notieren.',
        title: 'Wirklich fortfahren?',
        confirmText: 'Ja, Absagen',
        type: 'is-danger',
        onConfirm: () => {
          this.isLoading = true
          this.$apollo.mutate({
            mutation: UPDATE_SEMINAR_MUTATION,
            variables: {
              id: this.seminar.id,
              seminar: {
                status: 'CANCELED'
              }
            }
          }).then(() => {
            this.isLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
