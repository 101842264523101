<template>
  <div class="tags is-inline">
    <slot></slot>
    <component :is="hasClickListener ? 'a':'div'"
               v-for="(tag, index) in allTypes ? tags : tags.filter(t => t.type === type)" :key="index"
               class="tag is-light is-link mr-1"
               @click="$emit('click',tag.name)">
      {{ tag.name }}
    </component>
  </div>
</template>

<script>
export default {
  name: "TagsSimple",
  props: {
    tags: Array,
    allTypes: Boolean,
    type: {
      type: String,
      default: () => null
    },
  },
  computed: {
    hasClickListener() {
      return this.$listeners && this.$listeners.click
    }
  }
}
</script>

<style lang="scss" scoped>
.tag {
  margin: 0;
}

.tags {
  white-space: pre-wrap;
}
</style>
