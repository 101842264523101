<template>
  <div>
    <a @click="modalActive = true" class="button is-flex-mobile">
      <b-icon icon="map-marker-plus" size="is-small"/>
      <span>Tagungsort anlegen</span>
    </a>
    <b-modal :active.sync="modalActive" :has-modal-card="true">
      <div class="modal-card">
        <div class="modal-card-head">
          <div class="modal-card-title">Tagungsort hinzufügen</div>
        </div>
        <div class="modal-card-body">
          <b-field :type="errors.name ? 'is-danger': ''" label="Name*">
            <b-input placeholder="Name" v-model="newPlace.name"></b-input>
          </b-field>
          <b-field :type="errors.nameShort ? 'is-danger': ''" label="Kurzname*">
            <b-input placeholder="Kurzname" v-model="newPlace.nameShort"></b-input>
          </b-field>
          <b-field label="Beschreibung">
            <b-input placeholder="Beschreibung" type="textarea" v-model="newPlace.description"></b-input>
          </b-field>
        </div>
        <div class="modal-card-foot">
          <button :class="{'is-loading' : isLoading}"
                  @click="addPlace"
                  class="button is-success"
          >
            Hinzufügen
          </button>
          <button @click="modalActive=false" class="button is-danger">Abbrechen</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CREATE_PLACE_MUTATION from '../../graphql/Mutations/createPlace.gql'
import GET_PLACES_QUERY from '../../graphql/Query/Places.gql'

export default {
  name: "AddPlaceButton",
  data() {
    return {
      modalActive: false,
      isLoading: false,
      newPlace: {},
      errors: {}
    }
  },
  methods: {
    validate() {
      this.errors = {}
      this.errors.name = !this.newPlace.name
      this.errors.nameShort = !this.newPlace.nameShort


      for (let i in this.errors) {
        if (this.errors[i] === true) {
          return false
        }
      }
      return true
    },
    addPlace() {
      if (!this.validate()) return
      this.isLoading = true
      this.$apollo.mutate({
        mutation: CREATE_PLACE_MUTATION,
        refetchQueries: [{query: GET_PLACES_QUERY}],
        variables: {
          place: this.newPlace
        }
      }).then(({data}) => {
        this.isLoading = false
        this.$router.push({name: 'Place', params: {id: data.createPlace.id}})
      })
    }
  }
}
</script>

<style scoped>

</style>
