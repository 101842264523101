<template>
    <div class="card">
        <b-loading :is-full-page="false" :active="isDeleting" style="font-size:0.7rem;"></b-loading>
        <div class="card-content">
            <div class="columns is-mobile" style="flex-wrap:wrap;">
                <a class="is-block column" @click="$emit('load')">
                    {{template.name}}
                </a>
                <div class="column has-text-right is-narrow" style="white-space:nowrap; flex-grow:1;">
                    <a @click="deleteTemplate">
                        <b-icon icon="delete" type="is-danger"></b-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DELETE_TEMPLATE_MUTATION from '../../graphql/Mutations/deleteMailTemplate.gql'
import GET_MAIL_TEMPLATES_QUERY from '../../graphql/Query/MailTemplates.gql'

export default {
  name: 'template-list-item',
  data() {
    return {
      isDeleting: false
    }
  },
  props: ['template'],
  methods: {
    deleteTemplate() {
      this.$buefy.dialog.confirm({
        title: 'Vorlage löschen',
        message: 'Möchtest du die Vorlage wirklich <b>löschen</b>?',
        confirmText: 'Vorlage Löschen',
        type: 'is-danger',
        onConfirm: () => {
          this.isDeleting = true
          this.$apollo.mutate({
            mutation: DELETE_TEMPLATE_MUTATION,
            variables: {
              id: this.template.id
            },
            refetchQueries: [{query: GET_MAIL_TEMPLATES_QUERY}]
          }).then(() => {
            this.isDeleting = true
            this.$emit('deleted')
          })
        }
      })
    }
  }

}
</script>

<style scoped>
.lowOpacity {
    opacity: 0.3;
}
</style>
