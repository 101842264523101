<template>
  <div>
    <div class="headline is-borderless">{{ $tc('Mitgliederbereich') }}</div>
    <div class="card">
      <div class="card-content">
        <template v-if="member.isRegistered">
          <b-field :message="member.activated ? '' : 'Dieser Account ist noch nicht aktiviert'"
                   label="Account Status"
                   type="is-danger">

            <editable v-if="!member.activated && $store.getters.hasPermission('manage members')"
                      v-slot="{mutate, loading, editData}"
                      :data="member"
                      :mutation="require('../../../graphql/Mutations/updateMember.gql')"
                      input-arg="member">
              <button
                  :class="{'is-loading': loading}"
                  class="button"
                  @click="editData.activated = true; mutate();">
                Account aktivieren
              </button>
            </editable>
            <div v-else class="has-text-success">Dieser Account ist aktiviert</div>
          </b-field>
          <b-field :label="'Als ' + $tc('Mitglied') + ' einloggen'">
            <button v-if="member.isRegistered"
                    class="button"
                    @click="logInEa(member.id)">In {{ $tc('Mitgliederbereich') }} Einloggen
            </button>
          </b-field>
          <b-field v-if="$store.getters.hasPermission('manage members')" label="Passwort ändern"
                   message="Mindestens 8 Zeichen lang">
            <editable v-slot="{mutate, loading, editData}"
                      :data="member"
                      :mutation="require('../../../graphql/Mutations/updateMember.gql')"
                      input-arg="member"
                      @mutated="$buefy.toast.open({message: 'Passwort erfolgreich geändert!', type: 'is-success'})">
              <b-field>
                <b-input v-model="editData.password"
                         expanded
                         minlength="8"
                         placeholder="Neues Passwort eingeben"></b-input>
                <p class="control">
                  <button :class="{'is-loading': loading}"
                          :disabled="!(editData.password && editData.password.length > 8)"
                          class="button"
                          @click="mutate">
                    Passwort ändern
                  </button>
                </p>
              </b-field>
            </editable>
          </b-field>
        </template>
        <div v-else class="has-text-centered">
          Hat sich noch nicht für den internen Bereich registiert.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LOGIN_TO_EA_MUTATION from "../../../graphql/Mutations/loginEaUser.gql";
import {EA_URL} from "@/config";
import Editable from "../Editable";

export default {
  name: "MemberUser",
  components: {Editable},
  props: {
    member: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    logInEa(id) {
      this.$apollo.mutate({
        mutation: LOGIN_TO_EA_MUTATION,
        variables: {
          id: id
        }
      }).then(() => {
        window.open(EA_URL, '_blank');
      })
    }
  }
}
</script>

<style scoped>

</style>