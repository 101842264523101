<template>
  <div>
    <div :class="{'isMe': isMe}" :style="style" class="avatar">
            <span v-if="!raw && !$slots.default">
                <template v-if="user.id !== 'notReal'">
                    {{ initial(user.fullName) }}
                </template>
                <template v-else>
                    <i :class="{'isMe': isMe}" class="mdi mdi-account userAvatar"></i>
                </template>
            </span>
      <span v-else-if="!$slots.default">{{ raw }}</span>
      <span v-else><slot></slot></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'base-avatar',
  props: {
    user: Object,
    raw: String,
    size: {
      type: Number,
      default: 35
    }
  },
  computed: {
    isMe() {
      return this.user && this.user.id === this.$store.state.auth.user.id
    },
    style() {
      return {
        height: this.size + 'px',
        width: this.size + 'px',
        font: Math.floor(this.size / 2.5) + 'px/100px Helvetica, Arial, sans-serif',
        lineHeight: this.size + Math.floor(this.size / 20) + 'px',
        fontWeight: 600,
      }
    }
  },
  methods: {
    initial(username) {
      if (username) {
        let parts = username.split(/[ -]/)
        let initials = ''
        for (var i = 0; i < parts.length; i++) {
          initials += parts[i].charAt(0)
        }
        if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
          initials = initials.replace(/[a-z]+/g, '')
        }
        initials = initials.substr(0, 3).toUpperCase()
        return initials
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  border: 0;
  background-color: lightgrey;
  color: #6e6e6e;
}

.iconAddon {
  position: absolute;
  right: -.25em;
  bottom: -.25em;
  height: 1.2em;
  border-radius: 50%;
  background-color: white;
}

.userAvatar {
  font-size: 1.2rem;
}

.isMe {
  background-color: $user-color;
  color: findColorInvert($user-color);
}

.iconAddon i {
}
</style>
