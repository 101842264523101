<template>
  <b-field v-if="callable && callable.phoneNumbers" class="m-t-sm" group-multiline grouped label="Telefon">
    <!-- <phone-number/>-->
    <div v-for="phoneNumber in [...sortedPhoneNumbers,newPhoneNumber]" :key="phoneNumber.id"
         class="control">
      <editable v-slot="{editing, mutate, edit, data, editData, loading, errors, nestedErrors, regexpErrors}"
                :data="{id: callable.id, phoneNumbers: {upsert: [phoneNumber]}}"
                :include-keys="['phoneNumbers.upsert[0].id']"
                :input-arg="input_arg"
                :mutation="callable_mutation"
                class="is-fullheight"
                style="height: 100%">
        <template v-if="!editing && phoneNumber.id == null">
          <a v-if="canEdit" @click="edit">
            <b-tag :type="(sortedPhoneNumbers.length > 0 ? 'is-primary ' : 'is-danger ')+ 'is-light'"
                   size="is-medium">
              <span v-if="sortedPhoneNumbers.length === 0">Nummer hinzufügen </span>+
            </b-tag>
          </a>
          <b-tag v-else-if="sortedPhoneNumbers.length === 0" size="is-medium" type="is-danger is-light">Keine
            Telefonnummer
          </b-tag>
        </template>
        <b-taglist v-else attached>
          <b-tag :type="getType(phoneNumber)" size="is-medium">
            <template v-if="editing">
              <input v-model="editData.phoneNumbers.upsert[0].number" placeholder="+49 123 1234567"/>
            </template>
            <template v-else>
              <a :href="'tel:'+phoneNumber.number.replace(/[^+\d]+/g, '')" class="ci">{{ phoneNumber.number }}</a>
            </template>
          </b-tag>
          <template v-if="editing">
            <b-tag size="is-medium" type="is-success">
              <a class="ci" @click="mutate">
                <b-icon icon="check" size="is-small"></b-icon>
              </a>
            </b-tag>
            <b-tag size="is-medium" type="is-danger">
              <a class="ci" @click="edit(false)">
                <b-icon icon="close" size="is-small"></b-icon>
              </a>
            </b-tag>
          </template>
          <template v-else>
            <b-tag v-if="canEdit" :type="getType(phoneNumber)" size="is-medium">
              <b-dropdown hoverable>
                <template #trigger>
                  <a class="ci">
                    <b-icon icon="chevron-down"/>
                  </a>
                </template>
                <b-dropdown-item @click="edit">
                  Bearbeiten
                </b-dropdown-item>
                <b-dropdown-item v-if="!phoneNumber.isDefault" @click="set(phoneNumber.id, 'isDefault', true)">
                  Zur Kontaktnummer machen
                </b-dropdown-item>
                <editable v-if="!(phoneNumber.isDefault && sortedPhoneNumbers.length !== 1)"
                          v-slot="{mutate,loading}"
                          :data="{id: callable.id, phoneNumbers: {delete: [phoneNumber.id]}}"
                          :include-keys="['phoneNumbers']"
                          :input-arg="input_arg"
                          :mutation="callable_mutation">
                  <b-dropdown-item @click="$buefy.dialog.confirm({
                      type:'is-danger',
                      title: 'Addresse Löschen?',
                      message:'Wollen Sie die Telefonnummer wirklich löschen?',
                      confirmText: 'Ja, Nummer löschen!',
                      onConfirm: mutate
                      })">
                    Löschen
                  </b-dropdown-item>
                </editable>
              </b-dropdown>
            </b-tag>
          </template>
        </b-taglist>
      </editable>
    </div>
  </b-field>

</template>

<script>
import Editable from "@/components/Elements/Editable";

export default {
  name: "PhoneNumbers",
  components: {Editable},
  data() {
    return {
      newPhoneNumber: {id: null, number: ""}
    }
  },
  props: ['callable', 'callable_mutation', 'input_arg', 'canEdit'],
  computed: {
    sortedPhoneNumbers() {
      return this.callable.phoneNumbers.slice()
          .sort((a, b) => Number(b.isDefault) - Number(a.isDefault))
    }
  },
  methods: {
    set(id, field, val) {
      this.$apollo.mutate(
          {
            mutation: this.callable_mutation,
            variables: {
              id: this.callable.id,
              [this.input_arg]: {
                phoneNumbers: {
                  update: [
                    {id, [field]: val}
                  ]
                }
              }
            }
          }
      )
    },
    getType(number) {
      return (number.isDefault ? ['is-primary'] : ['is-primary', 'is-light']).join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.phonetable * {
  padding: 0;
}


.ci {
  color: inherit !important;
}

input {
  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }

  width: 150px;
  font-size: 1em;
  background: transparent;
  border: 0;
  color: inherit !important;

  &:focus, &:active {
    border: 0;
    outline: 0;
  }
}
</style>