<template>
    <button class="button is-success is-small addLecturerButton"
            :class="{'is-loading': isLoading}"
            :disabled="isLecturer"
            @click="addLecturer"
    >
        <template v-if="!isLecturer">
            <b-icon icon="plus" size="is-small"></b-icon>
            <span>SeminarleiterIn ernennen</span>
        </template>
        <template v-else>
            <span>Ist bereits SeminarleiterIn</span>
        </template>
    </button>
</template>

<script>
import CREATE_LECTURER_MUTATION from '../../../graphql/Mutations/createLecturer.gql'
import {addLecturer} from "../../../cache/cacheUpdater";

export default {
  name: 'add-lecturer-button',
  data() {
    return {
      isLoading: false
    }
  },
  props: ['member'],
  methods: {
    addLecturer() {
      this.isLoading = true

      this.$apollo.mutate({
        mutation: CREATE_LECTURER_MUTATION,
        variables: {
          lecturer: {
            member_id: this.member.id
          }
        },
        update: (store, {data: {createLecturer}}) => addLecturer(store, createLecturer)
      }).then(({data: {createLecturer}}) => {
        this.isLoading = false

        this.$router.push({name: 'Lecturer', params: {id: createLecturer.id}})
      })
    }
  },
  computed: {
    isLecturer() {
      return !!this.member.lecturer
    }
  }
}
</script>

<style scoped>
.addLecturerButton {
    width: 10.5rem;
}
</style>
