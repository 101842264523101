<template>
    <div>
        <setting-editor id="eisStartpage" type="markdown" label="Text Dashboard"/>
    </div>
</template>

<script>
import SettingEditor from "../../Elements/SettingEditor";

export default {
  name: "SettingsOverview",
  components: {SettingEditor}
}
</script>

<style scoped>

</style>