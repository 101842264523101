<template>
    <div>
        <!--<a @click="MailTemplate = template" class="is-block" v-for="template in MailTemplates" :key="template.id">{{template.name}}</a>-->
        <mail-template-editor/>
    </div>
</template>

<script>
import MailTemplateEditor from '../../Elements/MailTemplateEditor'

export default {
  components: {
    MailTemplateEditor
  },
  name: 'mails'
}
</script>

<style scoped>

</style>