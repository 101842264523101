<template>
  <b-taginput
      v-bind="$attrs"
      :allow-duplicates="false"
      :allow-new="false"
      :data="filteredTags"
      :value="val"
      autocomplete
      field="name"
      open-on-focus
      placeholder="Tag eingeben"
      @add="searchQuery = ''"
      @input="$emit('input', $event.map(e => e.name))"
      @typing="getFilteredTags">
  </b-taginput>
</template>

<script>
import TAGS from "../../graphql/Query/Tags.gql"

export default {
  name: "TagsInput",
  props: {
    value:Array,
    type: {
      type: String,
      default: () => null
    }
  },
  apollo: {
    Tags: {
      query: TAGS
    }
  },
  data() {
    return {
      Tags: [],
      searchQuery: ""
    }
  },
  computed: {
    val() {
      return Array.isArray(this.value) ? this.Tags.filter(t => t.type === this.type).filter(t => this.value.indexOf(t.name) !== -1) : []
    },
    filteredTags() {
      return this.Tags.filter(t => t.type === this.type).filter(t => t.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
    }
  },
  methods: {
    getFilteredTags(text) {
      this.searchQuery = text
    }
  }
}
</script>
