<template>
  <div class="box">
    <editable v-slot="{editing, mutate, edit, data, editData, loading, errors}" :data="member"
              :mutation="mutation"
              input-arg="member">
      <div v-if="member.isImported && editing" class="notification is-light is-danger">
        Achtung: Änderungen an Mitgliedern werden meist durch den Import aus eVewa überschrieben.
      </div>
      <div v-show="!editing" class="columns">
        <div class="column">
          <a v-if="$store.getters.hasPermission('manage members') && !editing" class="is-pulled-right is-hidden-tablet"
             @click="edit">
            <b-icon icon="pencil">Bearbeiten</b-icon>
          </a>
          <!--<b-field customClass="isText" label="Adresse">
            {{ member.salutation }} {{ (member.title ? member.title + ' ' : '') + member.fullName }}<br/>
            {{ member.street }}<br v-if="member.street"/>
            {{ member.zip }} {{ member.city }}
          </b-field>-->
          <b-field label="Briefanrede">
            {{ member.letterSalutation }}
          </b-field>
          <b-field :label="$tc('Mitgliedernummer')" customClass="isText">
            <mitgliedernummer :mitgliedernummer="member.mitgliedernummer"/>
          </b-field>
          <b-field :label="$tc('Verbandsstufe')" customClass="isText">
            <template v-if="member.department">
              <div class="control">
                <b-breadcrumb style="font-size:1em;">
                  <b-breadcrumb-item v-for="department in [...member.department.parents, member.department]"

                                     :to="{name: 'Department', params: {id: department.id}}"
                                     tag='router-link'>
                    {{ department.name }}
                    <!--<template v-if="department.level">({{department.level.nameShort}})</template>--->
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </template>
            <span v-else class="has-text-danger">
              {{ 'Keine ' + $tc('Verbandsstufe') }}
            </span>
          </b-field>
          <b-field label="Barrierefreiheit">
            <tags :id="member.id" :mutation="mutation"
                  :tags="member.tags"
                  type="accessibility"
            ></tags>
          </b-field>
          <b-field label="Unterbringung">
            <tags :id="member.id" :mutation="mutation"
                  :tags="member.tags"
                  type="accommodation"
            ></tags>
          </b-field>
        </div>
        <div class="column">
          <a v-if="$store.getters.hasPermission('manage members') && !editing" class="is-pulled-right is-hidden-mobile"
             @click="edit">
            <b-icon icon="pencil">Bearbeiten</b-icon>
          </a>

          <b-field v-if="$config.SHOW_DISTRICTS" label="Bezirk">
            {{ member.district ? member.district.name : '' }}
          </b-field>
          <b-field custom-class="isText" label="E-Mail">
            <a v-if="member.email" :href="'mailto:'+member.email" target="_blank"> {{ member.email }} </a>
            <template v-else>
              keine E-Mailadresse
            </template>
          </b-field>

          <phone-numbers :callable="member" :callable_mutation="mutation"
                         :can-edit="$store.getters.hasPermission('manage members')"
                         input_arg="member"></phone-numbers>

          <b-field label="Datenschutz">
            <template v-if="member.privacy_at">
              am {{ member.privacy_at | moment('DD.MM.YY') }} akzeptiert
            </template>
            <template v-else>nicht akzeptiert</template>
          </b-field>
          <b-field v-if="member.birth" label="Geburtsdatum">
            {{ member.birth | moment('DD.MM.YYYY') }} ({{ member.birth |moment('from', true) }})
          </b-field>
          <b-field :custom-class="member.state ? 'isText' : ''" label="Status">
            <span v-if="member.state" class="has-text-success">Aktiv</span>
            <div v-else class="tag is-danger is-medium">Inaktiv</div>
          </b-field>
        </div>
      </div>
      <div v-if="editing" class="columns">
        <div class="column">
          <b-field grouped>
            <div class="control" style="width: 7em;">
              <label class="label">Anrede</label>
              <gender-select v-model="editData.salutation"/>
            </div>

            <div class="control" style="width: 7em;">
              <label class="label">Titel</label>
              <b-input v-model="editData.title"/>
            </div>
          </b-field>
          <b-field grouped>
            <b-field :type="errors['member.firstName'] ? 'is-danger': ''" label="Vorname">
              <b-input v-model="editData.firstName"></b-input>
            </b-field>
            <b-field :type="errors['member.lastName'] ? 'is-danger': ''" expanded label="Nachname">
              <b-input v-model="editData.lastName"></b-input>
            </b-field>
          </b-field>
          <b-field :label="$tc('Mitgliedernummer')" :type="errors['member.mitgliedernummer'] ? 'is-danger': ''">
            <b-input v-model="editData.mitgliedernummer"
                     :maxlength="12"
                     class="nocounter" placeholder="901234">
            </b-input>
          </b-field>
          <b-field :label="$tc('Verbandstufe')">
            <department-select v-model="editData.association_department_id"></department-select>
          </b-field>

          <!--
          <b-field label="Straße">
            <b-input v-model="editData.street"></b-input>
          </b-field>
          <b-field grouped>
            <b-field label="Postleitzahl">
              <b-input v-model="editData.zip"/>
            </b-field>
            <b-field expanded label="Ort">
              <b-input v-model="editData.city"/>
            </b-field>
          </b-field>-->
          <!--<address-input :value="editData.contactAddress" has-phone/>
          <headline>Rechnungsaddresse</headline>
          <address-input :value="editData.billingAddress" has-phone has-name has-mail/>-->
        </div>

        <div class="column">
          <b-field v-if="$config.SHOW_DISTRICTS" label="Bezirk">
            <district-select v-model="editData.district_id"></district-select>
          </b-field>
          <b-field :message="getEmailErrorMessage(errors['member.email'])" :type="{'is-danger': errors['member.email']}"
                   label="E-Mail">
            <b-input v-model="editData.email"></b-input>
          </b-field>
          <b-field label="Status">
            <b-checkbox v-model="editData.state">Mitglied aktiviert</b-checkbox>
          </b-field>
        </div>

      </div>
      <div v-if="$store.getters.hasPermission('manage members')"
           class="has-text-right">
        <button v-if="editing"
                :class="{'is-loading' : loading}"
                class="button is-success mr-2"
                @click="() => { editData.state === false && data.state === true && data.memberUser !== null ? $buefy.dialog.confirm({title: 'Benutzeraccount wird gelöscht', message: 'Wenn sie den Status auf inaktiv stellen wird der zum Benutzeraccount für den  gelöscht.', confirmText: 'Ok, fortfahren.',onConfirm: mutate, type: 'is-danger'}) : mutate()}">
          Speichern
        </button>
        <button v-if="editing" class="button is-danger" @click="edit(false)">Abbrechen</button>
      </div>
    </editable>

  </div>
</template>

<script>
import Tags from "../Tags";
import Mitgliedernummer from "../Mitgliedernummer";
import DistrictSelect from "../DistrictSelect";
import MEMBER_MUTATION from "../../../graphql/Mutations/updateMember.gql"
import Editable from "../Editable";
import GenderSelect from "../GenderSelect";
import DepartmentSelect from "../DepartmentSelect";
import MemberDataStatusInput from "@/components/Elements/Member/MemberDataStatusInput";
import PhoneNumbers from "@/components/Elements/PhoneNumbers";
import {getEmailErrorMessage} from "@/utils/utils";

export default {
  name: "MemberOverview",
  components: {
    PhoneNumbers,
    MemberDataStatusInput,
    DepartmentSelect,
    GenderSelect,
    Editable,
    DistrictSelect,
    Mitgliedernummer,
    Tags
  },
  props: ["member"],
  methods: {
    getEmailErrorMessage
  },
  data() {
    return {
      mutation: MEMBER_MUTATION
    }
  }
}
</script>

<style>

.field .label.isText {
    margin-bottom: 0;
}
</style>