<template>
    <div>
        <button class="button" @click="modalActive = true">Termin hinzufügen</button>
        <b-modal :active.sync="modalActive" :has-modal-card="true">
            <div class="modal-card">
                <div class="modal-card-head">
                    <div class="modal-card-title">Termin hinzufügen</div>
                </div>
                <div class="modal-card-body">
                    <b-field label="Tagungsort">
                        <places-select :hide-inactive="true" v-model="newAppointment.place_id"></places-select>
                    </b-field>
                    <b-field label="Beginn">
                        <datetime-picker v-model="newAppointment.begin"></datetime-picker>
                    </b-field>
                    <b-field label="Ende">
                        <datetime-picker v-model="newAppointment.end"></datetime-picker>
                    </b-field>
                </div>
                <div class="modal-card-foot">
                    <button class="button is-success"
                            @click="addAppointment"
                            :class="{'is-loading' : isLoading}"
                    >
                        Hinzufügen
                    </button>
                    <button class="button is-danger" @click="modalActive=false">Abbrechen</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import DatetimePicker from "../DateTimePicker";
import PlacesSelect from "../PlacesSelect";
import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'

export default {
  name: "AddAppointmentButton",
  components: {PlacesSelect, DatetimePicker},
  props: ['seminar'],
  data() {
    return {
      modalActive: false,
      isLoading: false,
      newAppointment: {}
    }
  },
  methods: {
    addAppointment() {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_SEMINAR_MUTATION,
        variables: {
          id: this.seminar.id,
          addAppointment: this.newAppointment,
          includeAppointments: true,
          includeTodos: true
        }
      }).then(() => {
        this.isLoading = false
        this.modalActive = false
        this.newAppointment = {}
      })
    }
  }
}
</script>

<style scoped>

</style>
