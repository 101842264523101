<template>
  <apollo-query v-slot="{ result: { loading, error, data }, query }"
                :query="require('../../graphql/Query/AssociationDepartment.gql')"
                :variables="{id}">
    <base-page v-if="data && data.AssociationDepartment"
               :title="$tc('Verbandsstufe')+' - '+data.AssociationDepartment.name">
      <div class="card">
        <div class="card-content">
          <editable v-slot="{edit, editData, loading, editing, mutate, nestedErrors, errors}"
                    :data="data.AssociationDepartment"
                    :include-keys="['address', 'invoiceAddress']"
                    :mutation="UPDATE_DEPARTMENT_MUTATION" input-arg="input">
            <div v-if="!editing" class="columns">
              <div class="column">
                <b-field label="Name">
                  {{ editData.name }}
                </b-field>
                <b-field v-if="editData.nameExtra" label="Namenszusatz">
                  {{ editData.nameExtra }}
                </b-field>
                <phone-numbers :callable="editData" :callable_mutation="UPDATE_DEPARTMENT_MUTATION"
                               :can-edit="$store.getters.hasPermission('manage departments')"
                               input_arg="input"/>
              </div>
              <div class="column">
                <a v-if="$store.getters.hasPermission('manage departments')"
                   class="is-pulled-right"
                   @click="edit">
                  <b-icon icon="pencil">Bearbeiten</b-icon>
                </a>
                <b-field :label="$tc('Mitglieder der Verbandsstufe')">
                  <div class="button"
                       @click=" $store.commit('memberFilter/setFilters', {isMemberOfDepartments : [id]}); $router.push('/members')">
                    {{ $tc('Mitglieder suchen') }}
                  </div>
                </b-field>
              </div>
            </div>
            <div v-else class="columns">
              <div class="column">
                <b-field :type="{'is-danger': errors['associationDepartment.name']}" label="Name">
                  <b-input v-model="editData.name"/>
                </b-field>
                <b-field :type="{'is-danger': errors['associationDepartment.nameExtra']}" label="Namenszusatz">
                  <b-input v-model="editData.nameExtra"/>
                </b-field>
              </div>


            </div>
            <div v-if="$store.getters.hasPermission('manage departments')"
                 class="has-text-right">
              <button v-if="editing"
                      :class="{'is-loading' : loading}"
                      class="button is-success mr-2"
                      @click="mutate">
                Speichern
              </button>
              <button v-if="editing" class="button is-danger" @click="edit(false)">Abbrechen</button>
            </div>
          </editable>

        </div>
      </div>
      <addresses :addressable="data.AssociationDepartment" :addressable_mutation="UPDATE_DEPARTMENT_MUTATION"
                 :can-edit="$store.getters.hasPermission('manage departments')" class="mt-5" input_arg="input"/>

      <div class="headline">Kommentare</div>
      <comments :id="id" :comments="data.AssociationDepartment.comments"
                :mutation="UPDATE_DEPARTMENT_MUTATION"
                class="m-t-md"></comments>
    </base-page>
  </apollo-query>
</template>

<script>
import BasePage from "../Layout/BasePage";
import AddressInfo from "../Elements/AddressInfo";
import Editable from "../Elements/Editable";
import AddressInput from "../Elements/AddressInput";
import Headline from "../Layout/Headline";
import Comments from "@/components/Elements/Comments";
import UPDATE_DEPARTMENT_MUTATION from "@/graphql/Mutations/updateAssociationDepartment.gql"
import PhoneNumbers from "@/components/Elements/PhoneNumbers";
import Addresses from "@/components/Elements/Addresses.vue";

export default {
  name: "Department",
  components: {
    Addresses,
    PhoneNumbers, Comments, Headline, AddressInput, Editable, AddressInfo, BasePage
  },
  props: ['id'],
  data() {
    return {
      hasInvoiceAddress: null,
      UPDATE_DEPARTMENT_MUTATION
    }
  }
}
</script>

<style scoped>

</style>