import SEMINAR_QUERY from '../graphql/Query/Seminar.gql'
import TEMPLATES_QUERY from '../graphql/Query/Templates.gql'
import LECTURER_QUERY from '../graphql/Query/Lecturers.gql'
import NOTIFICATIONS_QUERY from '../graphql/Query/Notifications.gql'

export function deleteQuotaFromSeminar(store, seminarId, quotaId) {
  const data = store.readQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}})
  // Delete the Quota from our Seminar
  data.Seminar.quotas.splice(data.Seminar.quotas.findIndex((e) => e.id === quotaId), 1)
  // Write our data back to the cache.
  store.writeQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}, data})
}

export function deleteAppointmentFromSeminar(store, seminarId, appointmentId) {
  const data = store.readQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}})
  // Delete the Appointment from our Seminar
  data.Seminar.appointments.splice(data.Seminar.appointments.findIndex((e) => e.id === appointmentId), 1)
  // Write our data back to the cache.
  store.writeQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}, data})
}

export function deleteParticipationFromSeminar(store, seminarId, participationId) {
  const data = store.readQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}})
  // Delete the Participation from our Seminar
  data.Seminar.participations.splice(data.Seminar.participations.findIndex((e) => e.id === participationId), 1)
  // Write our data back to the cache.
  store.writeQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}, data})
}

export function deleteTodoFromSeminar(store, seminarId, todoId) {
  const data = store.readQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}})
  // Delete the Participation from our Seminar
  data.Seminar.todos.splice(data.Seminar.todos.findIndex((e) => e.id === todoId), 1)
  // Write our data back to the cache.
  store.writeQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}, data})
}

export function addParticipationToSeminar(store, seminarId, newParticipation) {
  const data = store.readQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}})
  // Add the new Participation to our Seminar
  data.Seminar.participations.push(newParticipation)
  // Write our data back to the cache.
  store.writeQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}, data})
}

export function addMailsToSeminarParticipations(store, seminarId, newMemberMails) {
  // console.log(newMemberMails)
  const data = store.readQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}})
  newMemberMails.forEach((mail) => {
    data.Seminar.participations.find(p => p.member.id === mail.member.id).mails.push(mail)
  })
  store.writeQuery({query: SEMINAR_QUERY, variables: {'id': seminarId}, data})
}

export function addTemplate(store, newTemplate) {
  const data = store.readQuery({query: TEMPLATES_QUERY})
  data.Templates.push(newTemplate)
  store.writeQuery({query: TEMPLATES_QUERY, data})
}

export function addLecturer(store, newLecturer) {
  const data = store.readQuery({query: LECTURER_QUERY})
  data.Lecturers.push(newLecturer)
  store.writeQuery({query: LECTURER_QUERY, data})
}

export function removeNotifications(store, ids) {
  const data = store.readQuery({query: NOTIFICATIONS_QUERY})
  data.Notifications = data.Notifications.filter(notification => ids.indexOf(notification.id) === -1)
  store.writeQuery({query: NOTIFICATIONS_QUERY, data})
}
