<template>
  <div>
    <div>
      <function-requirement-list-item :associationFunction="associationFunction"
                                      :requirement="requirement"
                                      class="m-b-sm" v-for="requirement in sortedRequirements"/>
      <div class="card" v-if="associationFunction.requirements.length < 1">
        <div class="card-content has-text-centered">Dieses Ausbildungspaket ist leer</div>
      </div>
    </div>
    <div class="columns mt-4 is-multiline is-variable is-3" grouped
         v-if="$store.getters.hasPermission('manage functions')">
      <b-field grouped class="column">
        <template-select :hide-inactive="true" v-model="addRequirementId"/>
        <b-select v-model="addIsRequired">
          <option :value="true" selected>
            Notwenig
          </option>
          <option :value="false">
            Optional
          </option>
        </b-select>
      </b-field>
      <div class="column is-narrow">
        <div :class="{'is-loading' : isAdding}" @click="addRequirement" class="button is-flex-mobile">Hinzufügen</div>
      </div>
    </div>
  </div>
</template>

<script>
import FunctionRequirementListItem from "./FunctionRequirementListItem";
import UPDATE_ASSOCIATION_FUNCTION_MUTATION from '../../../graphql/Mutations/updateAssociationFunction.gql'
import TemplateSelect from "../TemplateSelect";

export default {
  name: "FunctionRequirementList",
  components: {TemplateSelect, FunctionRequirementListItem},
  props: ['associationFunction'],
  data() {
    return {
      addRequirementId: null,
      addIsRequired: true,
      isAdding: false,
    }
  },
  computed: {
    sortedRequirements() {
      let sort = (a, b) => a.template.name.toLowerCase().localeCompare(b.template.name.toLowerCase());
      let required = this.associationFunction.requirements.filter(r => r.isRequired === true).slice().sort(sort);
      let optional = this.associationFunction.requirements.filter(r => r.isRequired === false).slice().sort(sort);
      return required.concat(optional)
    }
  },
  methods: {
    addRequirement() {
      if (!!this.addRequirementId) {
        this.isAdding = true
        this.$apollo.mutate({
          mutation: UPDATE_ASSOCIATION_FUNCTION_MUTATION,
          variables: {
            id: this.associationFunction.id,
            associationFunction: {
              requirements: {
                syncWithoutDetaching: [{
                  id: this.addRequirementId,
                  isRequired: this.addIsRequired
                }]
              }
            }
          }
        }).then(() => {
          this.isAdding = false
          this.addRequirementId = null
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
