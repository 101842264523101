<template>
  <div class="is-relative">
    <b-table
      :data="seminarPermission"
      class="card"
      narrowed
    >
        <b-table-column v-slot="props" label="Bezirk">
          {{props.row.name}}
        </b-table-column>
        <b-table-column v-slot="props" label="Teilnahmeliste" numeric>
          <b-switch :disabled="props.row.id === 'owner' || !seminar.canManageAll"
                    :value="props.row.canManageParticipations"
                    @input="updatePermission(props.row.district_id, 'canManageParticipations', $event)"
                    type="is-success"/>
        </b-table-column>
        <b-table-column v-slot="props" label="Alles verwalten" numeric>
          <b-switch :disabled="props.row.id === 'owner' || !seminar.canManageAll" :value="props.row.canManageAll"
                    @input="updatePermission(props.row.district_id, 'canManageAll', $event)"
                    type="is-success"/>
        </b-table-column>
    </b-table>
    <b-loading :active="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
  import DistrictSelect from "../DistrictSelect";
  import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'
  import GET_DISTRICTS_QUERY from '../../../graphql/Query/Districts.gql'

  export default {
    name: "DistrictPermissions",
    components: {DistrictSelect},
    props: ['seminar'],
    apollo: {
      Districts: {
        query: GET_DISTRICTS_QUERY
      }
    },
    data() {
      return {
        isLoading: false,
        Districts: []
      }
    },
    computed: {
      seminarPermission() {
        let firstDistrict = [{
          district_id: this.seminar.district.id,
          name: this.seminar.district.name,
          id: 'owner',
          canManageParticipations: true,
          canManageAll: true
        }]
        return [...firstDistrict, ...this.Districts.filter(d => d.id !== this.seminar.district.id).map(d => {
          let DistrictPermission = this.seminar.seminarPermissions.find(p => p.district.id === d.id)
          if (!DistrictPermission) {
            DistrictPermission = {
              id: null,
              canManageParticipations: false,
              canManageAll: false
            }
          }
          return {
            district_id: d.id,
            name: d.name,
            id: DistrictPermission.id,
            canManageParticipations: DistrictPermission.canManageParticipations,
            canManageAll: DistrictPermission.canManageAll
          }
        })]
      }
    },
    methods: {
      updatePermission(districtId, field, value) {
        this.isLoading = true
        let variables = {
          id: districtId
        }
        variables[field] = value
        this.$apollo.mutate({
          mutation: UPDATE_SEMINAR_MUTATION,
          variables: {
            includeSeminarPermissions: true,
            id: this.seminar.id,
            seminar: {seminarPermissions: {syncWithoutDetaching: [variables]}}
          }
        }).then(() => {
          this.isLoading = false
        })
      }
    }
  }
</script>

<style scoped>

</style>
