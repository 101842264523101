<template>
  <div class="editor">
    <headline class="is-borderless">
      <span :class="{'halfopacity': !seminar.pageEnabled}">Teilnahme-Infoseite</span>
      <template slot="right">
        <b-switch :value="seminar.pageEnabled"
                  :disabled="!seminar.canManageAll"
                  @input="setCheckbox('pageEnabled', $event)"
                  type="is-success"/>
        <a :href="seminar.pageUrl" target="_blank">
          <b-icon icon="open-in-new"/>
        </a>
      </template>
    </headline>
    <div v-if="!editing" class="content is-marginless notification" :class="{'halfopacity': !seminar.pageEnabled}">
      <!--<h3>{{seminar.template.name}}</h3>-->
      <div v-if="seminar.pageContent === null || seminar.pageContent === ''">
        <i>keine Beschreibung.. .</i>
      </div>
      <div v-else v-html="$options.filters.marked(seminar.pageContent)"></div>
    </div>
    <markdown-editor v-if="editing"
                     v-model="editingFields.pageContent">
    </markdown-editor>
    <br/>
    <template v-if="seminar.canManageAll">
      <button @click="edit" v-if="editing === false" class="button" :disabled="!seminar.pageEnabled">Bearbeiten</button>
      <template v-else>
        <button @click="saveseminar" :class="{'is-loading' : isLoading}"
                class=" is-success button mr-2">
          Speichern
        </button>
        <button @click="editing = false" class="is-danger button">Abbrechen</button>
      </template>
    </template>
  </div>
</template>

<script>
import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'
import MarkdownEditor from "../MarkdownEditor";
import Headline from "../../Layout/Headline";
import UPDATE_TEMPLATE_MUTATION from "../../../graphql/Mutations/updateTemplate.gql";

export default {
  name: "SeminarPageEditor",
  components: {Headline, MarkdownEditor},
  props: ['seminar'],
  data() {
    return {
      editing: false,
      editingFields: {
        pageContent: ''
      },
      isLoading: false
    }
  },
  methods: {
    edit() {
      this.editing = true
      this.editingFields.pageContent = this.seminar.pageContent
    },
    setCheckbox(field, value) {
      let variables = {}
      variables[field] = value
      this.$apollo.mutate({
        mutation: UPDATE_SEMINAR_MUTATION,
        variables: {
          id: this.seminar.id,
          seminar: variables
        }
      }).then(() => {

      })
    },
    saveseminar() {
      this.isLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_SEMINAR_MUTATION,
        variables: {
          id: this.seminar.id,
          seminar: {
            pageContent: this.editingFields.pageContent
          }
        }
      }).then(() => {
        this.isLoading = false
        this.editing = false

      })
    }
  }
}
</script>

<style scoped>
.editor * {
  transition: opacity .2s ease;
}

.halfopacity {
  opacity: 0.5;
}
</style>