<template>
    <div v-if="!isLoading" class="m-b-lg">
        <div class="headline">
            {{label}}
            <div class="is-pulled-right">
                <a @click="edit">
                    <b-icon icon="pencil"></b-icon>
                </a>
            </div>
        </div>
        <template v-if="!isEditing">
            <div v-if="Setting.value === ''" class="has-text-grey">nicht festgelegt</div>
            <div class="content notification" v-else-if="type === 'markdown'"
                 v-html="Setting.value !== null ? $options.filters.marked(Setting.value) : ''"></div>
            <div v-else>{{Setting.value}}</div>
        </template>
        <template v-else>
          <markdown-editor v-if="type === 'markdown'" v-model="editValue" class="m-b-md"></markdown-editor>
          <b-input v-else-if="type === 'textarea'" v-model="editValue" class="m-b-md" type="textarea"></b-input>
          <b-input v-else v-model="editValue" class="m-b-md"></b-input>

        </template>
        <template v-if="isEditing">
            <div class="has-text-right">
                <button class="button is-success" :class="{'is-loading': isSaving}" @click="save">
                    Änderungen speichern
                </button>
                <button class="button" @click="isEditing = false">Verwerfen</button>
            </div>
        </template>
    </div>
</template>

<script>
import UPDATE_SETTING_MUTATION from "../../graphql/Mutations/updateSetting.gql"
import GET_SETTING_QUERY from "../../graphql/Query/Setting.gql"
import MarkdownEditor from "./MarkdownEditor";

export default {
  name: "SettingEditor",
  components: {MarkdownEditor},
  props: ['id', 'type', 'label'],
  data() {
    return {
      isEditing: false,
      isSaving: false,
      isLoading: 0,
      editValue: '',
      Setting: {
        value: ''
      }
    }
  },
  apollo: {
    Setting: {
      query: GET_SETTING_QUERY,
      loadingKey: 'isLoading',
      variables() {
        return {
          id: this.id
        }
      }
    }
  },
  methods: {
    edit() {
      console.log(this.Setting.value)
      this.isEditing = true
      this.editValue = this.Setting.value
    },
    save() {
      this.isSaving = true
      this.$apollo.mutate({
        mutation: UPDATE_SETTING_MUTATION,
        variables: {
          id: this.id,
          setting: {
            value: this.editValue
          }
        }
      }).then(() => {
        this.isSaving = false
        this.isEditing = false
      })
    }
  }
}
</script>

<style scoped>
.content{
  overflow: auto;
}
</style>