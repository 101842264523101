<template>
  <div>
    <b-tooltip>
      <template v-slot:content>
        <div v-html="warningTooltip"/>
      </template>
      <b-icon :class="{'badge' : warningCount >1,'halfopcity' : warningTooltipColor === null}"
              :data-badge="warningCount"
              :type="warningTooltipColor"
              class="is-badge-small"
              icon="information-outline"
              style="vertical-align: bottom;"></b-icon>
    </b-tooltip>
    <b-tooltip :label="moveupTooltip">
      <b-icon :class="{'halfopcity' : !participation.moveupDate}" icon="account-switch"
              style="vertical-align: bottom;"></b-icon>
    </b-tooltip>
    <b-tooltip :label="inviteTooltip">
      <b-icon :class="{'halfopcity' : !participation.inviteDate}" icon="email-outline"
              style="vertical-align: bottom;"></b-icon>
    </b-tooltip>
    <b-tooltip :label="mailTooltip" style="z-index: 2;">
      <b-icon v-if="participation.member.email"
              :class="{'badge' : participation.mails.length > 1, 'halfopcity' : participation.mails.length < 1}"
              :data-badge="participation.mails.length"
              class="is-badge-small"
              icon="message-text-outline"
              @click="$emit('openMails', participation)"></b-icon>
    </b-tooltip>
  </div>
</template>

<script>
import moment from 'moment'

const maxSeminarsPerYear = 4;

export default {
  name: 'participation-status-icons',
  props: ['participation'],
  computed: {
    warningCount() {
      return (this.participation.missingFunctionCount !== 0 && this.participation.missingFunctionCount >= this.participation.requiredFunctionCount ? 1 : 0) +
          (this.participation.missingSeminarCount > 0) + (this.participation.parallelCount > 0) +
          (this.participation.completedCount > 0) + (this.participation.seminarsSameYearCount > maxSeminarsPerYear);
    },
    warningTooltipColor() {
      if (this.participation.missingFunctionCount !== 0 && this.participation.missingFunctionCount >= this.participation.requiredFunctionCount > 0) return "is-danger"
      if (this.participation.missingSeminarCount > 0) return "is-danger"
      if (this.participation.parallelCount > 0) return "is-danger"
      if (this.participation.completedCount > 0) return "is-warning"
      if (this.participation.seminarsSameYearCount > maxSeminarsPerYear) return "is-warning"
      return null
    },
    warningTooltip() {
      let message = "";
      if (this.participation.missingFunctionCount !== 0 && this.participation.missingFunctionCount >= this.participation.requiredFunctionCount) message += '<p>Erfüllt die Funktions-Voraussetzungen nicht</p>';
      if (this.participation.missingSeminarCount > 0) message += '<p>Erfüllt <b>' + this.participation.missingSeminarCount + '</b> Seminar-Voraussetzungen nicht</p>';
      if (this.participation.parallelCount > 0) message += "<p>Hat <b>" + this.participation.parallelCount + "</b> Veranstaltungen zur gleichen Zeit</p>";
      if (this.participation.completedCount > 0) message += "<p>Hat Seminar schon <b>" + this.participation.completedCount + "</b> mal abgeschlossen</p>";
      if (this.participation.seminarsSameYearCount > maxSeminarsPerYear) message += "<p>Hat <b>" + this.participation.seminarsSameYearCount + "</b> weitere Veranstaltungen im Kalenderjahr</p>";

      if (message === "") {
        return "Keine Warnungen"
      }
      return message
    },
    inviteTooltip() {
      if (!this.participation.inviteDate) {
        return 'Wurde noch nicht eingeladen.'
      } else {
        return 'Wurde eingeladen am ' + moment(this.participation.inviteDate).format('D. MMMM YYYY')
      }
    },
    moveupTooltip() {
      if (!this.participation.moveupDate) {
        return 'Ist nicht nachgerückt.'
      } else {
        return 'Ist nachgerückt am ' + moment(this.participation.moveupDate).format('D. MMMM YYYY')
      }
    },
    mailTooltip() {
      if (this.participation.mails.length < 1) {
        return 'Keine E-Mails verschickt'
      } else if (this.participation.mails.length === 1) {
        return 'Eine E-Mail verschickt'
      } else {
        return this.participation.mails.length + ' E-Mails verschickt'
      }
    }
  }
}
</script>

<style scoped>
.halfopcity {
  opacity: 0.2;
}
</style>