<template>
  <a @click="$store.commit('toggleShowNotifications')">
    <b-icon v-if="NotificationCount === 0" icon="bell"></b-icon>
    <div v-else class="unreadCount">
      {{ NotificationCount }}
    </div>
  </a>
</template>

<script>
import GET_NOTIFICATION_COUNT_QUERY from '../../graphql/Query/NotificationCount.gql'

export default {
  name: "NotificationsButton",
  data() {
    return {
      NotificationCount: 0
    }
  },
  apollo: {
    NotificationCount: {
      query: GET_NOTIFICATION_COUNT_QUERY,
      pollInterval: 15000
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: inherit;
}

.unreadCount {
  background-color: #ffa29e;
  color: black;
  border-radius: 50%;
  min-width: 2em;
  height: 2em;
  font-weight: bold;

  line-height: 1.9em;
  text-align: center;
}
</style>