<template>
  <base-page v-if="!$apollo.loading" :title="'Tagungsort - '+Place.name+' ('+Place.nameShort+')'">
    <div class="box">
      <editable v-slot="{edit, editData, loading, mutate, editing, nestedErrors, errors}" :data="Place"
                :include-keys="['address']"
                :mutation="UPDATE_PLACE_MUTATION"
                input-arg="place">
        <div v-if="!editing" class="columns is-multiline">
          <div class="column">
            <address-info :address="{...Place.address, name: Place.name}"></address-info>
            <phone-numbers :callable="Place" :callable_mutation="UPDATE_PLACE_MUTATION"
                           :can-edit="$store.getters.hasPermission('manage places')"
                           input_arg="place"></phone-numbers>
          </div>
          <div class="column">
            <b-field label="Beschreibung">
              <p :class="{'has-text-grey': !Place.description}">{{ Place.description || 'Noch keine beschreibung' }}</p>
            </b-field>
          </div>
        </div>
        <div v-else class="columns">
          <div class="column">
            <b-field :type="{ 'is-danger' : errors['place.name'] }" label="Name">
              <b-input v-model="editData.name" placeholder="Name"></b-input>
            </b-field>
            <b-field label="Kurzname">
              <b-input v-model="editData.nameShort" placeholder="Kurzname"></b-input>
            </b-field>
            <b-field label="Beschreibung">
              <b-input v-model="editData.description" placeholder="Beschreibung" type="textarea"></b-input>
            </b-field>
          </div>
        </div>
        <div v-if="$store.getters.hasPermission('manage places')" class="mt-5 has-text-right">
          <button v-if="editing === false" class="button" @click="edit()">Bearbeiten</button>
          <template v-else>
            <button :class="{'is-loading': loading}" class="button is-success mr-2" @click="mutate">Speichern
            </button>
            <button class="button is-danger" @click="edit(false)">Abbrechen</button>
          </template>
        </div>
      </editable>

    </div>
    <addresses :addressable="Place" :addressable_mutation="UPDATE_PLACE_MUTATION"
               :can-edit="$store.getters.hasPermission('manage places')"
               input_arg="place"/>
    <comments
        :id="id"
        :comments="Place.comments"
        :mutation="require('../../graphql/Mutations/updatePlace.gql')"
        title="Kommentare zum Tagungsort"/>
  </base-page>
</template>

<script>
import GET_PLACE_QUERY from '../../graphql/Query/Place.gql'
import UPDATE_PLACE_MUTATION from '../../graphql/Mutations/updatePlace.gql'
import BasePage from "../Layout/BasePage";
import Comments from "../Elements/Comments";
import AddressInfo from "../Elements/AddressInfo";
import Editable from "../Elements/Editable";
import Addresses from "@/components/Elements/Addresses.vue";
import PhoneNumbers from "@/components/Elements/PhoneNumbers";

export default {
  name: 'place-detail',
  props: ['id'],
  data() {
    return {
      Place: {},
      editing: false,
      editData: {},
      isLoading: 0,
      isLoadingEdit: false,
      UPDATE_PLACE_MUTATION
    }
  },
  apollo: {
    Place: {
      query: GET_PLACE_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
    }
  },
  components: {Addresses, PhoneNumbers, Editable, AddressInfo, Comments, BasePage}
}
</script>

<style scoped>

</style>