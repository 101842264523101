<template>
  <a :class="{'is-loading': isLoading}" :href="url" @click="warning" class="button is-flex-mobile" target="_blank"
     v-if="$store.getters.hasPermission('export members')">
    {{ $tc('Mitglied', true) }} Exportieren
  </a>
</template>

<script>
import {API_EXPORT_URL} from "@/config";
import {stringify} from "qs"

export default {
  name: "ExportMembers",
  props: ['filters', 'total'],
  data() {
    return {
      'isLoading': false
    }
  },
  methods: {
    warning(e) {
      if (this.total > 20000) {
        if (!confirm("Es können maximal 20000 Mitglieder auf einmal exportiert werden. Das Ergebnis Ihrer Suche ist größer, der Export wird daher unvollständig sein.")) {
          e.preventDefault();
        }
      }
    }
  },
  computed: {
    url() {
      return API_EXPORT_URL + '/members?' + stringify(this.filters, {arrayFormat: 'brackets'});
    }
  }
}
</script>

<style scoped>

</style>
