<template>
  <nav aria-label="main navigation" class="navbar is-navbar-color is-fixed-top" role="navigation">
    <div class="navbar-brand">
      <router-link to="/" class="navbar-item" style="font-weight:600; font-size:1.5rem;"
                   active-class="no-active-class">
        <img alt="EIS" class="eisLogo" src="../../assets/logo_eis.svg" style="height: 1.6rem;"/>
      </router-link>
      <div class="navbar-item is-hidden-desktop" style="margin-left:auto;">
        <notifications-button/>
      </div>
      <a class="navbar-item has-text-black-ter is-hidden-desktop m-r-sm"
         aria-label="menu" aria-expanded="false"
         @click="isExpanded = !isExpanded">
        <b-icon icon="menu" size="is"></b-icon>
      </a>
    </div>
    <div class="navbar-menu" :class="{'is-active' : isExpanded}" @click="isExpanded = false">
      <div class="navbar-start">
        <div class="navbar-item" @click.stop>
          <speed-search @select="isExpanded = false"/>
        </div>
        <router-link :to="{ path: '/dashboard' }" class="navbar-item is-tab">
          Dashboard
        </router-link>
        <router-link :to="{ path: '/seminars' }" class="navbar-item  is-tab">
          <div class="media">
            Seminare
          </div>
        </router-link>
        <router-link :to="{ path: '/members' }" class="navbar-item is-tab">
          {{ $tc('Mitglied', 2) }}
        </router-link>
        <div class="navbar-item is-hoverable has-dropdown">
          <div class="navbar-link is-arrowless">
            Weiteres
          </div>
          <div class="navbar-dropdown">
            <router-link :to="{ path: '/templates' }" class="navbar-item">
              Seminartypen
            </router-link>
            <router-link :to="{ path: '/lecturers' }" class="navbar-item">
              Seminarleitung
            </router-link>
            <router-link :to="{ path: '/places' }" class="navbar-item">
              Tagungsorte
            </router-link>
            <router-link :to="{ path: '/functions' }" class="navbar-item">
              Funktionen
            </router-link>
            <router-link :to="{ path: '/departments' }"
                         class="navbar-item">
              {{ $tc('Verbandsstufe', true) }}
            </router-link>
            <router-link :to="{ path: '/users' }" class="navbar-item"
                         v-if="$store.getters.hasPermission('manage users')">
              Benutzer
            </router-link>
            <router-link :to="{ path: '/settings' }" class="navbar-item"
                         v-if="$store.getters.hasPermission('manage system')">
              Systemeinstellungen
            </router-link>
          </div>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item is-hidden-touch">
          <notifications-button/>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <div class="navbar-item">
            <base-avatar :user="$store.state.auth.user" :size="32" class="is-hidden-touch"/>
            <span class="is-hidden-desktop">Benutzer</span>
          </div>
          <div class="navbar-dropdown is-right">
            <router-link :to="{ path: '/usersettings' }" class="navbar-item">
              Benutzereinstellungen
            </router-link>
            <a @click="$store.dispatch('logout')" class="navbar-item">
              Logout
            </a>
          </div>
        </div>
        <div class="navbar-dropdown">

        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Avatar from "vue-avatar/src/Avatar";
import BaseAvatar from "../Elements/BaseAvatar";
import NotificationsButton from "../Elements/NotificationsButton";
import SpeedSearch from "../Elements/SpeedSearch";

export default {
  name: "Navbar",
  data() {
    return {
      isExpanded: false
    }
  },
  components: {SpeedSearch, NotificationsButton, BaseAvatar, Avatar}
}
</script>

<style scoped lang="scss">
@import '../../assets/boxShadow';

.navbar {
  @include box_shadow(2);
  z-index: 1000;
}


@media only screen and (min-width: $desktop) {
  @for $i from 1 through 10 {
    .dw#{$i} {
      width: 0rem + $i;
    }
  }

}

</style>
