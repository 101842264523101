<template>
    <div>
        <b-dropdown :disabled="isParticipating !== -1 || !seminar.canManageParticipations" position="is-bottom-left">
            <button class="button is-small addParticipationButton"
                    :class="{
                        'is-success' : isParticipating === 2,
                        'is-warning' : isParticipating === 1,
                        'is-danger' : isParticipating === 0,
                        'is-loading' : isLoading
                     }"
                    type="button"
                    slot="trigger">
                <template v-if="isParticipating === -1">
                    <b-icon size="is-small" icon="account-plus"></b-icon>
                    <span>Hinzufügen</span>
                </template>
                <template v-if="isParticipating === 0">
                    <b-icon size="is-small" icon="account"></b-icon>
                    <span>Abgesagt</span>
                </template>
                <template v-if="isParticipating === 1">
                    <b-icon size="is-small" icon="timer-sand"></b-icon>
                    <span>Auf Warteliste</span>
                </template>
                <template v-if="isParticipating === 2">
                    <b-icon size="is-small" icon="account"></b-icon>
                    <span>Teilnahmeliste</span>
                </template>


                <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item @click="addParticipation('PARTICIPATING')" :disabled="!member.hasRequirements || seminar.participations.filter(p => p.status === 'PARTICIPATING').length >= seminar.quota">
                <div class="media">
                    <b-icon class="media-left" icon="account"></b-icon>
                    <div class="media-content">
                        <span>Zu Teilnahmeliste</span>
                    </div>
                </div>
            </b-dropdown-item>

            <b-dropdown-item @click="addParticipation('WAITING')">
                <div class="media">
                    <b-icon class="media-left" icon="timer-sand"></b-icon>
                    <div class="media-content">
                        <span>Zu Warteliste</span>
                    </div>
                </div>
            </b-dropdown-item>

        </b-dropdown>
    </div>
</template>

<script>
import CREATE_PARTICIPATION_MUTATION from '../../../graphql/Mutations/createParticipation.gql'
import _ from 'lodash'
import {addParticipationToSeminar} from '../../../cache/cacheUpdater'

export default {
  name: 'AddParticipationButton',
  props: ['seminar', 'member'],
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    addParticipation(status) {

      this.isLoading = true
      this.$apollo.mutate({
        mutation: CREATE_PARTICIPATION_MUTATION,
        variables: {
          participation: {
            seminar_id: this.seminar.id,
            member_id: this.member.id,
            status: status
          },
        },
        update: (store, {data: {createParticipation}}) => addParticipationToSeminar(store, this.seminar.id,
          createParticipation),
        loadingKey: 'isLoading'
      }).then(() => {

        this.isLoading = false
      })
    }
  },
  computed: {
    isParticipating() {
      if (!_.isArray(this.seminar.participations)) {
        return -1
      }
      if (
        this.seminar.participations.findIndex((p) => p.member.id === this.member.id && p.status === 'CANCELED') !== -1) {
        return 0
      }
      if (
        this.seminar.participations.findIndex((p) => p.member.id === this.member.id && p.status === 'WAITING') !== -1) {
        return 1
      }
      if (this.seminar.participations.findIndex((p) => p.member.id === this.member.id) !== -1) {
        return 2
      }
      return -1
    }
  }
}
</script>
<style scoped>
.addParticipationButton {
    width: 8rem;
}
</style>