<template>
  <base-page v-if="Member !== null" :title="$apollo.loading ? '': title">
    <template v-if="!$apollo.loading" v-slot:title>
      <span class="mr-2">{{ title }}</span>
      <tags :id="id" :mutation="MEMBER_MUTATION"
            :tags="Member.tags"
            @click="tag => {$store.commit('memberFilter/setFilters', {textSearch : tag}); $router.push({name: 'Members'})}">
        <member-data-status :member="Member"
                            @click="status => {$store.commit('memberFilter/setFilters', {textSearch : status}); $router.push({name: 'Members'})}"/>
      </tags>
    </template>
    <template v-slot:headerRight>
      <member-data-status-switch :member="Member"/>
    </template>
    <template v-if="!$apollo.loading">
      <div class="member">
        <div>
          <b-message v-if="!Member.state" title="Achtung" type="is-danger">
            {{ Member.fullName }} ist nicht mehr aktiv
          </b-message>
          <section>
            <member-overview :member="Member"/>
            <addresses :addressable="Member" :addressable_mutation="MEMBER_MUTATION"
                       :with-billing="$config.SHOW_BILLING_ADDRESSES"
                       :can-edit="$store.getters.hasPermission('manage members')"
                       input_arg="member"/>
            <div class="columns">
              <MemberUser :member="Member" class="column is-half"/>
              <MemberRegistration :registrationInfo="Member.registrationInfo" class="column is-half"/>
            </div>
            <member-functions :member="Member"/>
            <member-seminars :member="Member"/>
            <Comments :id="id"
                      :comments="Member.comments"
                      :logs="Member.audits"
                      :mutation="require('../../graphql/Mutations/updateMember.gql')"
                      class="mt-6"
                      title="Aktivität und Kommentare"
            />
          </section>
        </div>
      </div>
    </template>
    <b-loading v-else :active="$apollo.loading" :is-full-page="false"
               style="z-index: 10; min-height:800px;"></b-loading>
  </base-page>
  <not-found v-else></not-found>
</template>

<script>
import GET_MEMBER_QUERY from '../../graphql/Query/Member.gql'
import MEMBER_MUTATION from "@/graphql/Mutations/updateMember.gql"
import BasePage from '../Layout/BasePage'
import Tags from "../Elements/Tags";
import MemberOverview from "../Elements/Member/MemberOverview";
import MemberUser from "../Elements/Member/MemberUser";
import MemberSeminars from "../Elements/Member/MemberSeminars";
import MemberFunctions from "../Elements/Member/MemberFunctions";
import Comments from "../Elements/Comments";
import Headline from "../Layout/Headline";
import MemberRegistration from "../Elements/Member/MemberRegistration";
import MemberDataStatus from "@/components/Elements/Member/MemberDataStatus";
import NotFound from "@/components/404";
import MemberDataStatusSwitch from "@/components/Elements/MemberDataStatusSwitch";
import Addresses from "@/components/Elements/Addresses.vue";

export default {
  name: 'Member',
  components: {
    Addresses,
    MemberDataStatusSwitch,
    MemberDataStatus,
    NotFound,
    MemberRegistration,
    Headline,
    Comments,
    MemberFunctions,
    MemberSeminars,
    MemberUser,
    MemberOverview,
    Tags,
    BasePage,
  },
  props: ['id'],
  data() {
    return {
      Member: {},
      MEMBER_MUTATION
    }
  },
  apollo: {
    Member: {
      query: GET_MEMBER_QUERY,
      variables() {
        return {
          id: this.id
        }
      }
    }
  },
  computed: {
    title() {
      if (this.Member !== 0) {
        return this.Member.fullName
      } else {
        return 'Mitglied'
      }
    }
  }
}
</script>
