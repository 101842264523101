<template>
  <span :class="labelClass(todo)" class="tag" v-if="todo.deadline">{{todo.deadline | moment('dd. DD. MMM')}}</span>
  <span class="tag" v-else-if="!hideNoDeadline">Keine Deadline</span>
</template>

<script>
import moment from 'moment'

export default {
  name: "SeminarTodoDeadline",
  props: ['todo', 'hideNoDeadline'],
  methods: {
    labelClass(todo) {
      if (todo.done || !todo.deadline) {
        return ''
      }
      let diffDays = moment(todo.deadline).diff(moment(), 'days');
      if (diffDays < 0) {
        return 'is-danger'
      }
      if (diffDays < 3) {
        return 'is-warning'
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
