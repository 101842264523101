<template>
  <div>
    <b-field>
      <textarea :class="{'highPriority':highPriority}" class="input textarea commentarea"
                placeholder="Kommentieren..."
                ref="commentarea"
                @keydown.ctrl.enter="submit"
                @keydown.ctrl.space="highPriority = !highPriority"
                v-model="body"
      />
    </b-field>
    <button :class="{'is-loading' : loading}"
            @click="submit"
            class="button mr-2">
      Speichern
    </button>
    <button @click="highPriority = !highPriority" class="button">
      <b-icon :type="highPriority? 'is-danger' : 'is-grey'" icon="alert-circle"/>
    </button>
  </div>
</template>

<script>
import autosize from 'autosize'
import UPDATE_COMMENT_MUTATION from '../../graphql/Mutations/updateComment.gql'

export default {
  name: "CommentEditor",
  props: ['comment', 'mutation', 'id'],
  data() {
    return {
      body: this.comment ? this.comment.body : '',
      loading: false,
      highPriority: this.comment ? this.comment.highPriority : false,
    }
  },
  methods: {
    submit() {
      if (this.body === '') return
      if (!!this.comment) {
        this.update();
      } else {
        this.post();
      }
    },
    update() {
      this.loading = true
      this.$apollo.mutate({
        mutation: UPDATE_COMMENT_MUTATION,
        variables: {
          id: this.comment.id,
          comment: {
            body: this.body,
            highPriority: this.highPriority
          }
        }
      }).then(() => {
        this.loading = false
        this.$emit('updated')
      })
    },
    post() {
      this.loading = true
      this.$apollo.mutate({
        mutation: this.mutation,
        variables: {
          id: this.id,
          includeComments: true,
          addComment: {
            body: this.body,
            highPriority: this.highPriority
          }
        }
      }).then(() => {
        this.body = ''
        this.highPriority = false
        this.loading = false
        this.resize()
      })
    },
    resize() {
      this.$nextTick().then(() => {
        autosize(this.$refs.commentarea)
        autosize.update(this.$refs.commentarea)
      })
    }
  },
  mounted() {
    this.resize()
  }
}
</script>

<style lang="scss" scoped>
.commentarea {
  min-height: 2.7rem;
  max-height: none;
}

.highPriority {
  background-color: $red;
  color: white;
}

.highPriority::placeholder {
  color: rgba(255, 255, 255, 0.5)
}
</style>
