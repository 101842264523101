<template>
    <div v-if="!isLoading">
        <b-table
                :data="Lecturer.lectureships"
                narrowed
                striped
                :mobile-cards="false"
                :loading="!!isLoading"
                :default-sort="['template.name', 'asc']"
                class="table-has-frame space-bottom nowraptable"
        >
                <b-table-column v-slot="props" label="ID" field="id" sortable>
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column v-slot="props" label="Name" field="template.name" width="100%" sortable>
                    <router-link :to="{name: 'Template', params:{id: props.row.template.id}}">
                        {{ props.row.template.name }}
                    </router-link>
                </b-table-column>
                <b-table-column v-slot="props" label="Rolle" field="level" sortable>
                    <lecturer-level-button :lectureship="props.row"></lecturer-level-button>
                </b-table-column>
                <b-table-column v-slot="props" label="Status" field="state" sortable>
                    <state-dropdown :state="props.row.state" varName="lectureship" :id="props.row.id"
                                    :disabled="!$store.getters.hasPermission('manage lecturers')"
                                    :mutation="require('../../../graphql/Mutations/updateLectureship.gql')"></state-dropdown>
                </b-table-column>
            <template slot="empty">
                <empty-table>{{ Lecturer.member.fullName }} darf keine Seminare halten ..</empty-table>
            </template>
        </b-table>
        <div class="has-text-right" v-if="$store.getters.hasPermission('manage lecturers')">
            <template-select class="is-inline-block mr-3" v-model="addTemplateId" :hideInactive="true"/>
            <button class="button is-success"
                    @click="addTemplate"
                    :class="{'is-loading': isLoadingAddTemplate}"
                    :disabled="!addTemplateId">
                <b-icon icon="plus" size="is-small"></b-icon>
                <span>Hinzufügen</span>
            </button>
        </div>
    </div>
</template>

<script>
import EmptyTable from '../EmptyTable'
import GET_LECTURER_QUERY from '../../../graphql/Query/Lecturer.gql'
import UPDATE_LECTURER_MUTATION from '../../../graphql/Mutations/updateLecturer.gql'
import TemplateSelect from '../TemplateSelect'
import StateDropdown from "../StateDropdown";
import LecturerLevelButton from "./LecturerLevelButton";

export default {
  name: 'lecturer-templates',
  components: {
    LecturerLevelButton,
    StateDropdown,
    TemplateSelect,
    EmptyTable
  },
  data() {
    return {
      Lecturer: {},
      addTemplateId: null,
      isLoading: 0,
      isLoadingAddTemplate: false
    }
  },
  props: ['id'],
  methods: {
    addTemplate() {
      let id = this.addTemplateId
      this.addTemplateId = ''
      this.isLoadingAddTemplate = true

      this.$apollo.mutate({
        mutation: UPDATE_LECTURER_MUTATION,
        variables: {
          id: this.id,
          addLectureship: {template_id: id}
        }
      }).then(() => {

        this.isLoadingAddTemplate = false
      })
    }
  },
  apollo: {
    Lecturer: {
      query: GET_LECTURER_QUERY,
      variables() {
        return {
          id: this.id
        }
      },
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped>

</style>