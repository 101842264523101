<template>
    <b-message class="is-danger" v-if="highPriorityComments">
        <div class="infoCommentItem" v-for="comment in highPriorityComments">
            <div class="commentInfo">
                {{comment.author.fullName}}
              <span class="date"> &nbsp;({{ comment.created_at | moment('from', 'now')}})</span>
            </div>
            {{comment.body}}
        </div>
    </b-message>
</template>

<script>
export default {
  name: "WarningComments",
  props: ['comments'],
  computed: {
    highPriorityComments() {
      let comments = this.comments.filter(p => p.highPriority)
      return comments[0] ? comments.reverse() : false
    }
  }
}
</script>

<style scoped>
.commentInfo {
    text-align: left;
    font-size: 0.7em;
}
.infoCommentItem{
    margin-bottom:1.5rem;
}
.infoCommentItem:last-of-type{
    margin-bottom:0;
}
.date{
  opacity: 0.8;
  font-size:0.75em;
}
</style>
