<template>
    <markdown-editor :configs="config" :value="value" preview-class="content"
                     @update:modelValue="$emit('input', $event)"></markdown-editor>
</template>

<script>
import markdownEditor from './VueSimpleMde'
import SimpleMDE from 'easymde'

export default {
  name: "MarkdownEditor",
  props: ['value'],
  components: {markdownEditor},
  data() {
    return {
      config: {
        spellChecker: false,
        status: false,
        toolbar: [{
          name: "bold",
          action: SimpleMDE.toggleBold,
          className: "mdi mdi-format-bold mdi-24px",
          title: "Bold",
        },
          {
            name: "italic",
            action: SimpleMDE.toggleItalic,
            className: "mdi mdi-format-italic mdi-24px",
            title: "Italic",
          },
          {
            name: "strikethrough",
            action: SimpleMDE.toggleStrikethrough,
            className: "mdi mdi-24px mdi-format-strikethrough",
            title: "Strikethrough",
          },
          {
            name: "heading",
            action: SimpleMDE.toggleHeadingSmaller,
            className: "mdi mdi-24px mdi-format-title",
            title: "Toggle Heading",
          },
          {
            name: "quote",
            action: SimpleMDE.toggleBlockquote,
            className: "mdi mdi-24px mdi-format-quote-close",
            title: "Quote",
          }, '|',
          {
            name: "genaric list",
            action: SimpleMDE.toggleUnorderedList,
            className: "mdi mdi-24px mdi-format-list-bulleted",
            title: "Generic List",
          },
          {
            name: "numbered list",
            action: SimpleMDE.toggleOrderedList,
            className: "mdi mdi-24px mdi-format-list-numbered",
            title: "Numbered List",
          }, '|',
          {
            name: "link",
            action: SimpleMDE.drawLink,
            className: "mdi mdi-24px mdi-link",
            title: "Create Link",
          },
          {
            name: "table",
            action: SimpleMDE.drawTable,
            className: "mdi mdi-24px mdi-table",
            title: "Insert Table",
          },
          {
            name: "image",
            action: SimpleMDE.drawImage,
            className: "mdi mdi-24px mdi-image",
            title: "Insert Image",
          }, '|',
          {
            name: "preview",
            action: SimpleMDE.togglePreview,
            className: "mdi mdi-24px mdi-eye no-disable",
            title: "Toggle Preview",
          },
          /*{
            name: "side by side",
            action: SimpleMDE.toggleSideBySide,
            className: "mdi mdi-24px mdi-view-column",
            title: "Toggle Side by Side",
          },
          {
            name: "fullscreen",
            action: SimpleMDE.toggleFullScreen,
            className: "mdi mdi-24px mdi-fullscreen no-disable no-mobile",
            title: "Toggle Fullscreen no-disable no-mobile",
          },*/
          {
            name: "help",
            action: "https://simplemde.com/markdown-guide",
            className: "mdi mdi-24px mdi-help",
            title: "Markdown Guide",
          }
        ],
      }
    }
  }
}
</script>

<style scoped>
</style>
