<template>
  <div>

    <div class="columns is-multiline">
      <div class="column">
        <b-input v-model="filters.searchQuery" placeholder="Name suchen"></b-input>
      </div>
      <div class="column">
        <template-select v-model="filters.hasTemplateId"></template-select>
      </div>
      <div class="column is-narrow">
        <b-checkbox v-model="filters.hideOffline" class="mt-2">Inaktive ausblenden</b-checkbox>
      </div>
    </div>
    <listable :filter-defaults="{
      searchQuery: '',
      hideOffline: true,
      hasTemplateId: null
}" :filter-function="filterFunction"
              :query="require('../../../graphql/Query/Lecturers.gql')" name="Lecturers">

      <template v-slot:default="{filters}">
        <b-table-column v-slot="props">
          <router-link :to="{ name: 'Lecturer', params: { id: props.row.id }}" class="is-black-link">
            <b-icon icon="clipboard-account" size=""></b-icon>
          </router-link>
        </b-table-column>

        <b-table-column v-slot="props" field="member.firstName" label="Vorname" sortable>
          <router-link :to="{ name: 'Lecturer', params: { id: props.row.id }}">
            {{ props.row.member.firstName }}
          </router-link>
        </b-table-column>
        <b-table-column v-slot="props" field="member.lastName" label="Nachname" sortable style="width:100%;">
          <router-link :to="{ name: 'Lecturer', params: { id: props.row.id }}">
            {{ props.row.member.lastName }}
          </router-link>
        </b-table-column>

        <b-table-column v-slot="props" field="member.mitgliedernummer" label="Mgl. Nummer" sortable>
          <router-link :to="{ name: 'Member', params: { id: props.row.member.id }}">
            {{ props.row.member.mitgliedernummer }}
          </router-link>
        </b-table-column>
        <!--<b-table-column v-slot="props" field="member.district.name" label="Bezirk" sortable>
            {{ props.row.member.district.name }}
        </b-table-column>-->

        <!--<b-table-column v-slot="props" field="phone" label="Tel." sortable>
            {{ props.row.member.phone }}
        </b-table-column>-->
        <b-table-column v-slot="props" field="member.email" label="E-Mail" sortable style="width:100%;">
          <a :href="'mailto:'+props.row.member.email">{{ props.row.member.email }}</a>
        </b-table-column>
        <b-table-column v-slot="props" label="Kommentare">
          <compact-comments :id="props.row.id"
                            :comments="props.row.comments"
                            :mutation="require('../../../graphql/Mutations/updateLecturer.gql')"
                            title="Kommentare zur SeminarleiterIn"></compact-comments>
        </b-table-column>
        <b-table-column v-slot="props" :custom-sort="sortstate"
                        field="state"
                        label="Status"
                        numeric
                        sortable>
          <state-dropdown :id="props.row.id" :disabled="!$store.getters.hasPermission('manage lecturers')"
                          :mutation="require('../../../graphql/Mutations/updateLecturer.gql')"
                          :state="props.row.state"
                          varName="lecturer"></state-dropdown>
        </b-table-column>
      </template>
      <div v-if="$store.getters.hasPermission('export lecturers')" slot="footer" class="has-text-right m-t-lg">
        <a :href="$config.API_EXPORT_URL + '/lecturers'" class="button" target="_blank">Seminarleitung Exportieren</a>
      </div>
    </listable>
  </div>
</template>

<script>
import OnOffState from '../OnOffState'
import CompactComments from '../compactComments'
import StateDropdown from "../StateDropdown";
import {sortstate} from "@/utils/utils";
import TemplateSelect from "../TemplateSelect";
import Listable from "../Listable";

export default {
  components: {
    Listable,
    TemplateSelect,
    StateDropdown,
    CompactComments,
    OnOffState
  },
  name: 'LecturerList',
  data() {
    return {
      filters: {
        searchQuery: '',
        hideOffline: true,
        hasTemplateId: null,
      }
    }
  },
  methods: {
    sortstate,
    filterFunction(data) {
      let lecturers = data.filter(l => this.filters.hideOffline ? l.state : true).filter(l => (l.member.firstName + ' ' + l.member.lastName).toLowerCase().search(this.filters.searchQuery.toLowerCase()) !== -1)
      if (!!this.filters.hasTemplateId) {
        lecturers = lecturers.filter(l => l.lectureships.find(ls => ls.state && ls.template.id === this.filters.hasTemplateId))
      }
      return lecturers
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
