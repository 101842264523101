function getCookieVal() {
  return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}

function post(url, data) {
  return fetch(url, {
    credentials: 'include',
    method: 'POST',
    body: data,
    headers: {
      'X-XSRF-TOKEN': getCookieVal()
    }
  })
}

export default {
  post
}