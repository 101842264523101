<template>
  <b-dropdown v-if="!disabled" v-model="status">
    <button :class="[currentStatus.class, {'is-loading': isLoading}]"
            class="button is-small participationStatus"
            slot="trigger">
      <b-icon :icon="currentStatus.icon" size="is-small"></b-icon>
      <span v-if="showname">
                    {{ currentStatus.name }}
            </span>
    </button>
    <b-dropdown-item
        :disabled="(key === 'PARTICIPATING' && ((participation.missingFunctionCount !== 0 && participation.missingFunctionCount >= participation.requiredFunctionCount) || seminar.participations.filter(p => p.status === 'PARTICIPATING').length >= seminar.quota)) || !seminar.canManageParticipations"
      :key="key"
      :value="key"
      @click="participationChange"
      v-for="(value, key) in states">
      <b-icon :icon="value.icon"
              size="is-small"></b-icon>
      {{ value.name }}
    </b-dropdown-item>
  </b-dropdown>
  <div :class="[currentStatus.class, {'is-loading': isLoading}]"
       class="button is-small participationStatus"
       v-else>
    <b-icon :icon="currentStatus.icon" size="is-small"></b-icon>
    <span v-if="showname">
                    {{ currentStatus.name }}
            </span>
  </div>
</template>

<script>
  import UPDATE_PARTICIPATION_MUTATION from '../../../graphql/Mutations/updateParticipation.gql'
  import {PARTICIPATION_STATUS} from "../../../constants";

  export default {
    name: 'participation-state',
    data() {
      return {
        isLoading: false,
        showname: true,
        status: this.participation.status,
        states: PARTICIPATION_STATUS
      }
    },
    props: ['participation', 'disabled', 'seminar'],
    methods: {
      participationChange() {
        if (this.status === 'CANCELED') {
          this.$buefy.dialog.prompt({
            message: 'Bitte einen <b>Absagegrund</b> eingeben.<br><small>z.B. langfristige Absage, kurzfristige Absage, No Show (nicht erschienen).</small>',
            inputAttrs: {
              placeholder: 'Absagegrund eingeben',
              maxlength: 300
            },
            title: 'Wirklich fortfahren?',
            confirmText: 'Absagen',
            type: 'is-warning',
            onConfirm: (Comment) => this.updateParticipation(Comment)
          })
        } else {
          this.updateParticipation()
        }
      },
      updateParticipation(Comment) {
        if (this.status === this.participation.status) return
        this.isLoading = 1
        let variables = {
          id: this.participation.id,
          participation: {
            status: this.status
          }
        }
        if (Comment) {
          variables.addComment = {body: Comment}
        }
        if (this.status === 'CANCELED') {
          variables.participation.cancelDate = (new Date()).toISOString()
        }
        if (this.participation.status === 'WAITING' && (this.status === 'PARTICIPATING' || this.status === 'COMPLETED')) {
          variables.participation.moveupDate = (new Date()).toISOString()
        }
        this.$apollo.mutate({
            mutation: UPDATE_PARTICIPATION_MUTATION,
            variables: variables
          }
        ).then(() => {
          this.isLoading = 0
        })
      }
    },
    computed: {
      currentStatus() {
        return this.states[this.participation.status]
      }
    }
    ,
    watch: {
      participation(participation) {
        this.status = participation.status
      }
    }
  }
</script>

<style scoped>
  .participationStatus {
    width: 6.5rem;
  }
</style>
