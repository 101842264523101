import _ from 'lodash'

let defaultValues = {
  showNotifications: false,
}

export default {
  state: _.cloneDeep(defaultValues),
  mutations: {
    toggleShowNotifications(state) {
      state.showNotifications = !state.showNotifications
    },
  },
}
