<template>
  <div class="card" style="padding:1rem;">
    <seminar-to-do :key="item.id" :seminar="seminar" :todo="item" v-for="item in openTodos"></seminar-to-do>
    <add-todo :seminar="seminar"/>
    <template v-if="doneTodos.length > 0">
      <hr style="margin: 1em 0;"/>
      <div @click="showDone = !showDone" class="field m-t-md has-text-grey">
        <label class="b-checkbox checkbox">
          <b-icon :icon="showDone ? 'chevron-up' : 'chevron-down'" style="width:1.25em; height: 1.25em;"/>
          <span class="control-label">{{doneTodos.length}} abgehakte Todos</span>
        </label>
      </div>
      <seminar-to-do :key="item.id" :seminar="seminar" :todo="item" v-for="item in doneTodos"
                     v-if="showDone"></seminar-to-do>
    </template>
  </div>
</template>

<script>
import UPDATE_SEMINAR_MUTATION from '../../../graphql/Mutations/updateSeminar.gql'
import SeminarToDo from "./SeminarToDo";
import AddTodo from "./AddTodoButton";

export default {
  name: "SeminarToDos",
  components: {AddTodo, SeminarToDo},
  props: ['seminar'],
  data() {
    return {
      showDone: false
    }
  },
  computed: {
    openTodos() {
      return this.seminar.todos.filter(t => !t.done).slice().sort((a, b) => {
        if (a.deadline == b.deadline) return 0;
        if (!b.deadline) return -1;
        if (!a.deadline) return 1;
        return new Date(a.deadline) - new Date(b.deadline);
      })
    },
    doneTodos() {
      return this.seminar.todos.filter(t => t.done)
    },
    hasPermission() {
      return this.seminar.canManageAll
    }
  },

  methods: {}
}
</script>

<style scoped>

</style>
