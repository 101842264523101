<template>
  <div>
    <div class="headline is-borderless">Aktuelle Seminare</div>
    <b-table :data="current"
             :default-sort="['created_at', 'desc']"
             class="table-has-frame nowraptable"
             narrowed
             striped>
      <template slot="header"
                slot-scope="props">
        <b-tooltip
            :active="!!props.column.meta"
            :label="props.column.meta !== false ? props.column.meta : ''"
            dashed
        > {{ props.column.label }}
        </b-tooltip>
      </template>
      <b-table-column v-slot="props" field="status" label="Status" sortable width="9em">
        <participation-status-label :status="props.row.status"/>
      </b-table-column>
      <b-table-column v-slot="props" field="seminar.template.name" label="Seminar"
                      sortable style="width:auto;">
        <router-link :to="{ name: 'Seminar', params: { id: props.row.seminar.id }}">
          {{ props.row.seminar.template.name }}
        </router-link>
      </b-table-column>
      <!--<b-table-column field="created_at" label="Erstellung"
                      meta="Wann die Seminarteilnahme erstellt wurde (Unabhängig TL/WL)." sortable
                      style="width:7em;" v-slot="props">
        {{props.row.created_at | moment('DD.MM.YYYY')}}
      </b-table-column>-->
      <b-table-column v-slot="props" field="seminar.end"
                      label="Vorr. Abschl."
                      meta="Wann das Seminar vorrausichtilich abgeschlossen wird (Ende letzter Seminartermin)." sortable
                      width="7em">
        {{ props.row.seminar.end | moment('DD.MM.YYYY') }}
      </b-table-column>
      <b-table-column v-slot="props" field="created_at" label="Kommentar" numeric sortable width="17em">
        <compact-comments :id="props.row.id" :comments="props.row.comments"
                          :mutation="require('../../../graphql/Mutations/updateParticipation.gql')"
                          title="Kommentare zur Teilnahme"/>
      </b-table-column>
      <template slot="empty">
        <empty-table>Keine ausstehenden Teilnahmen</empty-table>
      </template>
    </b-table>
    <div class="headline is-borderless">Abgeschlossene Seminare</div>
    <b-table :data="completed"
             :default-sort="['created_at', 'desc']"
             :mobile-cards="false"
             class="table-has-frame"
             narrowed
             striped>
      <b-table-column v-slot="props" field="status" label="Status" sortable width="9em">
        <participation-status-label :class="{'lowOpacity' : props.row.type === 'certificate'}"
                                    status="COMPLETED"/>
      </b-table-column>
      <b-table-column v-slot="props" field="name" label="Seminar" sortable>
        <router-link v-if="props.row.type === 'participation'"
                     :to="{ name: 'Seminar', params: { id: props.row.seminar_id }}">
          {{ props.row.name }}
        </router-link>
        <router-link v-else :to="{name: 'Template', params: {id: props.row.template_id}}">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="created_at" label="Abschluss" sortable width="7em">
        {{ props.row.created_at | moment('DD.MM.YYYY') }}
      </b-table-column>
      <b-table-column v-slot="props" :width="member.certificates.length >0  ? '14.5em' : '17em'" field="created_at"
                      label="Kommentare" numeric
                      sortable>
        <compact-comments :id="props.row.id" :comments="props.row.comments"
                          :mutation="props.row.type === 'participation' ? require('../../../graphql/Mutations/updateParticipation.gql') : require('../../../graphql/Mutations/updateCertificate.gql')"
                          title="Kommentare zur Teilnahme"/>
      </b-table-column>
      <b-table-column v-slot="props" :visible="member.certificates.length >0 " width="2.5em">
        <remove-certificate
            v-if="props.row.type === 'certificate' && $store.getters.hasPermission('manage members')"
            :certificateId="props.row.id"
            :memberId="member.id"
        />
      </b-table-column>
      <template slot="empty">
        <empty-table>Keine abgeschlossenen Seminare</empty-table>
      </template>
    </b-table>
    <add-certificate v-if="member.state && $store.getters.hasPermission('manage members')" :member="member"
                     class="m-t-md"/>
    <div class="headline is-borderless">Nicht abgeschlossene Seminare</div>
    <b-table :data="notCompleted"
             :default-sort="['created_at', 'desc']"
             :mobile-cards="false"
             class="table-has-frame nowraptable"
             narrowed
             striped>
      <template slot="header"
                slot-scope="props">
        <b-tooltip
            :active="!!props.column.meta"
            :label="props.column.meta !== false ? props.column.meta : ''"
            dashed
        > {{ props.column.label }}
        </b-tooltip>
      </template>
      <b-table-column v-slot="props" field="status" label="Status" sortable width="9em">
        <participation-status-label :status="props.row.status"/>
      </b-table-column>
      <b-table-column v-slot="props" field="seminar.template.name" label="Seminar" sortable>
        <router-link :to="{ name: 'Seminar', params: { id: props.row.seminar.id }}">
                                        <span
                                            :class="props.row.seminar.status === 'CANCELED' ? ['strikethrough', 'has-text-danger']  : []">
                                        {{ props.row.seminar.template.name }}
                                        </span>
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="seminar.end" label="Vorr. Abschl."
                      meta="Wann das Seminar abgeschlossen worden wäre (Ende letzter Seminartermin)."
                      sortable width="7em">
        {{ props.row.seminar.end | moment('DD.MM.YYYY') }}
      </b-table-column>
      <b-table-column v-slot="props" field="created_at" label="Kommentare" numeric sortable width="17em">
        <compact-comments :id="props.row.id" :comments="props.row.comments"
                          :mutation="require('../../../graphql/Mutations/updateParticipation.gql')"
                          title="Kommentare zur Teilnahme"/>
      </b-table-column>

      <template slot="empty">
        <empty-table>Keine nicht abgeschlossenen Seminare</empty-table>
      </template>
    </b-table>
  </div>
</template>

<script>
import ParticipationStatusLabel from "../Seminar/ParticipationStatusLabel";
import CompactComments from "../compactComments";
import EmptyTable from "../EmptyTable";
import AddCertificate from "../AddCertificate";
import RemoveCertificate from "../removeCertificate";

export default {
  name: "MemberSeminars",
  components: {RemoveCertificate, AddCertificate, EmptyTable, CompactComments, ParticipationStatusLabel},
  props: ['member'],
  computed: {
    current() {
      if (!this.member.participations) return null;
      return this.member.participations.filter(p => p.seminar.status !== 'COMPLETED' && p.seminar.status !== 'CLEARED' && p.seminar.status !== 'CANCELED' && p.status !== 'CANCELED')
    },
    completed() {
      if (!this.member.participations) return [];
      let participations = this.member.participations.filter(p => p.status === 'PARTICIPATING' && (p.seminar.status === 'COMPLETED' || p.seminar.status === 'CLEARED')).map(p => ({
        id: p.id,
        name: p.seminar.template.name,
        type: 'participation',
        seminar_id: p.seminar.id,
        created_at: p.seminar.end,
        comments: p.comments
      }))
      let certificates = this.member.certificates.map(c => ({
        id: c.id,
        name: c.template.name,
        type: 'certificate',
        template_id: c.template.id,
        created_at: c.created_at,
        comments: c.comments
      }))

      return participations.concat(certificates)
    },
    notCompleted() {
      if (!this.member.participations) return [];
      return this.member.participations.filter(p => p.seminar.status === 'CANCELED' || p.status === 'CANCELED' || (p.status === 'WAITING' && (p.seminar.status === 'COMPLETED' || p.seminar.status === 'CLEARED')))
    },
  }
}
</script>

<style scoped>

.lowOpacity {
  opacity: 0.5;
}

</style>
