<template>
    <div>
        <button class="button" @click="modalActive = true">
            <b-icon icon="map-marker-plus" size="is-small"/>
            <span>User anlegen</span>
        </button>
        <b-modal :active.sync="modalActive" :has-modal-card="true">
            <div class="modal-card">
                <div class="modal-card-head">
                    <div class="modal-card-title">User hinzufügen</div>
                </div>
                <div class="modal-card-body">
                  <b-message type="is-danger" v-if="errors.serverError">Ein Fehler ist aufgetreten</b-message>
                    <b-field label="Vorname" :type="errors.firstName ? 'is-danger': ''">
                        <b-input placeholder="Vorname" v-model="newUser.firstName"></b-input>
                    </b-field>
                    <b-field label="Nachname" :type="errors.lastName ? 'is-danger': ''">
                        <b-input placeholder="Nachname" v-model="newUser.lastName"></b-input>
                    </b-field>
                    <b-field :type="errors.district_id ? 'is-danger': ''" label="Bezirk" v-if="$config.SHOW_DISTRICTS">
                        <district-select v-model="newUser.district_id"/>
                    </b-field>
                    <b-field label="Rolle" :type="errors.role_id ? 'is-danger': ''">
                        <role-select v-model="role_id"/>
                    </b-field>
                    <b-field label="E-Mail" :type="errors.email ? 'is-danger': ''">
                        <b-input placeholder="E-Mail-Adresse" type="email" v-model="newUser.email"></b-input>
                    </b-field>
                    <b-field label="Passwort" :type="errors.password ? 'is-danger': ''"
                             message="Mindestens 8 Zeichen lang">
                        <b-input type="password" v-model="newUser.password" placeholder="Passwort eingeben"></b-input>
                    </b-field>
                    <b-field label="Passwort wiederholen" :type="errors.passwordRepeat ? 'is-danger': ''">
                        <b-input type="password" v-model="repeatpassword" placeholder="Passwort eingeben"></b-input>
                    </b-field>
                </div>
                <div class="modal-card-foot">
                    <button class="button is-success"
                            @click="addUser"
                            :class="{'is-loading' : isLoading}"
                    >
                        Hinzufügen
                    </button>
                    <button class="button is-danger" @click="modalActive=false">Abbrechen</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import CREATE_USER_MUTATION from '../../graphql/Mutations/createUser.gql'
import GET_USERS_QUERY from '../../graphql/Query/Users.gql'
import DistrictSelect from "./DistrictSelect";
import RoleSelect from "./RoleSelect";
import BInput from "buefy/src/components/input/Input";

export default {
  name: "AddUserButton",
  components: {BInput, RoleSelect, DistrictSelect},
  data() {
    return {
      modalActive: false,
      isLoading: false,
      newUser: {
          firstName: '',
          lastName: '',
          email: '',
          district_id: this.$config.SHOW_DISTRICTS ? null : 1,

          state: true
      },
      role_id: null,
      repeatpassword: '',
      errors: {}
    }
  },
  methods: {
    validate() {
      this.errors = {}
      this.errors.firstName = !this.newUser.firstName
      this.errors.lastName = !this.newUser.lastName
      this.errors.email = !this.newUser.email
      this.errors.district_id = !this.newUser.district_id
      this.errors.role_id = !this.role_id
      this.errors.password = !this.newUser.password || this.newUser.password.length < 8
      this.errors.passwordRepeat = this.newUser.password !== this.repeatpassword

      for (let i in this.errors) {
        if (this.errors[i] === true) {
          return false
        }
      }
      return true
    },
    addUser() {
      if (!this.validate()) return
      this.isLoading = true
      this.newUser.roles = {sync: [this.role_id]}
      this.$apollo.mutate({
        mutation: CREATE_USER_MUTATION,
        refetchQueries: [{query: GET_USERS_QUERY}],
        variables: {
          user: this.newUser
        }
      }).then(({data}) => {
        this.newUser
        this.isLoading = false
        this.$router.push({name: 'User', params: {id: data.createUser.id}})
      }).catch(() => {
        this.errors.serverError = true
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
