<template>
  <b-select v-if="!multiple" v-model="computedValue" v-bind="$attrs" class="selectField"
            expanded
            placeholder="Seminartyp auswählen">
    <option :value="null" class="has-text-grey-light">Nichts auswählen</option>
    <option v-for="template in filteredTemplates" :value="template.id">{{ template.name }}</option>
  </b-select>
  <b-taginput
      v-else
      v-model="computedValue"
      v-bind="$attrs"
      :allowNew="false"
      :data="filteredTemplates"
      :open-on-focus="true"
      autocomplete
      field="name"
      @typing="input => this.searchQuery = input"
  ></b-taginput>
</template>

<script>
import TEMPLATES_SELECT_QUERY from '../../graphql/Query/Templates.gql'
import {filterObjects} from '../../utils/utils'

export default {
  name: 'template-select',
  data() {
    return {
      Templates: [],
      searchQuery: ''
    }
  },
  methods: {
    select(option) {
      if (option !== null) {
        this.$emit('input', option.id)
      } else {
        this.$emit('input', null)
      }
    }
  },
  apollo: {
    Templates: {
      query: TEMPLATES_SELECT_QUERY,
      variables() {
        return {
          canCreate: !!this.canCreate,
          selectField: true
        }
      }
    }
  },
  props: {value: [String, Array], hideInactive: {type: Boolean, default: true}, canCreate: Boolean, multiple: Boolean},
  computed: {
    computedValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    filteredTemplates() {
      let filtered = this.Templates.slice().sort(function (a, b) {
        return a.name.localeCompare(b.name);
      }).filter(t => t.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) !== -1)
      return !this.hideInactive ? filtered : filtered.filter((t) => t.state === true)
    }
  }
}
</script>

<style scoped>
.selectField:not(.is-expanded) {
  max-width: 18rem;
}
</style>
