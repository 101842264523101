import { render, staticRenderFns } from "./LecturerList.vue?vue&type=template&id=7b393331&scoped=true&"
import script from "./LecturerList.vue?vue&type=script&lang=js&"
export * from "./LecturerList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b393331",
  null
  
)

export default component.exports