<template>
  <filters v-slot="{filters, isActive, isActiveWith, resetFilter, isActiveWithout}"
           class="memberfilter" name="member" @input="$emit('input',$event) ">
    <div class="columns is-variable is-1 is-multiline is-mobile">
      <div class="column is-full-mobile">
        <clearable-input v-model="filters.textSearch"
                         :placeholder="'Schnellsuche (Name, Vorname, ' + $tc('Mitgliedernummer')+' oder Tags)'"></clearable-input>
      </div>
      <div class="column  is-narrow-tablet is-narrow-desktop is-half-mobile">
        <div class="button is-block-mobile" @click="resetFilter">
          <b-icon icon="filter-remove" size="is-small"/>
          <span>Zurücksetzen</span></div>
      </div>
      <div class="column is-narrow-tablet is-narrow-desktop  is-half-mobile">
        <div @click="filterOpen = filterOpen !== true" data-badge="" class="button is-badge-small is-flex-mobile"
             :class="{'badge': isActiveWithout(['textSearch'])}">
          <span>Weitere Filter</span>
          <b-icon icon="menu-down" v-if="!filterOpen"></b-icon>
          <b-icon icon="menu-up" v-else></b-icon>
        </div>
      </div>
    </div>
    <div v-show="filterOpen" :class="{'mb-5':filterOpen}" class="columns">
      <div class="column">
        <b-field :label="$tc('Mitgliedernummer')" expanded>
          <p class="control is-expanded">
            <b-field>
              <b-input v-model="filters.mitgliedernummer" :placeholder="$tc('Mitgliedernummer')"></b-input>
            </b-field>
          </p>
          <p class="control nowrap">
            <button class="button" @click="() => filters.mitgliedernummer = 'ANY'">Beliebig</button>
            <button class="button" @click="() => filters.mitgliedernummer = 'NONE'">Keine</button>
          </p>
        </b-field>
        <b-field expanded label="Vorname">
          <b-input v-model="filters.firstName" placeholder="Vorname eingeben"></b-input>
        </b-field>
        <b-field expanded label="Nachname">
          <b-input v-model="filters.lastName" placeholder="Nachname eingeben"></b-input>
        </b-field>
        <b-field expanded label="E-Mail">
          <b-input v-model="filters.email" placeholder="z.b max@web.de"></b-input>
        </b-field>
        <b-field expanded label="Telefon">
          <b-input v-model="filters.phone" placeholder="z.b 069123456"></b-input>
        </b-field>
        <b-field expanded label="Straße">
          <b-input v-model="filters.street" placeholder="z.b Superstraße 12"></b-input>
        </b-field>
        <b-field grouped>
          <b-field expanded label="Stadt">
            <b-input v-model="filters.city" placeholder="z.b Frankfurt"></b-input>
          </b-field>
          <b-field expanded label="Postleitzahl">
            <b-input v-model="filters.zip" placeholder="z.b 12345"></b-input>
          </b-field>
        </b-field>
        <b-field grouped label="Postleitzahl Bereich">
          <b-field expanded label="von" label-position="inside">
            <b-input v-model="filters.zipAbove" placeholder="z.b 60000"></b-input>
          </b-field>
          <b-field expanded label="bis" label-position="inside">
            <b-input v-model="filters.zipBelow" placeholder="z.b 69999"></b-input>
          </b-field>
        </b-field>
        <b-field :label="$tc('Ist Mitglied in Verbandsstufe')">
          <b-select v-model="filters.isMemberOfAnyDepartment" expanded>
            <option :value="null">Egal</option>
            <option :value="true">Ja</option>
            <option :value="false">Nein</option>
          </b-select>
        </b-field>
        <b-field :label="$tc('Ist Mitglied in Verbandsstufe')+' (spezifisch)'">
          <department-select v-model="filters.isMemberOfDepartments" multiple/>
        </b-field>

        <b-field group-multiline grouped>
          <b-field expanded label="Datenschutz">
            <b-select v-model="filters.agreedToPrivacy" expanded>
              <option :value="null">Egal</option>
              <option :value="true">Akzeptiert</option>
              <option :value="false">Nicht akzeptiert</option>
            </b-select>
          </b-field>

          <b-field expanded label="Newsletter">
            <b-select v-model="filters.wantsNewsletter" expanded>
              <option :value="null">Egal</option>
              <option :value="true">Ja</option>
              <option :value="false">Nein</option>
            </b-select>
          </b-field>
          <b-field v-if="$config.SHOW_DISTRICTS" expanded label="Bezirke">
            <district-select v-model="filters.districtIn" multiple/>
          </b-field>
          <b-field expanded label="VdK Mitglied">
            <b-select v-model="filters.isVdk" expanded>
              <option :value="null">Egal</option>
              <option :value="true">Ja</option>
              <option :value="false">Nein</option>
            </b-select>
          </b-field>
        </b-field>
      </div>
      <div class="column">
        <b-field label="Benötigt Seminare für Ausbildungspaket">
          <b-field grouped>
            <template-select v-model="filters.needsTemplates" class="is-expanded" maxtags="4"
                             multiple placeholder="Benötigt Seminare für Ausbildungspaket..."/>
            <p class="control">
              <b-select v-model="filters.needsTemplatesIsRequired">
                <option :value="null">Beides</option>
                <option :value="true">Notwendig</option>
                <option :value="false" class="lowOpa">Optional</option>
              </b-select>
            </p>
          </b-field>
        </b-field>
        <b-field class="m-b-lg" grouped>
          <b-field label="Hat Funktionen">
            <b-select v-model="filters.hasAnyFunction">
              <option :value="null">Egal</option>
              <option :value="true">Ja</option>
              <option :value="false">Nein</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field expanded label="Hat Funktionen (spezifisch)">
          <function-select v-model="filters.hasAssociationFunctions"
                           :placeholder="$tc('Mitglied') + ' hat Funktionen...'"
                           multiple/>
        </b-field>
        <b-field :label="'Hat Funktion in ' + $tc('Verbandsstufe')">
          <department-select v-model="filters.hasFunctionInDepartment"/>
        </b-field>
        <b-field expanded label="Hat Seminare abgeschlossen">
          <template-select v-model="filters.hasParticipations" maxtags="4" multiple
                           placeholder="Hat Seminare abgeschlossen..."/>
        </b-field>
        <b-field expanded label="Hat Seminare nicht abgeschlossen">
          <template-select v-model="filters.doesntHaveParticipations" maxtags="4"
                           multiple placeholder="Hat Seminare nicht abgeschlossen..."/>
        </b-field>
        <b-field class="m-b-lg" grouped>
          <b-field label="Hat aktuelle Seminare">
            <b-select v-model="filters.hasCurrentParticipations">
              <option :value="null">Egal</option>
              <option :value="true">Ja</option>
              <option :value="false">Nein</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field group-multiline grouped label="Hat Seminar im Zeitraum">
          <div class="control is-expanded">
            <b-field label="von" label-position="inside">
              <date-picker v-model="filters.hasParticipationAfter"/>
            </b-field>
          </div>
          <div class="control is-expanded">
            <b-field label="bis" label-position="inside">
              <date-picker v-model="filters.hasParticipationBefore"/>
            </b-field>
          </div>
        </b-field>
        <b-field class="m-t" expanded label="Tags (oder verknüpft)">
          <tags-input v-model="filters.tags"/>
        </b-field>
        <b-field group-multiline grouped label="Registriert im Zeitraum">
          <div class="control is-expanded">
            <b-field label="von" label-position="inside">
              <date-picker v-model="filters.registeredAfter"/>
            </b-field>
          </div>
          <div class="control is-expanded">
            <b-field label="bis" label-position="inside">
              <date-picker v-model="filters.registeredBefore"/>
            </b-field>
          </div>
        </b-field>

      </div>
    </div>
  </filters>
</template>

<script>
import DepartmentSelect from "./DepartmentSelect";
import TagsInput from "./TagsInput";
import ClearableInput from "./ClearableInput";
import Filters from "./Filters";
import DistrictSelect from "./DistrictSelect";
import TemplateSelect from "./TemplateSelect";
import FunctionSelect from "./FunctionSelect";
import DatePicker from "@/components/Elements/DatePicker";

export default {
  name: 'member-filter',
  components: {
    DatePicker,
    FunctionSelect, TemplateSelect, DistrictSelect, Filters, ClearableInput, TagsInput, DepartmentSelect
  },
  data() {
    return {
      filterOpen: false,
    }
  }
}
</script>
<style>
.memberfilter .taginput .help.counter {
  display: none;
}
</style>
