<template>
    <div>
      <!--<a class="">Serienbrief</a><br/>-->
      <a :href="genLink('participations')" target="_blank">Teilnahmeliste (xlsx)</a><br/>
      <a :href="genLink('attendance')" target="_blank">Anwesenheitsliste (pdf)</a><br/>
      <a :href="genLink('accommodation')" target="_blank">Übernachtungsliste (pdf)</a><br/>
      <a :href="genLink('certificates')" target="_blank">Teilnahmebestätigungen (pdf)</a><br/>
    </div>
</template>

<script>
import {API_EXPORT_URL} from "@/config";

export default {
  name: "SeminarDownloads",
  props: ['seminar'],
  methods: {
    genLink(type) {
      return API_EXPORT_URL + '/seminar/' + this.seminar.id + '/' + type
    }
  }
}
</script>

<style scoped>

</style>