<template>
    <div class="">
        <MembersList v-if="!isLoading" :addParticipation="true" :seminar="Seminar"/>
    </div>
</template>

<script>
import MembersList from '../MemberList'
import GET_SEMINAR_QUERY from '../../../graphql/Query/Seminar.gql'

export default {
  name: 'add-participant-to-seminar',
  components: {MembersList},
  props: ['id'],
  data () {
    return {
      Seminar: {},
      isLoading: 0
    }
  },
  apollo: {
    Seminar: {
      query: GET_SEMINAR_QUERY,
      variables () {
        return {
          id: this.id,
        }
      },
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped>

</style>