<template>
  <div class="field is-grouped is-grouped-multiline tagsField">
    <slot/>
    <div v-for="tag in allTags" :key="tag.otherTags+'_'+tag.id"
         class="control">
      <div class="tags is-inline-flex">
        <div :class="[size]" class="tag is-light is-link">
          <a v-if="$listeners.click" @click="$emit('click', tag.name)">{{ tag.name }}</a>
          <template v-else>{{ tag.name }}</template>
          <button v-if="!tag.other" class="delete is-small" @click="detach(tag.name)"></button>
        </div>
      </div>
    </div>
    <div v-if="!opened" class="control">
      <div :class="[size]" class="tag is-light  is-link is-pointer-curser" @click="open">
        <b-icon icon="plus" size="is-small"/>
        <span v-if="allTags.length === 0">Tag hinzufügen</span>
      </div>
    </div>
    <div v-else class="control">
      <div class="tags has-addons">
        <span ref="dropdown"
              :class="[size, {'is-active': dropdownOpen && filteredTags.length > 0}]"
              class="tag is-link is-light  dropdown">
          <input ref="input" v-model="body" class="dropdown-trigger" maxlength="24" placeholder="Tag eingeben"
                 @focus="dropdownOpen = true; hasFocus = true;" @focusout="hasFocus = false;" @keydown.enter="attach"/>
            <div
                ref="dropdown"
                class="dropdown-menu">
                <div class="dropdown-content">
                  <a v-for="tag in filteredTags" :key="tag.id" class="dropdown-item" @click="select(tag.name)">
                    {{ tag.name }}
                  </a>
                </div>
            </div>
        </span>
        <a :class="[size]" class="tag" @click="attach">
          <b-icon icon="check" size="is-small"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import TAGS from "../../graphql/Query/Tags.gql"
import TagsInput from "./TagsInput";
import {createAbsoluteElement, isCustomElement, removeElement} from "buefy/src/utils/helpers";

export default {
  name: "Tags",
  components: {TagsInput},
  props: {
    mutation: Object,
    id: String,
    tags: Array,
    size: {
      type: String,
      default: () => 'is-medium'
    },
    type: {
      type: String,
      default: () => null
    },
    otherTags: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dropdownOpen: false,
      opened: false,
      hasFocus: false,
      body: "",
      Tags: []
    }
  },
  apollo: {
    Tags: {
      query: TAGS
    }
  },
  computed: {
    allTags() {
      let tags = this.tags.map(t => ({type: null, ...t, other: false}))
      let otherTags = this.otherTags.map(t => ({type: null, ...t, other: true}))
      return [...otherTags, ...tags].filter(t => t.type === this.type)
    },
    whiteList() {
      const whiteList = []
      //whiteList.push(this.$refs.input)
      whiteList.push(this.$refs.dropdown)
      // Add all chidren from dropdown
      if (this.$refs.dropdown !== undefined) {
        const children = this.$refs.dropdown.querySelectorAll('*')
        for (const child of children) {
          whiteList.push(child)
        }
      }
      return whiteList
    },
    filteredTags() {
      return this.Tags.filter(t => t.name.toLowerCase().indexOf(this.body.toLowerCase()) !== -1)
          .filter(t => t.type === this.type)
          .filter(t => this.allTags.findIndex(at => at.id === t.id) === -1)
    }
  },
  methods: {
    open() {
      this.opened = true;
      this.$nextTick(() => {
        this.$refs.input.focus();
      })

    },
    select(name) {
      console.log("bla")
      this.body = name;
      this.attach();
    },
    detach(tag) {
      this.$apollo.mutate({
        mutation: this.mutation,
        variables: {
          id: this.id,
          detachTag: {
            name: tag,
            type: this.type
          }
        }
      })
    },
    attach() {
      if (this.body === '') {
        this.opened = false;
        return
      }
      this.$apollo.mutate({
        mutation: this.mutation,
        refetchQueries: [{query: TAGS}],
        variables: {
          id: this.id,
          attachTag: {
            name: this.body,
            type: this.type
          }
        }
      }).then(() => {
        this.opened = false
        this.body = ""
      })
    },
    /**
     * Close dropdown if clicked outside.
     */
    clickedOutside(event) {
      const target = isCustomElement(this) ? event.composedPath()[0] : event.target
      if (!this.hasFocus && this.whiteList.indexOf(target) < 0) this.dropdownOpen = false
    },
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside)
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside)
    }
  },
}
</script>

<style lang="scss" scoped>
.addTag {
  cursor: pointer;
  opacity: .2;
  transition: opacity 0.1s ease;

  &:hover {
    opacity: .8;
  }
}

.tag {
  input {
    width: 100px;
    background: transparent;
    border: 0;
    color: black;

    &:focus, &:active {
      border: 0;
      outline: 0;
    }
  }

  .dropdown-content {
    max-height: 300px;
    max-width: 300px;
    overflow-y: auto;
  }
}


.tagsField {
  display: inline-flex;
  vertical-align: text-bottom;
}

</style>

