<template>
    <div v-if="!isLoading">
        <div class="box" style="border-radius:0;">
            <div class="columns">
                <div class="column content">
                    <label class="label">Adresse</label>
                    <div v-if="Lecturer.member.address" class="lines">
                        {{ Lecturer.member.address.toLines }}
                    </div>
                </div>
                <div class="column content">
                    <label class="label">Mitglied</label>
                    <router-link :to="{ name: 'Member', params: { id: Lecturer.member.id }}">
                        {{Lecturer.member.fullName}}
                        <template v-if="Lecturer.member.mitgliedernummer" >({{ Lecturer.member.mitgliedernummer }})</template>
                    </router-link>
                    <br/><br/>
                    <template v-if="Lecturer.member.email">
                        <label class="label">E-Mail</label>
                        {{ Lecturer.member.email }}<br/><br/>
                    </template>
                    <template v-if="Lecturer.member.phone">
                        <label class="label">Telefon</label>
                        {{ Lecturer.member.phone }}
                    </template>
                </div>
            </div>
        </div>
        <comments
                  :comments="Lecturer.comments"
                  :id="id"
                  title="Kommentare zur SeminarleiterIn"
                  :mutation="require('../../../graphql/Mutations/updateLecturer.gql')"/>
    </div>
</template>

<script>
import GET_LECTURER_QUERY from '../../../graphql/Query/Lecturer.gql'
import Comments from '../Comments'

export default {
  components: {Comments},
  name: 'lecturer-overview',
  data () {
    return {
      Lecturer: {},
      isLoading: 0
    }
  },
  props: ['id'],
  apollo: {
    Lecturer: {
      query: GET_LECTURER_QUERY,
      variables () {
        return {
          id: this.id
        }
      },
      loadingKey: 'isLoading'
    }
  }
}
</script>

<style scoped>

</style>