<template>
  <div class="basepage">
    <div v-if="title ||$slots.title" :class="{'basepage-title': hasBorder}" class="m-b-md">
      <div class="columns is-mobile is-multiline">
        <div class="column">
          <h1 class="is-inline-block">
            <slot name="title">{{ title }}</slot>
          </h1>
          <slot name="headerAdd"></slot>
        </div>
        <div v-if="$slots.headerRight" class="column is-narrow is-full-mobile">
          <slot name="headerRight"></slot>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'base-page',
  props: ['title', 'hasBorder']
}
</script>

<style scoped>
h1 {
  font-size: 2rem;
}

.basepage-title {
  border-bottom: 1px solid #e1e4e8;
  padding-bottom: 0.75rem;
}

.basepage {
  position: relative;
}
</style>
