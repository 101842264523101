import _ from "lodash";

export function boldText(text) {
  let bold = /\*\*(\S(.*?\S)?)\*\*/gm;
  let html = text.replace(bold, '<strong>$1</strong>');
  return html;
}

export function filterTags(type = 'default') {

}

export function filterObjects(objects, key, keyword) {
  return objects.filter(object => {
    return object[key].toLowerCase().includes(keyword.toLowerCase())
  })
}

export function addOrRemove(array, value) {
  var index = array.indexOf(value)

  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
}

export function sortstate(a, b, d) {
  if (a.state && !b.state) {
    return d ? -1 : 1
  }
  if (!a.state && b.state) {
    return d ? 1 : -1
  }
  if (a.state === b.state) {
    return 0
  }
}

export var unpaginatedMethods = {
  sortstate,
  pageChange(page) {
    console.log(page)
    this.$router.replace({name: this.$route.name, query: {...this.$route.query, page: page}})
  },
  sortChange(field, order) {
    this.$router.replace({name: this.$route.name, query: {sField: field, sOrder: order}})
  },
  queryChange(field, value) {
    let query = {...this.$route.query}
    query[field] = value
    this.$router.replace({name: this.$route.name, query: query})
  }
}

export function startDownload(filename, content) {
  let element = document.createElement('a');
  //console.log(content)
  element.setAttribute('href', 'data:text/plain;base64,' + content);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}


export function omit(key, obj) {
  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      obj = omit(key[i], obj)
    }
    return obj
  }
  const {[key]: omitted, ...rest} = obj;
  return rest;
}

export function include(key, obj) {
  let newObj = {}
  if (Array.isArray(key)) {
    for (let i = 0; i < key.length; i++) {
      newObj[key[i]] = obj[key[i]]
    }
  } else {
    newObj[key] = obj[key]
  }
  return newObj
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(object, base);
}

export function different(object, base) {
  return Object.keys(difference(object, base)).length !== 0 || Object.keys(base).length !== Object.keys(object).length
}

export function filterObject(obj, key) {
  for (let i in obj) {
    if (!obj.hasOwnProperty(i)) continue;
    if (i == key) {
      delete obj[key];
    } else if (typeof obj[i] == 'object') {
      filterObject(obj[i], key);
    }
  }
  return obj;
}

import {Observable} from 'apollo-link';

export const promiseToObservable = promise =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      err => subscriber.error(err)
    );
    return subscriber; // this line can removed, as per next comment
  });


export function getEmailErrorMessage(err) {
  if (Array.isArray(err)) err = err[0]
  console.log("e", err)
  if (!err) return null
  if (err === 'validation.unique') return 'Die E-Mail Adresse wird bereits verwendet.'
  if (err === 'validation.email') return 'Die E-Mail Addresse ist ungültig'
  return null
}