<template>
  <base-page title="Benutzer">
    <add-user-button slot="headerRight" v-if="$store.getters.hasPermission('manage users')"></add-user-button>
    <b-input v-model="searchQuery" class="mb-4" placeholder="Benutzer durchsuchen..."></b-input>
    <listable
        :filter-function="users => users.filter(u => u.fullName.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)"
        :query="require('../../graphql/Query/Users.gql')" has-text-search
        icon="account"
        name="Users"
        text-search-field="fullName">
      <b-table-column v-slot="props" field="fullName" label="Name" sortable width="100%">
        <router-link :to="{ name: 'User', params: { id: props.row.id }}">
          {{ props.row.fullName }}
        </router-link>
      </b-table-column>
      <b-table-column v-if="$config.SHOW_DISTRICTS" v-slot="props" field="district.name" label="Bezirk" sortable>
        {{ props.row.district.name }}
      </b-table-column>
      <b-table-column v-slot="props" field="role.name" label="Rolle" sortable style="width:100%;">
        <span v-if="props.row.role">{{ props.row.role.name }}</span>
        <span v-else>Keine</span>
      </b-table-column>
      <b-table-column v-slot="props" label="Kommentare">
        <compact-comments :comments="props.row.comments"
                          :id="props.row.id"
                          title="Kommentare zum Tagungsort"
                          :mutation="require('../../graphql/Mutations/updateUser.gql')"></compact-comments>
      </b-table-column>

      <b-table-column v-slot="props" field="state"
                      label="Status"
                      sortable
                      numeric
                      :custom-sort="sortstate">
        <state-dropdown :state="props.row.state" varName="user" :id="props.row.id"
                        :disabled="!$store.getters.hasPermission('manage users')"
                        :mutation="require('../../graphql/Mutations/updateUser.gql')"></state-dropdown>
      </b-table-column>
    </listable>
  </base-page>
</template>

<script>
import BasePage from "../Layout/BasePage";
import {sortstate} from "../../utils/utils";
import StateDropdown from "../Elements/StateDropdown";
import CompactComments from "../Elements/compactComments";
import AddUserButton from "../Elements/AddUserButton";
import Listable from "../Elements/Listable";

export default {
  name: 'Users',
  components: {Listable, AddUserButton, CompactComments, StateDropdown, BasePage},
  data() {
    return {
      searchQuery: ''
    }
  },
  methods: {
    sortstate
  }
}
</script>

<style scoped>

</style>