import {ApolloLink} from "apollo-link";
import {HttpLink} from 'apollo-link-http'
import {defaultDataIdFromObject, InMemoryCache} from 'apollo-cache-inmemory'
import {ApolloClient} from 'apollo-client/index'
import VueApollo from 'vue-apollo'
import Vue from 'vue'
import {onError} from 'apollo-link-error'
import {API_GRAPHQL_ENDPOINT, API_INIT_CSRF} from './config'
import {promiseToObservable} from "@/utils/utils";

Vue.use(VueApollo)

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: API_GRAPHQL_ENDPOINT,
  credentials: 'include'
})

const setXSRF = (operation) => {
  let cookieVal = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
  operation.setContext({
    headers: {
      'X-XSRF-TOKEN': cookieVal
    }
  });
}

const xsrfLink = new ApolloLink((operation, forward) => {
  // Use the setContext method to set the X-CSRF-TOKEN header back.
  setXSRF(operation)
  // Call the next link in the middleware chain.
  return forward(operation);
});

const errorLink = onError(({networkError, graphQLErrors, forward, operation}) => {
    // Refresh page on unauthenticated
    if (!!graphQLErrors && !!graphQLErrors[0] && graphQLErrors[0].message === "Unauthenticated.") {
      location.reload()
    }

    // Refresh token on mismatch
    if (!!networkError && !!networkError.result && !!networkError.result.message && networkError.result.message === "CSRF token mismatch.") {
      const promise = fetch(API_INIT_CSRF, {credentials: 'include', mode: 'cors'})
      return promiseToObservable(promise).flatMap(() => {
        setXSRF(operation);
        return forward(operation);
      });
    }
  }
)

const cache = new InMemoryCache({
  typePolicies: {
    SystemSettings: {keyFields: []}
  },
  possibleTypes: {
    TemplateRequirementSubject: ["Template", "AssociationFunction"],
  },
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case 'SystemSettings':
        return 'SystemSettings';
      default:
        return defaultDataIdFromObject(responseObject);
    }
  }
})

// Create the apollo client
export default new ApolloClient({
  link: xsrfLink.concat(errorLink).concat(httpLink),
  cache,
  connectToDevTools: true
})