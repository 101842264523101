<template>
  <div
      v-if="address !== null && Object.entries(address).filter(([k, v]) => !!v && k !== '__typename').length > 0">
    <b-field>
      <template v-slot:label>
        <div class="is-flex">
          {{ labels.address || 'Adresse' }}
          <b-taglist class="is-block ml-2" style="max-width: 80%">
            <b-tag v-if="address.isContact" class="is-marginless mr-2" type="is-primary">Kontakt</b-tag>
            <b-tag v-if="address.isBilling && withBilling" class="is-marginless mr-2" type="is-info">Rechnung</b-tag>
          </b-taglist>
        </div>
      </template>
      <div class="address" title="In Zwischenablage kopieren" @click="copyToClipboard">
        <div ref="address">
          <p>{{ address.name || address.computedName }}</p>
          <p v-if="address.addition1">{{ address.addition1 }}</p>
          <p v-if="address.addition2">{{ address.addition2 }}</p>
          <p v-if="address.street">{{ address.street }}</p>
          <p v-if="address.zip">{{ address.zip }} {{ address.city }}</p>
        </div>
      </div>
    </b-field>
    <b-field v-if="address.phone" :label="labels.phone || 'Telefon'">
      <a :href="'tel:'+address.phone">{{ address.phone }}</a>
    </b-field>
    <b-field v-if="address.email" :label="labels.email || 'E-Mail'">
      <a :href="'mailto:'+address.email" target="_blank">{{ address.email }}</a>
    </b-field>
    <b-field v-if="address.url" :label="labels.url || 'URL'">
      <a :href="address.url" target="_blank">{{ address.url }}</a>
    </b-field>
  </div>
</template>
<script>
export default {
  name: "AddressInfo",
  props: {
    address: Object, labels: {
      type: Object, default: () => ({})
    },
    withBilling: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.$refs.address.innerText.replace(/\n{2,}\s*/g, '\n')).then(() => {
        this.$buefy.toast.open({message: 'Adresse in Zwischenablage kopiert'})
      })
    }
  }
}
</script>

<style scoped>
.address {
  cursor: pointer;
  position: relative;
}
</style>