<template>
    <div>
        <seminar-status-steps :seminar="seminar"></seminar-status-steps>
    </div>
</template>

<script>
import SeminarStatusSteps from "./SeminarStatusSteps";

export default {
  name: 'seminar-overview',
  components: {SeminarStatusSteps},
  props: ['seminar'],
}
</script>

<style scoped>

</style>