<template>
    <b-dropdown position="is-bottom-left" class="autoWidthDropdown" :disabled="disabled">
        <button class="button is-small" slot="trigger" style="width:4.7em;" :class="{'is-loading': isLoading}">
            <b-icon icon="checkbox-blank-circle" size="is-small" :type="currentState.color"/>
            <span>{{currentState.text}}</span>
            <b-icon icon="menu-down" size="is-small"></b-icon>
        </button>
        <b-dropdown-item @click="updateState(true)">
            <b-icon :icon="state ? 'check' : ''" size="is-small">
            </b-icon>
            <b-icon icon="checkbox-blank-circle" size="is-small" type="is-success"/>
            <span>an</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateState(false)">
            <b-icon :icon="!state ? 'check' : ''" size="is-small">
            </b-icon>
            <b-icon icon="checkbox-blank-circle" size="is-small" type="is-danger"/>
            <span>aus</span>
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
  name: "StateDropdown",
  data() {
    return {
      isLoading: false
    }
  },
  props: ['state', 'varName', 'mutation', 'id', 'disabled'],
  computed: {
    currentState() {
      return this.state ? {color: 'is-success', text: 'an'} : {color: 'is-danger', text: 'aus'}
    }
  },
  methods: {
    updateState(state) {
      if (state === this.state) return
      this.isLoading = true
      let variables = {id: this.id}
      variables[this.varName] = {state: state}
      this.$apollo.mutate({
        mutation: this.mutation,
        variables: variables
      }).then(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>
.autoWidthDropdown .dropdown-menu {
    min-width: 0;
}
</style>