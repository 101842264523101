<template>
    <div>
        <div class="headline">Vorlage bearbeiten/erstellen</div>
        <b-field label="Name der Vorlage">
            <b-input v-model="template.name"></b-input>
        </b-field>
        <b-field label="Betreff">
            <input class="input" v-if="!showPreview" placeholder="Betreff" v-model="template.subject"/>
            <input class="input" v-else size="is-small" :value="preview.subject" disabled/>

        </b-field>
        <b-field label="Vorlage">
            <textarea class="textarea" v-model="template.body" ref="mailarea"
                      v-if="!this.showPreview"
                      placeholder="E-Mail Vorlage verfassen..."></textarea>

            <textarea v-else class="textarea" :value="preview.body" ref="preview" disabled></textarea>
        </b-field>
        <div class="m-t-md">
            <div class="columns">
                <div class="column">
                    <button class="button is-success" @click="saveOld" :class="{'is-loading' : isSavingOld}"
                            v-if="!this.isNew">
                        Speichern
                    </button>
                    <button class="button ml-3" @click="saveNew" :class="{'is-loading' : isSavingNew, 'is-success' : isNew}">
                        <template v-if="isNew">Erstellen</template>
                        <template v-else>als Kopie speichern</template>
                    </button>
                    <button class="button ml-3" v-if="!this.isNew" @click="this.loadNew">Neue Vorlage</button>
                    <b-icon type="is-success" class="savedIcon" icon="check" v-if="isSaved"></b-icon>
                </div>
                <div class="column has-text-right is-narrow">
                    <b-dropdown position="is-bottom-left" class="has-text-left" :disabled="showPreview">
                        <button class="button" slot="trigger" @click="keepfocus">
                            <span>Platzhalter einfügen</span>
                            <b-icon icon="menu-down" size="is-small"></b-icon>
                        </button>
                        <b-dropdown-item v-for="placeholder in placeholders"
                                         :key="placeholder.placeholder"
                                         @click="insertText(placeholder.placeholder)">
                            {{placeholder.placeholder}}<br/>
                            <span class="replacement"><small>{{placeholder.replacement}}</small></span>
                        </b-dropdown-item>
                    </b-dropdown>
                    <button class="button ml-3" @click="showPreview = !showPreview">Vorschau</button>
                </div>
            </div>
        </div>
        <mail-template-list class="m-t-xl m-b-xl" @load="loadTemplate" @deleted="hasDeleted"
                            :editingId="templateId"/>
    </div>
</template>

<script>
import CREATE_MAIL_TEMPLATE_MUTATION from '../../graphql/Mutations/createMailTemplate.gql'
import UPDATE_MAIL_TEMPLATE_MUTATION from '../../graphql/Mutations/updateMailTemplate.gql'
import GET_MAIL_TEMPLATES_QUERY from '../../graphql/Query/MailTemplates.gql'
import autosize from 'autosize'
import MailTemplateList from './MailTemplateList'

export default {
  components: {MailTemplateList},
  name: 'mail-template-editor',
  data() {
    return {
      isLoading: false,
      isSavingNew: false,
      isSavingOld: false,
      isSaved: false,
      isNew: true,
      templateId: 0,
      showPreview: false,
      template: {
        name: '',
        subject: '',
        body: ''
      }
    }
  },
  computed: {
    placeholders() {
      return [
        {
          'placeholder': '##ANREDE##',
          'replacement': 'Frau',
        },
        {
          'placeholder': '##BRIEFANREDE##',
          'replacement': 'Sehr geehrte Frau Muster'
        },
        {
          'placeholder': '##TITEL##',
          'replacement': 'Dr.',
        },
        {
          'placeholder': '##VORNAME##',
          'replacement': 'Erika',
        },
        {
          'placeholder': '##NACHNAME##',
          'replacement': 'Musterfrau',
        },
        {
          'placeholder': '##MITGLIEDERNUMMER##',
          'replacement': '123456789',
        },
        {
          'placeholder': '##SEMINARNAME##',
          'replacement': 'Verbandsarbeit'
        },
        {
          'placeholder': '##SEMINARNUMMER##',
          'replacement': '123',
        },
        {
          'placeholder': '##DATUM_BEGINN##',
          'replacement': 'Montag, 12. Mai 2018',
        },
        {
          'placeholder': '##DATUM_ENDE##',
          'replacement': 'Dienstag, 13. Mai 2018',
        },
        {
          'placeholder': '##TERMIN_INFO_BLOCK##',
          'replacement': 'Ort: Tagungsort, 12345 Musterstandort \n' +
            'Datum: Montag, 12. Mai 2018 bis Dienstag, 13. Mai 2018\n' +
            'Uhrzeit: 09:00 - 05:00',
        },
        {
          'placeholder': '##BEZIRK##',
          'replacement': 'Frankfurt'
        },
        {
          'placeholder': '##BEZIRK_EMAIL##',
          'replacement': 'berzirks@email.de',
        },
        {
          'placeholder': '##ICH##',
          'replacement': this.$store.state.auth.user.fullName,
        }
      ]
    },
    preview() {
      return {
        subject: this.replaceText(this.template.subject, this.placeholders),
        body: this.replaceText(this.template.body, this.placeholders)
      }
    }
  },
  watch: {
    template: {
      handler() {
        this.isSaved = false
      },
      deep: true
    }
  },
  mounted() {
    autosize(this.$refs.mailarea)
  },
  methods: {
    updateAutosize() {
      this.$nextTick().then(() => {
        autosize.update(this.$refs.mailarea)
      })
    },
    replaceText(text, placeholders) {
      let output = text
      placeholders.forEach(p => {
        let placeholder = new RegExp(p.placeholder, 'gi')
        output = output.replace(placeholder, p.replacement)
      })
      return output
    },
    loadTemplate(template) {
      this.isNew = false
      this.templateId = template.id
      this.template.name = template.name
      this.template.subject = template.subject
      this.template.body = template.body
      if (this.$refs.mailarea) {
        this.$refs.mailarea.focus()
        this.updateAutosize()
      }
    },
    loadNew() {
      this.isNew = true
      this.template = {
        name: '',
        subject: '',
        body: ''
      }
      if (this.$refs.mailarea) {
        this.$refs.mailarea.focus()
        this.updateAutosize()
      }
    },
    keepfocus() {
      this.$nextTick(() => {
        this.$refs.mailarea.focus()
      })
    },
    insertText(text) {
      let pos = this.$refs.mailarea.selectionStart
      this.template.body = this.template.body.substr(0, pos) + text + this.template.body.substr(pos)
      let newpos = pos + text.length
      this.$nextTick(() => {
        this.$refs.mailarea.focus()
        this.$refs.mailarea.setSelectionRange(newpos, newpos)
      })
    },
    hasDeleted(template) {
      if (template.id === this.templateId) {
        this.loadNew()
      }
    },
    saveOld() {
      if (!this.isNew && this.templateId !== 0) {
        this.isSavingOld = true
        this.isSaved = false
        this.$apollo.mutate({
          mutation: UPDATE_MAIL_TEMPLATE_MUTATION,
          variables: {
            id: this.templateId,
            MailTemplate: this.template
          },
          refetchQueries: [{query: GET_MAIL_TEMPLATES_QUERY, variables: {includeIsUsed: true}}]
        }).then(() => {
          this.isSavingOld = false
          this.isSaved = true
        })
      }
    },
    saveNew() {
      this.isSavingNew = true
      this.$apollo.mutate({
        mutation: CREATE_MAIL_TEMPLATE_MUTATION,
        variables: {
          MailTemplate: this.template
        },
        refetchQueries: [{query: GET_MAIL_TEMPLATES_QUERY, variables: {includeIsUsed: true}}]
      }).then(({data}) => {
        this.isSaved = true
        this.isSavingNew = false
        this.templateId = data.CreateMailTemplate.id
        this.isNew = false
      })
    }
  }
}
</script>

<style scoped>
.savedIcon {
    margin-left: 0.5rem;
    vertical-align: bottom;
    padding-bottom: 1rem;
}

.textarea {
    min-height: 400px;
    max-height: 800px;

}

.replacement {
    max-width: 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
