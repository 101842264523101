<template>
    <div>
        <b-dropdown :disabled="!$store.getters.hasPermission('manage lecturers')">
            <button class="button is-small" slot="trigger" :class="{'is-loading': isLoading}"  style="min-width: 10em;">
                <span>{{levels[lectureship.level].name}}</span>
                <b-icon icon="menu-down" size="is-small"></b-icon>
            </button>
            <b-dropdown-item v-for="(level, key) in levels" @click="updateLevel(key)">
                <b-icon :icon="key === lectureship.level ? 'check' : ''" size="is-small"></b-icon>
                {{level.name}}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import {LECTURESHIP_LEVELS} from "../../../constants";
import UPDATE_LECTURESHIP_MUTATION from '../../../graphql/Mutations/updateLectureship.gql'

export default {
  name: "LecturerLevelButton",
  props: ['lectureship'],
  data() {
    return {
      levels: LECTURESHIP_LEVELS,
      isLoading: false
    }
  },
  methods: {
    updateLevel(level) {
      if (this.lectureship.level === level) return
      this.isLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_LECTURESHIP_MUTATION,
        variables: {
          id: this.lectureship.id,
          lectureship: {
            level: level
          }
        }
      }).then(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>