<template>
    <div>
        <navbar></navbar>
        <div class="container is-small m-t-lg">
            <!--<transition :name="transitionName">-->
            <router-view class="main-view is-relative"/>
            <!--</transition>-->
        </div>
        <transition name="slidein" transition>
            <sidebar v-if="$store.state.ui.showNotifications"></sidebar>
        </transition>
    </div>
</template>

<script>
import Sidebar from './Sidebar'
import Navbar from "./Navbar";

export default {
  data() {
    return {
      transitionName: 'fade',
      navbarOpen: true
    }
  },
  computed: {},
  components: {
    Sidebar,
    Navbar
  },
  watch: {
    $route(to, from) {
      const toDepth = to.meta.depth || 0
      const fromDepth = from.meta.depth || 0
      if (toDepth === fromDepth) {
        this.transitionName = 'fade'
      }
      if (toDepth > fromDepth) {
        this.transitionName = 'slide-left'
      }
      if (toDepth < fromDepth) {
        this.transitionName = 'slide-right'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container.is-small {
    max-width: 1024px;
    padding: 0.5rem;
}

.main-view {
    padding-top: 5rem;
    padding-bottom: 3rem;
}

/**** NOTIFICATION ANIOMATION ***/
.slidein-leave-active, .slidein-enter-active{
    transition: all 0.15s ease;
}
.slidein-enter-to, .slidein-leave{
    right: 0;
}
.slidein-enter, .slidein-leave-to{
    right:-300px;
}
</style>

<style>
/*DIRTY FIX FOR TAGINPUT/AUTOCOMPLETE*/
.autocomplete .fade-enter, .autocomplete .fade-leave-to {
    opacity: 1 !important;
}

.is-black-link, .is-black-link a {
    color: black;
}
</style>