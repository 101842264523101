<template>
  <div>
    <div class="panel">
      <div class="panel-heading">
        {{ mail.subject }}
        <a @click="closed = true" v-show="!closed">
          <b-icon icon="chevron-up" class="is-pulled-right"></b-icon>
        </a>
        <div class="is-pulled-right"><small>vor {{ mail.created_at | moment('from', 'now', true) }}</small></div>
      </div>
      <div class="mailtext panel-block" :class="{closed}" @click="closed = false">
        {{ mail.body }}
      </div>
      <div class="panel-block" v-if="!closed">
        Gesendet {{ mail.email ? 'an ' + mail.email : '' }} am {{
          mail.created_at | moment('DD.MM.YYYY [um] HH:mm')
        }}<br/>
        <template v-if="mail.replyTo">Antworten an {{ mail.replyTo }}</template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'member-mail-item',
  props: ['mail', 'open'],
  data() {
    return {
      closed: !this.open
    }
  }
}
</script>

<style lang="scss" scoped>
.head {
  padding: .5em 1em;
}

.mailtext {
  display: block;
  position: relative;
  background-color: white;

  &.closed {
    transition: all 0.5s ease;

    &:hover {
      cursor: pointer;
      height: 85px;
    }

    height: 75px;
    overflow: hidden;
    text-overflow: ellipsis;

    &:after {
      content: '';
      z-index: 2;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.6) 70%, rgba(255, 255, 255, 0.8) 100%);
    }
  }

  /*
    padding: 1em;
    background-color: white;*/
  /*max-height: 500px;*/
  white-space: pre-line;
  /*border: 1px solid #dbdbdb;
  border-radius: 4px;*/
  //box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
}

.panel {
  box-shadow: 0 0.25em .5em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}

.panel-heading {
  font-weight: normal;
  font-size: 1rem;
}
</style>