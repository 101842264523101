<template>
  <div>

    <apollo-query :query="SYSTEM_SETTINGS_QUERY">
      <template v-slot="{result: {data},loading,error}">
        <template v-if="!!data && !!data.SystemSettings && !loading">
          <editable v-slot="{ mutate, editData, data, loading, isDifferent,edit}" :data="data.SystemSettings"
                    :mutation="UPDATE_SYSTEM_SETTINGS_MUTATION"
                    :refetch-queries="[{query:SYSTEM_SETTINGS_QUERY}]"
                    show-buttons>
            <headline>Funktionen Allgemein</headline>
            <b-field message="Unterteilung in Bezirke (Nicht zu verwechseln mit Verbansstufen/Firmen)">
              <b-switch v-model="editData.districts">Bezirke</b-switch>
            </b-field>
            <b-field message="Verbansstufen/Firmen werden in Stufen (LV/BV/OV) sortiert.">
              <b-switch v-model="editData.departmentHierarchy">Hierarchische Verbandstufen/Firmen</b-switch>
            </b-field>
            <b-field message="Preise für Seminare">
              <b-switch v-model="editData.prices">Preise</b-switch>
            </b-field>
            <b-field message="Mitglieder können sowohl Kontakt- als auch Rechnungsadressen haben">
              <b-switch v-model="editData.memberBillingAddresses">Rechnungsadressen an Mitgliedern</b-switch>
            </b-field>
            <b-field message="Funktion zum Import von eVewa Daten aktivieren">
              <b-switch v-model="editData.dataImport">Datenimport</b-switch>
            </b-field>
            <headline class="m-t-lg">Funktionen Mitgliederbereich</headline>
            <b-field message="Auswertungsfunktion für berechtigte Mitglieder">
              <b-switch v-model="editData.analytics">
                Auswertung
              </b-switch>
            </b-field>
            <b-field message="in Mitgliederdashboard anzeigen">
              <b-switch v-model="editData.myEducationStatus">
                <i>Mein Ausbildungsstatus</i>
              </b-switch>
            </b-field>
            <b-field message="in Mitgliederdashboard anzeigen">
              <b-switch v-model="editData.myFunctions">
                <i>Meine Funktionen</i>
              </b-switch>
            </b-field>
            <b-field message="Menüpunkt Alle Seminare öffentlich einsehbar">
              <b-switch v-model="editData.publicSeminarList">
                <i>Alle Seminare</i> öffentlich
              </b-switch>
            </b-field>
            <b-field message="Kontaktformular in Mitgliederbereich anzeigen">
              <b-switch v-model="editData.contactForm">
                Kontaktformular
              </b-switch>
            </b-field>
            <b-field message="Supportformular in Mitgliederbereich anzeigen">
              <b-switch v-model="editData.supportForm">
                Supportformular
              </b-switch>
            </b-field>
            <b-field message="Mitglier/Kunden können sich selbst zu auswählbaren Seminaren anmelden">
              <b-switch v-model="editData.seminarSignup">
                Selbstanmeldungsfunktion
              </b-switch>
            </b-field>
            <b-field message="Mitgliedern/Kunden können eigenständig Adresse anpassen">
              <b-switch v-model="editData.userEditableAddresses">
                Selbstanpassung Addressen
              </b-switch>
            </b-field>
            <b-field message="Mitgliedern/Kunden können eigenständig Tags für Barrierefreiheit anpassen">
              <b-switch v-model="editData.userEditableAccessibility">
                Selbstanpassung Barrierefreiheit
              </b-switch>
            </b-field>
            <b-field message="Mitgliedern/Kunden können eigenständig Tags für Unterbringung anpassen">
              <b-switch v-model="editData.userEditableAccommodation">
                Selbstanpassung Unterbringung
              </b-switch>
            </b-field>
            <b-field message="Registrierung bestehender und neuer Mitglieder/Kunden (wenn aktiviert)">
              <b-switch v-model="editData.registration">
                Registrierung
              </b-switch>
            </b-field>
            <b-field message="Registierung neuer Mitglieder/Kunden erlauben.">
              <b-switch v-model="editData.registrationOfNewMembers" :disabled="!editData.registration">
                Registrierung neuer Mitglieder/Kunden
              </b-switch>
            </b-field>
            <headline>Verknüfung mit zweiten Mitgliederbereich</headline>
            <b-field label="URL zu 2. Mitgliederbereich" label-position="on-border">
              <b-input v-model="editData.secondPage" placeholder="https://seminare.beispiel.de"/>
            </b-field>
            <b-field label="Linkname zu 2. Mitgliederbereich" label-position="on-border">
              <b-input v-model="editData.secondPageName" placeholder="Zu den Beispiel-Seminaren"/>
            </b-field>
            <div v-if="isDifferent" class="m-t-lg">
              <b-button :loading="loading" class="m-r-md" type="is-success" @click="mutate">Änderungen speichern
              </b-button>
              <b-button type="is-danger" @click="edit(0)">Abbrechen</b-button>
            </div>
          </editable>
        </template>
      </template>
    </apollo-query>

  </div>
</template>

<script>
import SYSTEM_SETTINGS_QUERY from "@/graphql/Query/SystemSettings.gql"
import UPDATE_SYSTEM_SETTINGS_MUTATION from "@/graphql/Mutations/updateSystemSettings.gql"
import Editable from "@/components/Elements/Editable.vue";
import Template from "@/components/Pages/Template.vue";
import Headline from "@/components/Layout/Headline.vue";

export default {
  name: "BasicSettings",
  components: {Headline, Template, Editable},
  data() {
    return {
      SYSTEM_SETTINGS_QUERY,
      UPDATE_SYSTEM_SETTINGS_MUTATION
    }
  }
}
</script>

<style scoped>

</style>