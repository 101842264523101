<template>
    <b-input type="date" v-model="date" @input="sendDate"></b-input>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

export default {
  name: 'date-picker',
  props: ['value'],
  data () {
    return {
      date: this.formatDate(this.value)
    }
  },
  methods: {
    sendDate () {
      this.$emit('input', this.date)
    },
    formatDate (date) {
      if (_.isString(date) && date !== '') {
        return moment(date).format('YYYY-MM-DD')
      }
      return ''
    }
  },
  watch: {
    value (date) {
      let newDate = this.formatDate(date)
      if (newDate !== this.date) {
        this.date = newDate
      }
    }
  }
}
</script>

<style scoped>

</style>