<template>
  <div v-if="$store.getters.hasPermission('manage members')">
    <div @click="modalActive = true" class="button is-flex-mobile">
      <b-icon icon="account-plus" size="is-small"/>
      <span>{{ $tc('Mitglied') }} anlegen</span>
    </div>
    <b-modal :active.sync="modalActive" :has-modal-card="true">

      <editable v-slot="{loading, editData, mutate, errors}"
                :data="{
        salutation: null,
        firstName: '',
        lastName: '',
        email: '',
        district_id: this.$config.SHOW_DISTRICTS ? null : 1,
        association_department_id: null,
        state: true
      }"
                includeAll
                :mutation="require('../../graphql/Mutations/createMember.gql')" input-arg="member"
                @mutated="(data) =>  this.$router.push({name: 'Member', params: {id: data.createMember.id}})">

        <div class="modal-card">
          <div class="modal-card-head">
            <div class="modal-card-title">{{ $tc('Mitglied') }} hinzufügen</div>
          </div>
          <div class="modal-card-body">
            <b-field grouped>
              <b-field label="Anrede">
                <b-select v-model="editData.salutation" placeholder="Keine">
                  <option :value="null">Keine</option>
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                </b-select>
              </b-field>
              <b-field :type="{ 'is-danger' : errors['member.firstName'] }" expanded label="Vorname*">
                <b-input v-model="editData.firstName" placeholder="Vorname"></b-input>
              </b-field>
              <b-field :type="{ 'is-danger' : errors['member.lastName'] }" label="Nachname*">
                <b-input v-model="editData.lastName" placeholder="Nachname"></b-input>
              </b-field>
            </b-field>
            <b-field :label="$tc('Mitgliedernummer')+'*'" :type="{ 'is-danger' : errors['member.mitgliedernummer'] }">
              <b-input v-model="editData.mitgliedernummer" :has-counter="false" :placeholder="$tc('Mitgliedernummer')"
                       maxlength="7"></b-input>
            </b-field>
            <b-field v-if="$config.SHOW_DISTRICTS" :type="{ 'is-danger' : errors['member.district_id'] }"
                     label="Bezirk*">
              <district-select v-model="editData.district_id"/>
            </b-field>
            <b-field :label="$tc('Verbandstufe')">
              <department-select v-model="editData.association_department_id"></department-select>
            </b-field>
            <b-field :message="getEmailErrorMessage(errors['member.email'])"
                     :type="{ 'is-danger' : errors['member.email'] }" label="E-Mail">
              <b-input v-model="editData.email" placeholder="E-Mail-Adresse" type="email"></b-input>
            </b-field>


          </div>
          <div class="modal-card-foot">
            <button :class="{'is-loading' : loading}"
                    class="button is-success"
                    @click="mutate">
              Hinzufügen
            </button>
            <button class="button is-danger" @click="modalActive=false">Abbrechen</button>
          </div>
        </div>
      </editable>
    </b-modal>
  </div>
</template>

<script>
import DistrictSelect from "./DistrictSelect";
import Editable from "./Editable";
import DepartmentSelect from "./DepartmentSelect";
import {getEmailErrorMessage} from "@/utils/utils";

export default {
  name: "AddMemberButton",
  components: {DepartmentSelect, Editable, DistrictSelect},
  methods: {
    getEmailErrorMessage
  },
  data() {
    return {
      modalActive: false,
    }
  }
}
</script>

<style scoped>

</style>
