<template>
  <div class="mb-5">
    <div class="headline is-borderless">Adressen</div>
    <div class="columns is-multiline">
      <div v-for="(address,index) in [...sortedAddresses, newAddress] " :key="index"
           class="column is-6">
        <editable v-slot="{editing, mutate, edit, data, editData, loading, errors, nestedErrors, regexpErrors}"
                  :data="{id: addressable.id, addresses: {upsert: [address]}}"
                  :include-keys="['addresses.upsert[0].id']"
                  :input-arg="input_arg"
                  :mutation="addressable_mutation"
                  class="is-fullheight"
                  style="height: 100%">
          <template v-if="!editing && !address.id">
            <a v-if="canEdit"
               class="box is-flex is-justify-content-center is-fullheight align-items-center has-text-link half-visible"
               @click="edit">
              <span class="mr-2 my-5">Adresse Hinzufügen</span>
              <b-icon icon="plus-thick"></b-icon>
            </a>
            <div v-else-if="sortedAddresses.length === 0" class="tag is-danger is-light is-large">
              Keine Adresse hinterlegt.
            </div>
          </template>
          <div v-else :class="{'is-flex': !editing && !address.id}" class="box is-flex-grow-1 pt-5">
            <template v-if="!editing">
              <div class="is-flex">
                <address-info :address="address" :with-billing="withBilling" class="is-flex-grow-1"></address-info>
              </div>
              <div v-if="canEdit" class="mt-4">
                <ul class="actionlinks">

                  <li><a @click="edit">Bearbeiten
                    <b-icon icon="pencil-outline" size="is-small"/>
                  </a></li>
                  <li v-if="(!address.isBilling | !withBilling) && !address.isContact">
                    <editable v-slot="{mutate,loading}"
                              :data="{id: addressable.id, addresses: {delete: [address.id]}}"
                              :include-keys="['addresses']"
                              :input-arg="input_arg"
                              :mutation="addressable_mutation">
                      <a @click="$buefy.dialog.confirm({
                      type:'is-danger',
                      title: 'Addresse Löschen?',
                      message:'Wollen Sie die Adresse wirklich löschen:<br/><br/>'+address.toString.split(', ').join('<br/>'),
                      confirmText: 'Ja, Adresse löschen!',
                      onConfirm: mutate
                      })">
                        Löschen
                        <b-icon icon="delete-outline" size="is-small"/>
                      </a>
                    </editable>
                  </li>
                </ul>
                <ul class="actionlinks">

                  <li v-if="!address.isContact"><a @click="set(address.id, 'isContact', true)">Für Kontakt nutzen</a>
                  </li>
                  <li v-if="!address.isBilling && withBilling"><a @click="set(address.id, 'isBilling', true)">Für
                    Rechnung nutzen</a>
                  </li>
                </ul>
              </div>
            </template>
            <template v-else>
              <address-input v-model="editData.addresses.upsert[0]"
                             :errors="regexpErrors(/(?<=.addresses.upsert.0.).*/)"
                             :with-billing="withBilling"
                             :has-mail="editData.addresses.upsert[0].isBilling"
                             :nameFallback="addressable.addressName"
                             has-name></address-input>
              <div class="control mt-5">
                <button :class="{'is-loading': loading}" class="button is-success" @click="mutate">
                  Änderungen
                  speichern
                </button>
                <button class="button is-danger ml-2" @click="edit(false)">Abbrechen</button>
              </div>
            </template>
          </div>
        </editable>
      </div>
    </div>
  </div>
</template>

<script>
import Editable from "@/components/Elements/Editable";
import AddressInfo from "@/components/Elements/AddressInfo";
import AddressInput from "@/components/Elements/AddressInput";
import Template from "@/components/Pages/Template";

export default {
  name: "Addresses",
  components: {Template, AddressInput, AddressInfo, Editable},
  props: {
    addressable: Object,
    addressable_mutation: Object,
    input_arg: String,
    canEdit: Boolean,
    withBilling: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      newAddress: {id: null, name: null, street: null, addition1: null, addition2: null, email: null}
    }
  },
  methods: {
    set(id, field, val) {
      this.$apollo.mutate(
          {
            mutation: this.addressable_mutation,
            variables: {
              id: this.addressable.id,
              [this.input_arg]: {
                addresses: {
                  update: [
                    {id, [field]: val}
                  ]
                }
              }
            }
          }
      )
    }
  },
  computed: {
    sortedAddresses() {
      return this.addressable.addresses.slice()
          .sort((a, b) => Number(b.isBilling) - Number(a.isBilling))
          .sort((a, b) => Number(b.isContact) - Number(a.isContact))
    }
  }
}
</script>

<style scoped>
.half-visible {
  opacity: 0.6;
}

.half-visible:hover {
  opacity: 1;
}

.actionlinks {
  list-style: square;
}

.actionlinks li {
  display: inline-block;
  padding-left: .5em;
  margin-right: .5em;
  white-space: nowrap;
  border-left: 1px solid black;
}

.actionlinks li:first-child {
  padding-left: 0;
  border-left: 0;
}


</style>