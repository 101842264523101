<template>
  <div>
    <div class="columns">
      <div class="column"><h1 class="headline is-borderless">Voreingestellte Todos</h1>
      </div>
      <div class="column is-narrow">
        <add-default-todo-button/>
      </div>
    </div>
    <div class="columns is-multiline">
      <default-todo :DefaultTodo="item" :key="item.id" class="m-b-sm is-half-desktop" v-for="item in DefaultTodos"/>
    </div>
  </div>
</template>

<script>
import TemplateSelect from "../../Elements/TemplateSelect";
import DefaultTodo from "../../Elements/DefaultTodo";
import GET_DEFAULT_TODOS_QUERY from '../../../graphql/Query/DefaultTodos.gql'
import AddDefaultTodoButton from "../../Elements/AddDefaultTodoButton";

export default {
  name: 'DefaultTodos',
  data() {
    return {
      DefaultTodos: [],
      isLoading: 0
    }
  },
  components: {AddDefaultTodoButton, DefaultTodo, TemplateSelect},
  apollo: {
    DefaultTodos: {
      query: GET_DEFAULT_TODOS_QUERY
    }
  }
}
</script>

<style scoped>

</style>
