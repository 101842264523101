<template>
  <div class="is-relative">
    <apollo-query v-slot="{ result: { loading, error, data } , isLoading, }" :fetch-policy="fetchPolicy"
                  :query="query" :skip="skipComputed" :variables="queryVariables">
      <b-table
              :backend-filtering="backendFiltering"
              :backend-pagination="backendPagination"
              :backend-sorting="backendSorting"
              :current-page="currentPage(data)"
              :data="tableData(data)"
              :default-sort="[$route.query.sField || sortDefaults.field, $route.query.sOrder || sortDefaults.order]"
              :loading="!!isLoading"
              :mobile-cards="false"
              :pages="pages(data)"
              :per-page="pageLimit"
              :total="total(data)"
              class="table-has-frame"
              default-sort-direction="ASC"
              narrowed
              paginated
              striped
              @sort="onSort"
              @page-change="onPageChange">
        <b-table-column v-if="icon" width="2em">
          <b-icon :icon="icon"/>
        </b-table-column>
        <slot/>
        <div slot="empty" class="emptyTable">
          <template v-if="skip">
            {{ skipText }}
          </template>
          <template v-else-if="!isLoading && !loading">
            Leider nichts gefunden...
          </template>
        </div>
        <template v-if="!skip && loaded(data)" v-slot:bottom-left>
          {{ paginatorText(data) }}
        </template>
      </b-table>
      <slot v-bind="{data, loaded: loaded(data),  skip}" name="footer"></slot>
    </apollo-query>
  </div>
</template>

<script>
import ClearableInput from "./ClearableInput";
import {different} from '@/utils/utils';
import _ from "lodash";

export default {
  name: "Listable",
  components: {ClearableInput},
  props: {
    query: Object,
    name: String,
    variables: Object,
    icon: String,
    sortDefaults: {
      type: Object,
      default: () => ({field: 'id', order: 'ASC'})
    },
    filterFunction: {
      type: Function,
    },
    pageLimit: {
      type: Number,
      default: 25
    },
    backendSorting: Boolean,
    backendFiltering: Boolean,
    backendPagination: Boolean,
    fetchPolicy: {
      type: String,
      default: undefined
    },
    skip: {
      type: Boolean,
      default: false,
    },
    skipText: {
      type: String,
      default: "Bitte zuerst Filter einstellen"
    }
  },
  computed: {
    skipComputed() {
      return this.skip || this.debouncing
    },
    searchQuery() {
      return this.$route.query.searchQuery || ''
    },
    queryVariables() {
      return {
        page: parseInt(this.$route.query.page) || 1,
        limit: this.pageLimit,
        sort: {
          field: this.$route.query.sField || this.sortDefaults.field,
          order: (this.$route.query.sOrder || this.sortDefaults.order).toUpperCase()
        },
        ...this.variables
      }
    }
  },
  watch: {
    variables() {
      this.startDebounce()
    },
    searchQuery() {
      this.startDebounce()
    }
  },
  data() {
    return {
      debouncing: false,
    }
  },
  methods: {
    startDebounce() {
      this.debouncing = true;
      this.debounce()
    },
    debounce: _.debounce(function () {
      this.debouncing = false
    }, 600),
    paginatorInfo(data) {
      if (!this.loaded(data)) return {first: 0, last: 0, total: 0}
      if (this.backendPagination) {
        return {
          first: data[this.name].paginatorInfo.firstItem,
          last: data[this.name].paginatorInfo.lastItem,
          total: data[this.name].paginatorInfo.total
        }
      } else {
        return {
          first: 1 + (this.pageLimit * ((parseInt(this.$route.query.page) || 1) - 1)),
          last: Math.min(this.pageLimit * (parseInt(this.$route.query.page) || 1), this.tableData.length),
          total: this.tableData.length
        }
      }
    },
    paginatorText(data) {
      let info = this.paginatorInfo(data)
      if (info.total === 0) return 'Keine Einträge gefunden'
      return 'Zeige ' + info.first + ' bis ' + info.last + ' Einträge von ' + info.total;
    },
    loaded(data) {
      return data && data[this.name] && (this.backendPagination ? data[this.name].data : true);
    },
    pages(data) {
      if (!this.loaded(data)) return undefined
      return this.skip ? 0 : this.backendPagination ? data[this.name].paginatorInfo.currentPage : undefined
    },
    tableData(data) {
      if (this.skip || !this.loaded(data)) return []
      if (this.backendPagination) {
        return data[this.name].data
      }
      if (this.filterFunction) {
        return this.filterFunction(data[this.name])
      }
      return data[this.name]
    },
    total(data) {
      if (!this.loaded(data)) return undefined
      return this.skip ? 0 : this.backendPagination ? data[this.name].paginatorInfo.total : undefined
    },
    currentPage(data) {
      if (!this.loaded(data)) return 1
      return this.skip ? 0 : this.backendPagination ? data[this.name].paginatorInfo.currentPage : parseInt(this.$route.query.page) || 1
    },
    onSort(field, order) {
      let query = {...this.$route.query, sField: field, sOrder: order}
      if (different(query, this.$route.query)) {
        this.$router.replace({name: this.$route.name, query: {...query, page: "1"}})
      }
    },
    onPageChange(page) {
      let query = {...this.$route.query, page}
      if (different(query, this.$route.query)) {
        this.$router.replace({name: this.$route.name, query})
      }
    },
  },
}
</script>

<style scoped>
.loading {
    position: relative;
    height: 300px;
}

.emptyTable {
    color: grey;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>