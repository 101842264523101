<template>
    <div class="card" :class="{'lowOpa': !requirement.isRequired}">
        <b-loading :is-full-page="false" :active="isLoading" style="font-size:.75rem;"></b-loading>
        <div class="card-content">
            <div class="columns is-mobile">
                <router-link :to="{name: 'Template', params: {id: requirement.id}}" class="column">
                    {{requirement.template.name}}
                    <span class="has-text-black" v-if="!requirement.isRequired">(optional)</span>
                    <span class="has-text-black" v-else>(notwendig)</span>

                </router-link>
                <div v-if="$store.getters.hasPermission('manage functions')" class="column has-text-right is-narrow">
                    <a @click="removeRequirement">
                        <b-icon icon="delete" type="is-danger"></b-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UPDATE_ASSOCIATION_FUNCTION_MUTATION from '../../../graphql/Mutations/updateAssociationFunction.gql'

export default {
  name: "FunctionRequirementListItem",
  props: ['associationFunction', 'requirement'],
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    removeRequirement() {
      this.$buefy.dialog.confirm({
        title: 'Voraussetzung entfernen',
        message: 'Sind Sie sicher, dass Sie die Voraussetzung entfernen möchten?',
        confirmText: 'Ja, entfernen!',
        type: 'is-danger',
        onConfirm: () => {
          this.isLoading = true
          this.$apollo.mutate({
            mutation: UPDATE_ASSOCIATION_FUNCTION_MUTATION,
            variables: {
              id: this.associationFunction.id,
              associationFunction: {requirements: {disconnect: [this.requirement.template.id]}}
            }
          }).then(() => {
            this.isLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.lowOpa {
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.7;
}
</style>
