<template>
  <div class="address">
    <b-field v-if="hasName" :type="errors.name ? 'is-danger' : ''" label="Name / Firma"
             label-position="on-border">
      <b-input v-model="address.name" placeholder="Jan Mustermann"></b-input>
    </b-field>
    <b-field :type="errors.addition1 ? 'is-danger' : ''" grouped label="Adresszusatz" label-position="on-border">
      <b-input v-model="address.addition1" expanded placeholder="z.B. Ansprechpartner oder Abzeilung"/>
      <button v-if="address.addition2 === null" class="button" @click="address.addition2 = ''">
        <b-icon icon="plus"></b-icon>
      </button>
    </b-field>
    <b-field v-if="address.addition2 !== null" :type="errors.addition2 ? 'is-danger' : ''" grouped
             label="2. Adresszusatz"
             label-position="on-border">
      <b-input v-model="address.addition2" expanded placeholder="z.B. Ansprechpartner"/>
      <button class="button" @click="address.addition2 = null">
        <b-icon icon="minus"></b-icon>
      </button>
    </b-field>
    <b-field :type="errors.street ? 'is-danger' : ''" expanded label="Straße und Hausnummer" label-position="on-border">
      <b-input v-model="address.street" placeholder="Straße"></b-input>
    </b-field>
    <b-field grouped>
      <b-field :type="errors.zip ? 'is-danger' : ''" class="zip" label="PLZ" label-position="on-border">
        <b-input v-model="address.zip" :has-counter="false" maxlength="10" placeholder="12345"></b-input>
      </b-field>
      <b-field :type="errors.city ? 'is-danger' : ''" expanded label="Ort" label-position="on-border">
        <b-input v-model="address.city" placeholder="Ort"></b-input>
      </b-field>
    </b-field>
    <b-field v-if="hasPhone" :type="errors.phone ? 'is-danger' : ''" label="Telefon" label-position="on-border">
      <b-input v-model="address.phone" placeholder="Name"></b-input>
    </b-field>
    <b-field v-if="address.email !== null && withBilling" :type="errors.email ? 'is-danger' : ''" grouped
             label="Rechnungs E-Mail" label-position="on-border">
      <b-input v-model="address.email" expanded placeholder="Rechnungsemail"/>
      <button class="button" @click="address.email = null">
        <b-icon icon="minus"></b-icon>
      </button>
    </b-field>
    <b-field v-if="hasUrl" :type="errors.url ? 'is-danger' : ''" label="URL">
      <b-input v-model="address.url" placeholder="https://..."></b-input>
    </b-field>
    <b-field v-if="withBilling">
      <ul>
        <li><a v-if="address.email === null" @click="address.email = ''">Rechnungsemail?</a></li>
      </ul>
    </b-field>

  </div>
</template>

<script>
import _ from 'lodash'
import Template from "@/components/Pages/Template";

export default {
  name: "AddressInput",
  components: {Template},
  props: {
    value: Object,
    errors: {
      type: Object,
      default: () => ({})
    },
    nameFallback: String,
    hasName: Boolean,
    hasMail: Boolean,
    hasPhone: Boolean,
    hasUrl: Boolean,
    nullable: Boolean,
    withBilling: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      address: {...this.value},
      showNameInput: false,
    }
  },
  watch: {
    address: {
      deep: true,
      handler(value) {
        if (this.nullable && Object.values(this.address).filter(v => v !== '' && v !== null).length === 0) {
          this.$emit('input', null);
        } else {
          this.$emit('input', value)
        }
      }
    },
    value: {
      deep: true,
      handler(value) {
        if (!_.isEqual(this.address, value)) {
          this.address = {...this.address, ...value}
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.address {
  .num {
    width: 6em;
  }

  .zip {
    width: 6em;
  }

}

</style>