<template>
    <section class="hero is-fullheight">
        <div class="hero-body">
            <div class="container has-text-centered">
                <div class="column is-4 is-offset-4 auth">
                    <img class="logo eisLogo" src="../../assets/logo_eis.svg"/>
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
  name: 'auth-base'
}
</script>

<style scoped>
.auth {
    margin-top: -8rem;
}
.logo{
    width:60%;
    margin-bottom:1rem;
}
</style>