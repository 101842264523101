<template>
  <div class="is-relative">
    <b-table
        class="card"
        :data="templatePermissions"
        narrowed
    >
      <b-table-column v-slot="props" label="Bezirk">
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-slot="props" label="Kann Seminare anlegen" numeric>
        <b-switch type="is-success" :value="props.row.canCreate"
                  :disabled="!$store.getters.hasPermission('manage templates')"
                  @input="updatePermission(props.row.district_id, 'canCreate', $event)"/>
      </b-table-column>
    </b-table>
    <b-loading :is-full-page="false" :active="isLoading"></b-loading>
  </div>
</template>

<script>
import DistrictSelect from "../DistrictSelect";
import UPDATE_TEMPLATE_MUTATION from '../../../graphql/Mutations/updateTemplate.gql'
import GET_DISTRICTS_QUERY from '../../../graphql/Query/Districts.gql'

export default {
  name: "TemplatePermissions",
  components: {DistrictSelect},
  props: ['template'],
  apollo: {
    Districts: {
      query: GET_DISTRICTS_QUERY
    }
  },
  data() {
    return {
      isLoading: false,
      Districts: []
    }
  },
  computed: {
    templatePermissions() {
      return this.Districts.map(d => {
        let DistrictPermission = this.template.templatePermissions.find(p => p.district.id === d.id)
        if (!DistrictPermission) {
          DistrictPermission = {
            id: null,
            canCreate: false
          }
        }
        return {
          district_id: d.id,
          name: d.name,
          id: DistrictPermission.id,
          canCreate: DistrictPermission.canCreate,
        }
      })
    }
  },
  methods: {
    updatePermission(districtId, field, value) {
      this.isLoading = true
      let variables = {
        district_id: districtId
      }
      variables[field] = value
      this.$apollo.mutate({
        mutation: UPDATE_TEMPLATE_MUTATION,
        variables: {
          id: this.template.id,
          updateTemplatePermission: variables
        }
      }).then(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>