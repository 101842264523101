<template>
  <div>
    <slot v-bind="{
      filters,
      isActive,
      isActiveWith,
      isActiveWithout,
      resetFilter,
      transformed
    }"></slot>
  </div>
</template>

<script>
class FilterProxy {
  constructor(vm, defaults) {
    vm.$store.commit('initFilter', {name: vm.name, defaults})
    return new Proxy(this, {
      get: (target, property, receiver) => {
        if (property === '__ob__') return null
        return vm.$store.state[vm.name + 'Filter'].values[property]
      },
      set: (target, property, value, receiver) => {
        vm.$store.commit(vm.name + 'Filter/setFilter', {filter: property, value})
        return true;
      }
    });
  }
}

export default {
  name: "Filters",
  props: {
    name: {
      type: String,
      default: 'default'
    },
    defaults: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      filters: new FilterProxy(this, this.defaults)
    }
  },
  watch: {
    filter: {
      handler() {
        this.$emit('input', this.transformed)
      },
      deep: true
    }
  },
  methods: {
    resetFilter() {
      this.$store.commit(this.name + 'Filter/resetFilter');
    },
    getGetter(getter) {
      return this.$store.getters[this.name + 'Filter/' + getter]
    }
  },
  computed: {
    filter() {
      return this.$store.state[this.name + 'Filter'].values
    },
    isActive() {
      return this.getGetter('isActive')
    },
    isActiveWith() {
      return this.getGetter('isActiveWith')
    },
    isActiveWithout() {
      return this.getGetter('isActiveWithout')
    },
    transformed() {
      return this.getGetter('transformed')
    }
    //...mapGetters(this.name + 'Filter', ['isActive', 'isActiveWith', 'isActiveWithout'])
  },
  created() {
    this.$store.commit('initFilter', {name: this.name, defaults: this.defaults})
    this.$emit('input', this.transformed)
  }
}
</script>

<style scoped>

</style>