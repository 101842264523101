<template>
  <div class="has-text-centered m-t-md">
    <div class="small-container has-text-left">
      <div class="columns">
        <div class="column is-one-fifth">
          <aside class="menu card">
            <!--<p class="menu-label">
                General
            </p>-->
            <ul class="menu-list">
              <li>
                <router-link active-class="is-active"
                             :to="{name: 'SettingsOverview'}">
                  Allgemein
                </router-link>
              </li>
              <li>
                <router-link active-class="is-active"
                             :to="{name: 'SettingsEa'}">
                  {{ $tc('Mitgliederbereich') }}
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'SettingsMails'}"
                             active-class="is-active">
                  E-Mail Vorlagen
                </router-link>
              </li>
              <li>
                <router-link :to="{name: 'SettingsDefaultTodos'}"
                             active-class="is-active">
                  Todos
                </router-link>
              </li>
              <li v-if="$config.SHOW_DATA_IMPORT && $store.getters.hasPermission('import data')">
                <router-link :to="{name: 'SettingsDataImport'}"
                             active-class="is-active">
                  Datenimport
                </router-link>
              </li>
              <li v-if="$store.getters.hasPermission('update system settings')">
                <router-link :to="{name: 'SettingsBasic'}"
                             active-class="is-active">
                  Grundeinstellungen
                </router-link>
              </li>
            </ul>
          </aside>
        </div>
        <div class="column">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'settings'
}
</script>

<style scoped>
</style>
