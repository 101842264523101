<template>
  <div>
    <div ref="dropdown"
         :class="{'is-active': searchQuery !== '' && dropdownOpen}"
         class="dropdown">
      <div class="control has-icons-right">
        <clearable-input ref="input" v-model="searchQuery" :has-counter="false" class="dropdown-trigger"
                         maxlength="30"
                         placeholder="Schnellsuche"
                         @click.native="dropdownOpen = true; hasFocus = true;"
                         @focusin.native="dropdownOpen = true; hasFocus = true;"
                         @focusout.native="hasFocus = false;" @input="dropdownOpen = true;"
                         @keydown.native.enter="debouncing = false; goToFirst();"/>
      </div>
      <div class="dropdown-menu">
        <div :class="{loading: $apollo.loading}" class="dropdown-content">
          <router-link v-if="Seminars.data.length > 0" :to="{name: 'Seminars'}" active-class=""
                       class="dropdown-item"
                       style="font-size: 1.4em"
                       @click.native="selected(); $store.commit('seminarFilter/setFilters', {textSearch : searchQuery})">
            <b>Seminare</b>
            ({{ Seminars.paginatorInfo.total }})
            <b-icon icon="magnify"></b-icon>
          </router-link>
          <router-link v-for="seminar in Seminars.data" :key="'s'+seminar.id"
                       :to="{name: 'Seminar', params:{id: seminar.id}}" class="dropdown-item" @click.native="selected">
            <span class="stitle">{{ seminar.template.name }}</span>
            <tags-simple :tags="[...seminar.template.tags, ...seminar.tags]" class="ml-2"
                         @click="tag => searchQuery = tag"
                         @click.native.stop.prevent/>
            <br/>
            <span class="mr-1">{{ seminar.id }}</span>

            <small class="sdate mr-2">
              <template v-if="seminar.begin">
                {{ seminar.begin |  moment('D. MMM YYYY') }}
              </template>
              <template v-else>Terminlos</template>
            </small>
          </router-link>
          <router-link v-if="Members.data.length > 0" :to="{name: 'Members'}" active-class=""
                       class="dropdown-item"
                       style="font-size: 1.4em;"
                       @click.native="selected(); $store.commit('memberFilter/setFilters', {textSearch: searchQuery})">

            <b>Mitglieder</b>
            ({{ Members.paginatorInfo.total }})
            <b-icon icon="magnify"></b-icon>
          </router-link>
          <router-link v-for="member in Members.data" :key="'m'+member.id"
                       :to="{name: 'Member', params:{id: member.id}}" class="dropdown-item" @click.native="selected">

            <div class="stitle"> {{ member.fullName }}
              <tags-simple :tags="member.tags" class="ml-2" @click="tag => searchQuery = tag"
                           @click.native.stop.prevent/>

            </div>
            <span class="sdate">{{ member.mitgliedernummer }}</span>


          </router-link>
          <router-link v-if="Todos.paginatorInfo.total > 0" :to="{name: 'Dashboard'}" active-class=""
                       class="dropdown-item"
                       style="font-size: 1.4em;"
                       @click.native="selected(); $store.commit('todoFilter/setFilters', {textSearch: searchQuery})">

            <b>Todos</b>
            ({{ Todos.paginatorInfo.total }})
            <b-icon icon="magnify"></b-icon>
          </router-link>
          <b-loading :active="$apollo.loading" :is-full-page="false"
                     style="border-radius: 4px; border-top:4px solid transparent;"></b-loading>
          <div v-if="notFound" class="dropdown-item" style="line-height: 2;">Nicht gefunden</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SUPER_SEARCH_QUERY from "../../graphql/Query/SuperSearch.gql"
import {isCustomElement} from "buefy/src/utils/helpers";
import TagsSimple from "./TagsSimple";
import Template from "../Pages/Template";
import ClearableInput from "./ClearableInput";
import _ from 'lodash';


const queryOpts = {
  skip() {
    return this.debouncing || this.searchQuery === ''
  },
  variables() {
    return {text: this.searchQuery}
  }
}
export default {
  name: "SpeedSearch",
  components: {ClearableInput, Template, TagsSimple},
  data() {
    return {
      searchQuery: "",
      entered: false,
      dropdownOpen: false,
      debouncing: false,
      Members: {
        data: [],
        paginatorInfo: {
          count: 0,
          total: 0
        }
      },
      Seminars: {
        data: [],
        paginatorInfo: {
          count: 0,
          total: 0
        }
      },
      Todos: {
        paginatorInfo: {
          total: 0
        }
      },
    }
  },
  watch: {
    searchQuery() {
      this.debouncing = true;
      this.debounce()
    }
  },
  methods: {
    debounce: _.debounce(function () {
      this.debouncing = false
    }, 600),
    selected() {
      this.dropdownOpen = false
      this.$emit('select')
    },
    goTo(name, id = null) {
      this.selected()
      this.$router.push({name, params: {id: id}}).catch(() => true)
    },
    goToFirst() {
      this.debouncing = false
      this.$nextTick(() => {
        if (this.$apollo.loading) {
          this.entered = true;
          return;
        }
        if (this.Seminars.data.length > 0) {
          this.goTo("Seminar", this.Seminars.data[0].id)
        } else if (this.Members.data.length > 0) {
          this.goTo("Member", this.Members.data[0].id)
        } else if (this.Todos.paginatorInfo.total > 0) {
          this.$store.commit('memberFilter/setFilters', {textSearch: this.searchQuery})
          this.goTo("Dashboard")
        }
      })
    },
    /**
     * Close dropdown if clicked outside.
     */
    clickedOutside(event) {
      const target = isCustomElement(this) ? event.composedPath()[0] : event.target
      if (!this.hasFocus && this.whiteList.indexOf(target) < 0) this.dropdownOpen = false
    },
  },
  computed: {
    notFound() {
      return !this.$apollo.loading && this.Members.paginatorInfo.total === 0 && this.Seminars.paginatorInfo.total === 0 && this.Todos.paginatorInfo.total === 0
    },
    whiteList() {
      const whiteList = []
      //whiteList.push(this.$refs.input)
      whiteList.push(this.$refs.dropdown)
      // Add all chidren from dropdown
      if (this.$refs.dropdown !== undefined) {
        const children = this.$refs.dropdown.querySelectorAll('*')
        for (const child of children) {
          whiteList.push(child)
        }
      }
      return whiteList
    },
  },
  apollo: {
    Members: {
      query: SUPER_SEARCH_QUERY,
      ...queryOpts,
      result() {
        if (this.entered) {
          this.entered = false;
          this.$nextTick(() => {
            this.goToFirst()

          })
        }
      }
    },
    Seminars: {
      query: SUPER_SEARCH_QUERY,
      ...queryOpts,

    },
    Todos: {
      query: SUPER_SEARCH_QUERY,
      ...queryOpts,
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside)
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside)
    }
  },
}
</script>

<style lang="scss" scoped>
.dropdown-content {

  vertical-align: text-top;
  min-width: 500px;

  &.loading {
    min-height: 200px;
  }

  width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  white-space: normal;
}

.dropdown-item {
  white-space: normal;
  font-size: 1rem;
  font-weight: normal;

  .stitle {
    font-size: 1.15em;
    font-weight: normal;
  }

  .sdate {
    font-weight: normal;
  }

  .icon {
    vertical-align: text-bottom;

  }

  .mglnr {
    font-size: 1em;
    font-weight: lighter;
  }
}

</style>