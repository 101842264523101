<template>
  <div>
    <slot v-if="errors.networkError || graphQLError || hasOtherError" :errors="errors"
          :graphQLError="graphQLError" name="networkError">
      <div class="notification is-danger is-light">
        <b>Ein {{ graphQLError ? 'Serverfehler' : 'Fehler' }} ist aufgetreten</b>
        <div v-if="graphQLError">{{ graphQLError.message }}</div>
      </div>
    </slot>
    <slot :data="originalData"
          :edit="edit"
          :editData="editData"
          :editing="editing"
          :isDifferent="isDifferent"
          :errors="validationErrors || {}"
          :nestedErrors="nestedError"
          :regexpErrors="regexpError"
          :loading="loading"
          :mutate="mutate">
    </slot>
  </div>
</template>

<script>
import {difference, filterObject} from '@/utils/utils'

export default {
  name: "Editable",
  props: {
    data: {
      type: Object, default: () => {
      }
    },
    refetchQueries: {type: Array, default: () => []},
    includeKeys: {type: Array, default: () => []},
    excludeKeys: {type: Array, default: () => []},
    includeAll: Boolean,
    mutation: Object,
    inputArg: {type: String, default: () => 'input'}
  },
  data() {
    return {
      originalData: {},
      editData: {},
      errors: {},
      editing: false,
      loading: false,
    }
  },
  watch: {
    data: {
      handler() {
        this.init()
      },
      deep: true
    }
  },
  computed: {
    isDifferent() {
      return !_.isEmpty(this.diff)
    },
    diff() {
      return difference(this.editData, this.originalData);
    },
    hasErrors() {
      return !_.isEmpty(this.errors)
    },
    hasOtherError() {
      return this.graphQLError === null && this.validationErrors === null && this.hasErrors;
    },
    graphQLError() {
      return (this.errors
          && this.errors.graphQLErrors
          && this.errors.graphQLErrors[0]
          && this.errors.graphQLErrors[0].extensions
          && this.errors.graphQLErrors[0].extensions.category === "graphql"
      ) ? this.errors.graphQLErrors[0] : null
    },
    validationErrors() {
      return (this.errors
          && this.errors.graphQLErrors
          && this.errors.graphQLErrors[0]
          && this.errors.graphQLErrors[0].extensions
          && this.errors.graphQLErrors[0].extensions.validation)
          ? this.errors.graphQLErrors[0].extensions.validation : null;
    }
  },
  methods: {
    regexpError(regexp) {
      let renamed = {}
      Object.keys(this.validationErrors || {}).forEach(key => {
        renamed[key.match(regexp)[0]] = this.validationErrors[key]
      })
      return renamed
    },
    nestedError(prefix) {
      let renamed = {}
      Object.keys(this.validationErrors || {}).forEach(key => {
        renamed[key.replace(prefix, '')] = this.validationErrors[key]
      })
      return renamed
    },
    mutate() {
      this.errors = {}
      this.loading = true;
      let data = this.includeAll ? this.editData : _.omit(_.pick(this.editData, this.includeKeys), this.excludeKeys)
      _.merge(data, this.diff)
      this.$apollo.mutate({
        mutation: this.mutation,
        refetchQueries: this.refetchQueries,
        variables: {
          id: this.data.id,
          [this.inputArg]: data
        }
      }).then(({data}) => {
        this.$emit('mutated', data)
        this.loading = false;
        this.editing = false
        this.initEdit()
      }).catch((errors) => {
        console.log(errors.networkError)
        this.loading = false;
        this.errors = errors
      })
    },
    edit(edit = true) {
      this.editing = edit
      this.initEdit()
    },
    initEdit() {
      this.editData = _.cloneDeep(this.originalData)
    },
    init() {
      this.originalData = _.cloneDeep(filterObject(this.data, '__typename'))
      this.initEdit()
    },
  },
  beforeMount() {
    this.init()
  }
}
</script>

<style scoped>

</style>