<template>
  <b-field label="Datenstatus">
    <b-radio-button v-for="(s, key) in status" :key="key" :native-value="key" :type="s.type" :value="value"
                    @input="input">
      {{ s.text }}
    </b-radio-button>
  </b-field>
</template>

<script>
import {MemberDataStatus} from "@/constants";

export default {
  name: "MemberDataStatusInput",
  props: ['value'],
  data() {
    return {
      status: MemberDataStatus
    }
  },
  methods: {
    input(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>