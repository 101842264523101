<template>
  <div class="space-bottom">
    <div class="headline is-borderless">Funktionen</div>
    <b-table :data="member.functions"
             :mobile-cards="false"
             class="table-has-frame mb-4"
             narrowed
             striped>
      <b-table-column v-slot="props" field="name" label="Name" sortable>
        <router-link :to="{ name: 'Function', params: { id: props.row.functionId }}">
          {{ props.row.name }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="vdkFunctionId" label="Nummer" numeric sortable width="5em">
        {{ props.row.vdkFunctionId || props.row.functionId }}
      </b-table-column>
      <b-table-column v-if="$config.SHOW_ASSOCIATION_LEVELS" v-slot="props" field="level.name" label="Ebene" sortable
                      width="6em">
        {{ props.row.level ? props.row.level.nameShort : '-' }}
      </b-table-column>
      <b-table-column v-slot="props" :label="$tc('Verbandsstufe')"
                      :width="$config.SHOW_ASSOCIATION_LEVELS? '15em' : '21em'" field="level.name"
                      sortable>
        <template v-if="props.row.department">
          {{ props.row.department.name }}
        </template>
        <span v-else class="has-text-grey">keine verknüft</span>
      </b-table-column>
      <b-table-column v-slot="props" cell-class="is-relative" numeric width="3em">
        <apollo-mutation v-slot="{ mutate, loading, error }"
                         :mutation="require('../../../graphql/Mutations/updateMember.gql')"
                         :variables="{id: member.id, removeMemberFunctionId: props.row.id}">
          <b-loading :active="loading" :is-full-page="false" style="font-size: 0.5em;"></b-loading>
          <a @click="$buefy.dialog.confirm({title: 'Funktion Entfernen', confirmText: 'Ja, entfernen!', message:`Wollen Sie die Funktion wirklich entfernen?<br/>Sie können sie später wieder hinzufüngen.`, type:'is-danger', onConfirm: mutate})">
            <b-icon icon="delete" type="is-danger"></b-icon>
          </a>
        </apollo-mutation>
      </b-table-column>
      <template slot="empty">
        <empty-table>Keine Funktionen</empty-table>
      </template>
    </b-table>
    <apollo-mutation v-slot="{ mutate, loading, error }"
                     :mutation="require('../../../graphql/Mutations/updateMember.gql')"
                     :variables="{id: member.id, addMemberFunction:{association_function_id: addFunctionId, association_department_id:  addFunctionDepartmentId}}"
                     @done="() => addFunctionId = null">
      <b-field grouped>
        <function-select v-model="addFunctionId"/>
        <div class="control">
          <department-select v-model="addFunctionDepartmentId" style="max-width:300px;"/>
        </div>
        <div class="control">
          <button :class="{'is-loading': loading}" class="button" @click="mutate">Hinzufügen</button>
        </div>
      </b-field>
    </apollo-mutation>
  </div>
</template>

<script>
import EmptyTable from "../EmptyTable";
import FunctionSelect from "@/components/Elements/FunctionSelect";
import Editable from "@/components/Elements/Editable";
import DepartmentSelect from "@/components/Elements/DepartmentSelect";

export default {
  name: "MemberFunctions",
  components: {DepartmentSelect, Editable, FunctionSelect, EmptyTable},
  props: ['member'],
  data() {
    return {
      addFunctionId: null,
      addFunctionDepartmentId: this.member.department ? this.member.department.id : null
    }
  }
}
</script>

<style scoped>

</style>