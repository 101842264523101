<template>
  <base-page title="Seminare">
    <template slot="headerRight">
      <add-seminar-button class="is-block-mobile"/>
    </template>
    <seminar-filter v-model="filter" class="mb-5"/>
    <listable :query="require('../../graphql/Query/Seminars.gql')" :sortDefaults="{field: 'begin', order: 'asc'}"
              :variables="{filters: filter}" backend-filtering
              backend-pagination
              backend-sorting name="Seminars">
      <b-table-column v-slot="props" field="id" label="Nr." meta="Nummer" sortable width="3em">
        <router-link :to="{ name: 'Seminar', params: { id: props.row.id }}">{{ props.row.id }}
        </router-link>
      </b-table-column>
      <b-table-column v-slot="props" field="templates.name" label="Name" sortable>
        <router-link :to="{ name: 'Seminar', params: { id: props.row.id }}">
          {{ props.row.template.name }}
        </router-link>
        <tags-simple :tags="[...props.row.template.tags, ...props.row.tags]"
                     @click="tag => $store.commit('seminarFilter/setFilters', {textSearch : tag})"/>
      </b-table-column>
      <b-table-column v-if="$config.SHOW_DISTRICTS" v-slot="props" field="district_id" label="Bezirk" sortable
                      width="8em">
        {{ props.row.district.name }}
      </b-table-column>
      <b-table-column v-slot="props" field="begin" label="Beginn" meta="Beginn des ersten Termins" sortable width="5em">

        {{ props.row.begin | moment('DD.MM.YY') }}

      </b-table-column>
      <b-table-column v-slot="props" field="place" label="Tagungsort" meta="Tagungsort des ersten Termins" sortable
                      width="11em">
        {{ props.row.placeName }}
      </b-table-column>
      <b-table-column v-slot="props" field="quota" label="Kon." meta="Kontingent" numeric sortable>
        {{ props.row.quota }}
      </b-table-column>

      <b-table-column v-slot="props" field="participantsCount" label="TN" meta="Teilnehmende" numeric sortable
                      width="2em">
        {{ props.row.participantsCount }}
      </b-table-column>
      <b-table-column v-slot="props" field="waitinglistCount" label="WL" meta="Warteliste" numeric sortable width="2em">
        {{ props.row.waitinglistCount }}
      </b-table-column>
      <b-table-column v-slot="props" field="canceledCount" label="AB" meta="Abgesagt" numeric sortable width="2em">
        {{ props.row.canceledCount }}
      </b-table-column>
      <b-table-column v-slot="props" field="status" label="Status" sortable width="7em">
        <SeminarStatus :status="props.row.status"/>
      </b-table-column>
    </listable>
  </base-page>
</template>

<script>
import BasePage from '../Layout/BasePage'
import AddSeminarButton from "../Elements/Seminar/AddSeminarButton";
import Listable from "../Elements/Listable";
import TagsSimple from "../Elements/TagsSimple";
import SeminarStatus from "../Elements/Seminar/SeminarStatus";
import SeminarFilter from "../Elements/Seminar/SeminarFilter";

export default {
  name: 'Seminars',
  components: {
    SeminarFilter,
    SeminarStatus,
    TagsSimple,
    Listable,
    AddSeminarButton,
    BasePage
  },
  data() {
    return {
      filter: {}
    }
  }
}
</script>

<style>

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
