<template>
    <div class="card">
        <b-loading :is-full-page="false" :active="isLoading" style="font-size:.75rem;"></b-loading>
        <div class="card-content">
            <div class="columns is-mobile">
                <router-link :to="{name: 'Template', params: {id: requirement.id}}" class="column">
                  {{ requirement.requires.name }}

                </router-link>
                <div class="column has-text-right is-narrow" v-if="$store.getters.hasPermission('manage templates')">
                    <a @click="removeRequirement">
                        <b-icon icon="delete" type="is-danger"></b-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UPDATE_TEMPLATE_MUTATION from '../../../graphql/Mutations/updateTemplate.gql'

export default {
  name: "TemplateRequirementsListItem",
  props: ['requirement', 'template'],
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    removeRequirement() {
      this.$buefy.dialog.confirm({
        title: 'Voraussetzung entfernen',
        message: 'Sind Sie sicher, dass Sie die Voraussetzung entfernen möchten?',
        confirmText: 'Ja, entfernen!',
        type: 'is-danger',
        onConfirm: () => {
          this.isLoading = true
          this.$apollo.mutate({
            mutation: UPDATE_TEMPLATE_MUTATION,
            variables: {
              id: this.template.id,
              template: {requirements: {delete: [this.requirement.id]}}
            }
          }).then(() => {
            this.isLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.card-content {
    padding: .75rem;
}
</style>
