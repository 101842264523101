// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import apolloClient from './apollo'

import VueApollo from 'vue-apollo'

import Buefy from 'buefy'

import {marked} from 'marked'

import 'easymde/dist/easymde.min.css'
import '@mdi/font/css/materialdesignicons.css'

import VueMoment from 'vue-moment'
import moment from 'moment'
import i18n from './i18n'
import {API_INIT_CSRF, loadSettings} from "./config";

// Install the vue plugin
Vue.use(VueApollo)

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: 'isLoading'
  },
  errorHandler(err) {
    this.$buefy.toast.open({
      duration: 5000,
      message: 'Ein Fehler ist aufgetreten: ' + err,
      type: 'is-danger'
    })
  }
})


Vue.use(Buefy, {
  defaultDialogCancelText: 'Abbrechen',
  defaultDialogConfirmText: 'Bestätigen',
  defaultIconPack: 'mdi'
})

Vue.config.productionTip = false

moment.locale('de')

Vue.use(VueMoment, {
  moment
})


marked.setOptions({
  sanitize: true,
  breaks: true
})
Vue.filter('marked', function (input) {
  return marked(input)
})

Vue.directive('parseInt', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      let val = parseInt(e.target.value);
      vnode.componentInstance.$emit('input', isNaN(val) ? null : val)
    })
  }
})

Vue.directive('parseFloat', {
  bind(el, _, vnode) {
    el.addEventListener('input', (e) => {
      let val = parseFloat(e.target.value);
      vnode.componentInstance.$emit('input', isNaN(val) ? null : val)
    })
  }
})

fetch(API_INIT_CSRF, {credentials: 'include', mode: 'cors'}).then(async () => {
  await loadSettings()
  await store.dispatch('getUser')
  new Vue({
    router,
    store,
    apolloProvider,
    template: '<App/>',
    render: h => h(App)
  }).$mount('#app')
})