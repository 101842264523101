<template>
  <div class="card" style="padding: .75rem;">
    <editable v-slot="{loading, edit, editing, mutate, editData, errors}"
              :data="seminar" :mutation="require('../../../graphql/Mutations/updateSeminar.gql')" input-arg="seminar">
      <div class="columns is-multiline is-mobile">
        <div class="column">
          <table :class="{'editing': editing}">
            <tbody>
            <tr>
              <th style="width:10rem;">Seminartyp</th>
              <td style="width:60%;">
                <router-link :to="{name: 'Template', params:{id:seminar.template.id}}">
                  {{ seminar.template.name }}
                </router-link>
              </td>
            </tr>
            <tr v-if="$config.SHOW_DISTRICTS">
              <th>Bezirk</th>
              <td>
                <span>{{ seminar.district.name }}</span>
                <!--<district-select v-else v-model="editData.district_id"/>-->
              </td>
            </tr>
            <tr>
              <th>Belegung</th>
              <td>
                <progress
                    :class="{
                                'is-success': (occupationTotal < seminar.quota),
                                'is-danger': (occupationTotal > seminar.quota),
                                'is-warning': (occupationTotal === seminar.quota)}"
                    :max="seminar.quota"
                    :value="occupationTotal" class="progress is-marginless"
                    style="width:100%; display:inline-block;">
                  {{ occupationTotal + ' / ' + seminar.quota }}
                </progress>
                <div class="tags has-addons is-inline-block is-marginless " style="width:100%; display:block;">
                <span class="tag is-success "
                      style="width:32%;">{{ seminar.participations.filter(p => p.status === 'PARTICIPATING').length }} TL</span>
                  <span class="tag is-warning "
                        style="width:32%;"> {{
                      seminar.participations.filter(p => p.status === 'WAITING').length
                    }} WL</span>
                  <span class="tag is-danger "
                        style="width:32%;">{{
                      seminar.participations.filter(p => p.status === 'CANCELED').length
                    }} AB</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                Teilnehmergrenze
              </th>
              <td>
                <span v-if="!editing">{{ seminar.quota }}</span>
                <b-input v-else v-model="editData.quota" v-parse-int min="0" placeholder="z.B. 15"
                         size="" type="number"></b-input>
              </td>
            </tr>
            <tr>
              <th>
                Teilnahmeliste
              </th>
              <td>
                <template v-if="!editing">
                  <span v-if="seminar.isOpen" class="has-text-success">offen</span>
                  <span v-else class="has-text-danger">geschlossen</span>
                </template>
                <template v-else>
                  <b-switch v-model="editData.isOpen"
                            :type="editData.isOpen ? 'is-success' : 'is-danger'">
                    <span v-if="editData.isOpen" class="has-text-success">offen</span>
                    <span v-else class="has-text-danger">geschlossen</span>
                  </b-switch>
                </template>
              </td>
            </tr>
            <tr v-if="$config.SHOW_SEMINAR_REGISTER">
              <th>
                Online Anmeldung
              </th>
              <td>
                <template v-if="!editing">
                  <span v-if="seminar.isRegistrable && seminar.template.visible_in_ea"
                        class="has-text-success">offen</span>
                  <span v-else-if="seminar.isRegistrable" class="has-text-success">offen (nur über Link)</span>

                  <span v-else class="has-text-danger">geschlossen</span>
                </template>
                <template v-else>
                  <b-switch v-model="editData.isRegistrable"
                            :type="editData.isRegistrable ? 'is-success' : 'is-danger'">
                    <span v-if="editData.isRegistrable" class="has-text-success">offen</span>
                    <span v-else class="has-text-danger">geschlossen</span>
                  </b-switch>
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $tc('Mitgliederbereich') }}</th>
              <td><a :href="seminar.eaUrl" target="_blank"><span>öffnen</span>
                <b-icon icon="open-in-new" style="vertical-align: bottom;"/>
              </a></td>
            </tr>
            <tr v-if="$config.SHOW_PRICE">
              <th>
                Preis
              </th>
              <td>
                <template v-if="!editing">
                  <template v-if="seminar.price === 0">
                    kostenlos
                  </template>
                  <template v-else-if="seminar.price === null">
                    Kein Preis angegeben
                  </template>
                  <template v-else>
                    {{ seminar.price }} €
                  </template>
                </template>
                <template v-else>
                  <b-field message="0 für kostenlos">
                    <b-input v-model="editData.price" v-parse-float/>
                  </b-field>
                </template>
              </td>
            </tr>
            <tr v-if="seminar.replyToName || editing">
              <th>Antworten an Name</th>
              <td v-if="!editing">{{ seminar.replyToName }}</td>
              <td v-else>
                <b-field
                    message="Benutzerdefinierter Absender- und Antworten-an Name">
                  <clearable-input v-model="editData.replyToName" clearable></clearable-input>
                </b-field>
              </td>
            </tr>
            </tbody>
            <tr v-if="seminar.replyToEmail || editing">
              <th>Antworten an E-Mail</th>
              <td v-if="!editing">{{ seminar.replyToEmail }}</td>
              <td v-else>
                <b-field
                    message="Benutzerdefinierte Antworten-an E-Mail Adresse. Leer = Die E-Mail Adresse des Bezirks.">
                  <clearable-input v-model="editData.replyToEmail" :placeholder="seminar.district.email"
                                   clearable></clearable-input>
                </b-field>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <template v-if="seminar.canManageAll">
          <div v-if="!editing" class="column is-narrow">
            <a @click="edit(true)">
              <b-icon icon="pencil"/>
            </a>
          </div>
          <div v-else class="column is-full">
            <button :class="{'is-loading' : loading}" class="button is-success" @click="mutate">
              Speichern
            </button>
            <button class="button is-danger ml-2" @click="edit(false)">Abbrechen</button>
          </div>
        </template>
      </div>
    </editable>
  </div>
</template>

<script>
import DistrictSelect from "../DistrictSelect";
import Editable from "../Editable";
import ClearableInput from "@/components/Elements/ClearableInput";

export default {
  name: "SeminarSettings",
  components: {ClearableInput, Editable, DistrictSelect},
  props: ['seminar'],
  computed: {
    occupationTotal() {
      return this.seminar.participations.filter(p => p.status === 'PARTICIPATING').length
    }
  }
}
</script>

<style scoped>
tr {
  height: 1.75rem;
}

.editing tr {
  height: 2.5rem;
}

</style>
