<template>
    <div class="sideWrapper" >
        <div class="side">
            <notifications></notifications>
        </div>
    </div>
</template>

<script>
import Notifications from "../Elements/Notifications";
export default {
  name: 'sidebar',
  components: {Notifications}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/boxShadow';
.sideWrapper{
    position:fixed;
    top: 0;
    bottom:0;
    width: 320px;
    z-index:10;
    background-color: white;
    right:0;
    margin-top:3em;
    @include box_shadow(3);
}

.side{
    height:100%;
    width:100%;
}


</style>
