import { render, staticRenderFns } from "./SeminarToDoDeadline.vue?vue&type=template&id=0bb3d1fa&scoped=true&"
import script from "./SeminarToDoDeadline.vue?vue&type=script&lang=js&"
export * from "./SeminarToDoDeadline.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bb3d1fa",
  null
  
)

export default component.exports