<template>
  <span class="has-text-danger" v-if="!mitgliedernummer">Keine {{$tc('MitgliedernummerShort')}}</span>
  <span :class="{'has-text-danger' : String(mitgliedernummer).length < 8}" v-else>{{mitgliedernummer}}</span>
</template>

<script>
export default {
  name: "Mitgliedernummer",
  props: ['mitgliedernummer']
}
</script>

<style scoped>

</style>
