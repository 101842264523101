<template>
    <article class="media comm">
        <figure class="media-left" style="margin-top: .2rem;">
            <base-avatar :size="30"
                         :user="comment.author ? comment.author : {id: null, fullName: 'Unbekannt'}"></base-avatar>
        </figure>
        <div class="media-content">
            <div class="content comment">
                <div>
                    <span class="username">{{comment.author ? comment.author.fullName : 'Unbekannt'}}</span>
                    <span class="time" v-if="comment.created_at">vor {{ comment.created_at | moment('from', 'now', true)}}</span>
                    <span class="m-l-xs"><a @click="isEditing = !isEditing">
                            <b-icon icon="pencil" size="is-small"
                                    v-if="comment.author && comment.author.id === $store.state.auth.user.id"></b-icon>
                        </a>
                            </span>

                </div>
                <template v-if="!isEditing">
                    <div :class="{'highPriority': comment.highPriority}" class="commentBody">{{ comment.body }}</div>
                </template>
                <comment-editor :comment="comment" @updated="isEditing = false" v-else/>
                <br/>
            </div>
        </div>
    </article>
</template>

<script>
import BaseAvatar from "./BaseAvatar";
import CommentEditor from "./CommentEditor";

export default {
  name: "Comment",
  components: {CommentEditor, BaseAvatar},
  data() {
    return {
      isEditing: false
    }
  },
  props: ['comment']
}
</script>

<style lang="scss" scoped>

.media-left {
    width: 1.8rem;
}

.media-content {
    max-width: 95%;
    max-width: calc(100% - 3em);
}

.username {
    font-weight: 500;
}

.time {
    font-size: 0.65rem;
    padding: 0;
    margin-left: 0.2rem;
}

.commentBody {
    display: inline-block;
    background-color: white;
    display: inline-block;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .23);
    padding: 6px 10px;
    margin-top: 3px;
    white-space: pre-wrap;
    max-width: 100%;
}

.highPriority {
    background-color: $red;
    color: white;
}

.highPriority::placeholder {
    color: rgba(255, 255, 255, 0.5)
}
</style>