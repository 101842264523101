<template>
  <div>
    <b-loading :active="!!isLoading"></b-loading>
    <base-page :title="title" v-if="!isLoading">
      <template slot="title">
        <span class="mr-2">{{ title }}</span>
        <tags :id="id" :mutation="require('../../graphql/Mutations/updateSeminar.gql')"
              :other-tags="Seminar.template.tags"
              :tags="Seminar.tags"
              @click="tag => {$store.commit('seminarFilter/setFilters', {textSearch : tag}); $router.push({name: 'Seminars'})}"></tags>
      </template>
      <template slot="headerRight" v-if="Seminar.canManageAll">
        <action-button :seminar="Seminar"/>
      </template>
      <warning-comments :comments="Seminar.comments"></warning-comments>
      <SeminarOverview :seminar="Seminar" class="m-b-md"/>
      <div class="columns is-multiline">
        <div class="column  is-half">
          <div class="headline is-borderless">Allgemein</div>
          <seminar-settings :seminar="Seminar"/>
          <div class="headline is-borderless">Termine</div>
          <AppointmentList :id="id"/>
        </div>
        <div class="column is-half">
          <div class="headline is-borderless">Todo</div>
          <seminar-to-dos :seminar="Seminar"/>
          <div class="headline is-borderless">Seminarleitung</div>
          <SeminarLecturerList :id="id"/>
        </div>
      </div>
      <div class="m-b-lg"></div>
      <div class="b-tabs">
        <div class="tabs is-medium is-boxed" style="margin-left:-1px;">
          <ul>
            <router-link tag="li" :to="{ id: id, name: 'ParticipationList'}">
              <a>
                Teilnahmeliste
                <b-icon icon="account-multiple"></b-icon>
              </a>
            </router-link>
            <router-link tag="li" :to="{ id: id, name: 'AddParticipantToSeminar'}"
                         v-if="Seminar.canManageParticipations">
              <a>
                Teilnahme Hinzufügen
                <b-icon icon="account-plus"></b-icon>
              </a>
            </router-link>
            <router-link tag="li" :to="{ id: id, name: 'MailToSeminar'}"
                         v-if="Seminar.canManageParticipations">
              <a>
                Mailversand
                <b-icon icon="message-text"></b-icon>
              </a>
            </router-link>
            <router-link tag="li" :to="{ id: id, name: 'LetterToSeminar'}"
                         v-if="Seminar.canManageParticipations">
              <a>
                Serienbrief
                <b-icon icon="email"></b-icon>
              </a>
            </router-link>
          </ul>
        </div>
        <div class="box" style="border-radius:0; padding:1rem;">
          <keep-alive>
            <router-view>
              <ParticipationList :id="id"/>
            </router-view>
          </keep-alive>
        </div>
      </div>
      <div class="m-b-xl"></div>
      <div class="columns is-multiline">
        <div class="column is-half-desktop" v-if="$config.SHOW_DISTRICTS">
          <div class="headline is-borderless">Berechtigungen</div>
          <district-permissions :seminar="Seminar"/>
        </div>

        <div class="column is-half-desktop">
          <div class="headline is-borderless">Downloads</div>
          <seminar-downloads :seminar="Seminar"/>

          <div class="headline is-borderless">Uploads</div>
          <seminar-uploads :seminar="Seminar"/>
        </div>

        <div class="column is-half-desktop mt-4">
          <div class="headline is-borderless">Öffentliche Beschreibung</div>
          <seminar-description-editor :seminar="Seminar" class="m-b-xl"/>

        </div>
        <div class="column is-half-desktop mt-4">
          <seminar-page-editor :seminar="Seminar" class="m-b-xl"/>

        </div>
      </div>
      <div class="headline is-borderless">Aktivität und Kommentare</div>
      <seminar-comments-and-logs :id="id"></seminar-comments-and-logs>

      <!--  -->
    </base-page>
  </div>
</template>

<script>
// import SeminarDetail from './SeminarDetail'
import BasePage from '../Layout/BasePage'
import GET_SEMINAR_QUERY from '../../graphql/Query/Seminar.gql'
import _ from 'lodash'
import moment from 'moment'
import SeminarStatus from "../Elements/Seminar/SeminarStatus";
import WarningComments from "../Elements/WarningComments";
import SeminarOverview from "../Elements/Seminar/SeminarOverview";
import ParticipationList from "../Elements/Seminar/ParticipationList";
import SeminarLecturerList from "../Elements/Seminar/SeminarLecturerList";
import AppointmentList from "../Elements/Seminar/AppointmentList";
import Comments from "../Elements/Comments";
import SeminarDescriptionEditor from "../Elements/Seminar/SeminarDescriptionEditor";
import SeminarSettings from "../Elements/Seminar/SeminarSettings";
import ActionButton from "../Elements/Seminar/ActionButton";
import DistrictPermissions from "../Elements/Seminar/DistrictPermissions";
import SeminarDownloads from "../Elements/Seminar/SeminarDownloads";
import SeminarUploads from "../Elements/Seminar/SeminarUploads";
import SeminarToDos from "../Elements/Seminar/SeminarToDos";
import Tags from "../Elements/Tags";
import SeminarPageEditor from "../Elements/Seminar/SeminarPageEditor";
import Headline from "../Layout/Headline";
import SeminarCommentsAndLogs from "@/components/Elements/Seminar/SeminarCommentsAndLogs.vue";

export default {
  name: 'Seminar',
  components: {
    SeminarCommentsAndLogs,
    Headline,
    SeminarPageEditor,
    Tags,
    SeminarToDos,
    SeminarUploads,
    SeminarDownloads,
    DistrictPermissions,
    ActionButton,
    SeminarSettings,
    SeminarDescriptionEditor,
    Comments,
    AppointmentList,
    SeminarLecturerList, ParticipationList, SeminarOverview, WarningComments, SeminarStatus, BasePage
  },
  props: ['id'],
  data() {
    return {
      Seminar: {},
      isLoading: 0
    }
  },
  watch: {
    Seminar() {
      document.title = this.title
    }
  },
  apollo: {
    Seminar: {
      query: GET_SEMINAR_QUERY,
      variables() {
        return {
          includeComments: true,
          id: this.id
        }
      },
      loadingKey: 'isLoading'
    }
  },
  computed: {
    title() {
      if (!this.isLoading) {
        let title = this.Seminar.id + " - " + this.Seminar.template.name
        if (!_.isEmpty(this.Seminar.appointments)) {
          title += " - " + moment(this.Seminar.appointments[0].begin).format('DD.MM.Y')
        }
        return title
      } else {
        return 'Seminar'
      }
    }
  }
}
</script>

<style>

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tabs ul {
    border: 0;
}
</style>
