<template>
  <section>
    <MemberFilter v-model="filters" class="mb-4"/>
    <listable :fetch-policy="addLecturer || addParticipation ? 'network-only' :'cache-and-network'"
              :query="require('../../graphql/Query/Members.gql')" :skip="!$store.getters['memberFilter/isActive']"
              :sort-defaults="{field: 'fullName', order: 'ASC'}"
              :variables="{filters, ...variables}"
              backend-filtering
              backend-pagination
              backend-sorting
              icon="account"
              name="Members">

      <template v-slot>
        <b-table-column v-slot="props" field="fullName" label="Name" sortable>
          <router-link :to="{ name: 'Member', params: { id: props.row.id }}">
            {{ props.row.fullName }}
          </router-link>
          <tags-simple :all-types="addParticipation"
                       :tags="props.row.tags"
                       @click="tag => $store.commit('memberFilter/setFilters', {textSearch : tag})">
            <member-data-status :member="props.row" size="is-small"
                                @click="status => $store.commit('memberFilter/setFilters', {textSearch : status})"/>
          </tags-simple>
        </b-table-column>
        <b-table-column v-slot="props" :label="$tc('MitgliedernummerShort')" field="mitgliedernummer" sortable
                        width="9em">
          <router-link :to="{ name: 'Member', params: { id: props.row.id }}" class="is-black-link">
            <mitgliedernummer :mitgliedernummer="props.row.mitgliedernummer"/>
          </router-link>
        </b-table-column>
        <b-table-column v-if="$config.SHOW_DISTRICTS" v-slot="props" field="districts.name" label="Bezirk" sortable
                        width="9em">
          {{ props.row.district.name }}
        </b-table-column>

        <b-table-column v-slot="props" field="phone" label="Tel." width="10em">
          {{ props.row.phone }}
        </b-table-column>
        <!--<b-table-column v-slot="props" field="address" label="Adresse">
            {{ props.row.street+', '+props.row.zip+', '+props.row.city }}
        </b-table-column>-->
        <b-table-column v-if="addParticipation" v-slot="props" label="Vorr." meta="Erfüllt die Vorraussetungen" numeric>
          <template v-if="props.row.hasRequirements">

            <b-icon icon="check" type="is-success"></b-icon>
          </template>
          <template v-else>
            <b-icon icon="close" type="is-danger"></b-icon>
          </template>
        </b-table-column>
        <b-table-column v-slot="props" :visible="!!addParticipation || !!addLecturer"
                        class="actionsField"
                        field="actions"
                        label="Aktion">
          <AddParticipationButton v-if="addParticipation" :member="props.row" :seminar="seminar"/>
          <AddLecturerButton v-if="addLecturer" :member="props.row"/>
        </b-table-column>
      </template>
      <template v-slot:footer="{data, loaded, skip}">
        <div v-if="loaded && !skip" class="has-text-right mt-5">
          <export-members :filters="filters" :total="data.Members.paginatorInfo.total"/>
        </div>
      </template>
    </listable>
  </section>
</template>

<script>
import MemberFilter from './MemberFilter'
import AddParticipationButton from '../Elements/Seminar/AddParticipationButton'
import AddLecturerButton from '../Elements/Lecturer/AddLecturerButton'
import ExportMembers from "./ExportMembers";
import Mitgliedernummer from "./Mitgliedernummer";
import TagsSimple from "./TagsSimple";
import Listable from "./Listable";
import MemberDataStatus from "@/components/Elements/Member/MemberDataStatus";

export default {
  name: 'member-list',
  components: {
    MemberDataStatus,
    Listable,
    TagsSimple, Mitgliedernummer, ExportMembers, MemberFilter, AddParticipationButton, AddLecturerButton
  },
  props: ['addParticipation', 'addLecturer', 'seminar'],
  data() {
    return {
      filters: {}
    }
  },
  computed: {
    variables() {
      let addOptions = {}
      if (this.seminar) {
        addOptions.withHasRequirementsId = this.seminar.template.id;
        addOptions.includeLecturer = this.addLecturer || false;
      }
      return addOptions
    },
  }
}
</script>

<style scoped>
.actionsField {
  /*text-align: right;*/
  white-space: nowrap;
}

.actionsField a {
  color: grey;
}

</style>
